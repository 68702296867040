import {Tabs, TabsContent, TabsList, TabsTrigger} from "@components/ui/tabs";
import {useSearchParams} from "react-router-dom";
import {useState} from "react";
import CreateBulkDelegateMeetingProject from "@pages/BulkDelegateMeeting/CreateBulkDelegateMeetingProject";
import BulkDelegateMeetingHistory from "@pages/BulkDelegateMeeting/BulkDelegateMeetingHistory";

export default function BulkDelegateMeeting() {
	const [searchParams] = useSearchParams();
	const tab = searchParams.get("tab") || "create";

	const [
		currentTab,
		setCurrentTab
	] = useState<string>(tab);

	function changeTab(value: string) {
		setCurrentTab(value);
	}

	return (
		<div className="w-full flex flex-col items-center justify-center">
			<Tabs value={currentTab} onValueChange={changeTab} className="w-full">
				<TabsList className="grid w-full grid-cols-2">
					<TabsTrigger value="create">Create Project</TabsTrigger>
					<TabsTrigger value="history">Project History</TabsTrigger>
				</TabsList>
				<TabsContent value="create" className="mt-6">
					<CreateBulkDelegateMeetingProject/>
				</TabsContent>
				<TabsContent value="history" className="mt-6">
					<BulkDelegateMeetingHistory/>
				</TabsContent>
			</Tabs>
		</div>
	)
}
