import {Navigate, redirect, RouteObject} from "react-router-dom";
import Base from "@pages/Base";
import Dashboard from "@pages/Dashboard";
import Conversation from "@pages/Conversation";
import Delegate from "@pages/Delegate";
import AuthBase from "@pages/AuthBase";
import Login from "@pages/Login";
import Signup from "@pages/Signup";
import {clearTokens, retrieveTokens} from "@lib/jwt";
import axios from "axios";
import ErrorPage from "@pages/ErrorPage";
import CallLogs from "@pages/CallLogs/CallLogs";
import PhoneCallLogDetails from "@pages/CallLogs/PhoneCallLogDetails";
import GoogleAuthRedirect from "@pages/GoogleAuthRedirect";
import QuotaExhausted from "@pages/QuotaExhausted";
import UserRestApi from "@pages/UserRestApi";
import Integrations from "@pages/Integrations";
import AdminBase from "@admin/AdminBase";
import AdminDashboard from "@admin/AdminDashboard";
import AdminAllUsers from "@admin/AdminAllUsers";
import AdminVoiceBuilder from "@admin/AdminVoiceBuilder";
import AdminAddVoice from "@admin/AdminAddVoice";
import AdminEditVoice from "@admin/AdminEditVoice";
import AdminVoiceGreetings from "@admin/AdminVoiceGreetings";
import AdminVoiceFillers from "@admin/AdminVoiceFillers";
import AdminSubscriptionPlans from "@admin/AdminSubscriptionPlans";
import AdminAddPlan from "@admin/AdminAddPlan";
import AdminEditPlan from "@admin/AdminEditPlan";
import Subscription from "@pages/Subscription";
import ForgotPassword from "@pages/ForgotPassword";
import ChangePassword from "@pages/ChangePassword";
import PhoneNumber from "@pages/PhoneNumber";
import InboundCalls from "@pages/InboundCalls";
import CreateInboundCallAgent from "@pages/CreateInboundCallAgent";
import EditInboundCallAgent from "@pages/EditInboundCallAgent";
import BulkCalls from "@pages/BulkDelegateCall/BulkCalls";
import BulkCallsCreate from "@pages/BulkDelegateCall/BulkCallsCreate";
import BulkCallsHistory from "@pages/BulkDelegateCall/BulkCallsHistory";
import BulkCallsProjectDetails from "@pages/BulkDelegateCall/BulkCallsProjectDetails";
import BulkCallsProjectCallDetails from "@pages/BulkDelegateCall/BulkCallsProjectCallDetails";
import AdminUserDetails from "@admin/AdminUserDetails/AdminUserDetails";
import AdminUserVerifiedNumbers from "@admin/AdminUserDetails/AdminUserVerifiedNumbers";
import AdminUserPurchasedNumbers from "@admin/AdminUserDetails/AdminUserPurchasedNumbers";
import AdminUserCallLogs from "@admin/AdminUserDetails/AdminUserCallLogs";
import AdminUserConversationCallLog from "@admin/AdminUserDetails/AdminUserConversationCallLog";
import AdminUserDelegateCallLog from "@admin/AdminUserDetails/AdminUserDelegateCallLog";
import AdminUserBulkCallProjectDetails from "@admin/AdminUserDetails/AdminUserBulkCallProjectDetails";
import AdminUserBulkCallProjectCallDetails from "@admin/AdminUserDetails/AdminUserBulkCallProjectCallDetails";
import AdminVoiceSample from "@admin/AdminVoiceSample";
import MeetingCallLogDetails from "@pages/CallLogs/MeetingCallLogDetails";
import StartDelegateMeetingCall from "@pages/StartDelegateMeetingCall";
import DelegateMeetingCallLogDetails from "@pages/CallLogs/DelegateMeetingCallLogDetails";
import AdminUserConversationMeetingCallLog from "@admin/AdminUserDetails/AdminUserConversationMeetingCallLog";
import AdminUserMeetingDelegateCallLog from "@admin/AdminUserDetails/AdminUserMeetingDelegateCallLog";
import BulkDelegateMeeting from "@pages/BulkDelegateMeeting/BulkDelegateMeeting";
import BulkDelegateMeetingProject from "@pages/BulkDelegateMeeting/BulkDelegateMeetingProject";
import StartBulkDelegateMeeting from "@pages/BulkDelegateMeeting/StartBulkDelegateMeeting";
import BulkDelegateMeetingCallDetails from "@pages/BulkDelegateMeeting/BulkDelegateMeetingCallDetails";

export const urls = {
	"login": "/auth/login",
	"signup": "/auth/signup",
	"logout": "/auth/logout",
	"forgotPassword": "/auth/forgot-password",
	"changePassword": "/auth/change-password",
	"dashboard": "/dashboard",
	"profile": "/profile",
	"conversation": "/conversation",
	"delegate": "/delegate",
	"startDelegateMeeting": "/start-call/delegate-meeting/:callUID",
	"bulkCalls": "/bulk-calls",
	"bulkCallsCreate": "/bulk-calls/create",
	"bulkCallsHistory": "/bulk-calls/history",
	"bulkCallsProjectDetails": "/bulk-calls/history/:projectUID",
	"bulkCallsProjectCallDetails": "/bulk-calls/history/:projectUID/:callUID",
	"bulkDelegateMeeting": "/bulk-delegate-meeting",
	"bulkDelegateMeetingProject": "/bulk-delegate-meeting/:projectUID",
	"bulkDelegateMeetingCallDetails": "/bulk-delegate-meeting/:projectUID/:callUID",
	"startBulkDelegateMeeting": "/start-call/bulk-delegate-meeting/:callUID",
	"callLogs": "/call-logs",
	"callLogDetails": "/call-logs/phone/:callType/:callUID",
	"delegateMeetingCallLogDetails": "/call-logs/meeting/delegate/:callUID",  // For delegate calls only.
	"meetingCallLogDetails": "/call-logs/meeting/:callType/:callUID",  // For remaining types of calls.
	"integrations": "/integrations",
	"googleAuthRedirect": "/google-auth-redirect",
	"quotaExhausted": "/quota-exhausted",
	"userRestApis": "/api",
	"subscription": "/plans",
	"phoneNumber": "/phone-number",
	"inboundCalls": "/inbound-calls",
	"createInboundCallAgent": "/inbound-calls/create-agent",
	"editInboundCallAgent": "/inbound-calls/edit-agent/:agentUID",

	"adminDashboard": "/admin/dashboard",
	"adminAllUsers": "/admin/users",
	"adminUserDetails": "/admin/users/:userID",
	"adminUserVerifiedNumbers": "/admin/users/:userID/verified-numbers",
	"adminUserPurchasedNumbers": "/admin/users/:userID/purchased-numbers",
	"adminUserCallLogs": "/admin/users/:userID/call-logs",
	"adminUserConversationCallLog": "/admin/users/:userID/call-logs/conversation/:callUID",
	"adminUserMeetingConversationCallLog": "/admin/users/:userID/call-logs/meeting-conversation/:callUID",
	"adminUserDelegateCallLog": "/admin/users/:userID/call-logs/delegate/:callUID",
	"adminUserMeetingDelegateCallLog": "/admin/users/:userID/call-logs/meeting-delegate/:callUID",
	"adminUserBulkCallProjectDetails": "/admin/users/:userID/call-logs/bulk-call/:projectUID",
	"adminUserBulkCallProjectCallDetails": "/admin/users/:userID/call-logs/bulk-call/:projectUID/:callUID",
	"adminVoiceBuilder": "/admin/voice-builder",
	"adminAddVoice": "/admin/voice-builder/add-new-voice",
	"adminEditVoice": "/admin/voice-builder/edit/:source/:voiceID",
	"adminVoiceGreetings": "/admin/voice-builder/greetings/:voiceID",
	"adminVoiceFillers": "/admin/voice-builder/fillers/:voiceID",
	"adminVoiceSamples": "/admin/voice-builder/samples/:voiceID",
	"adminSubscriptionPlans": "/admin/subscription-plans/",
	"adminAddPlan": "/admin/subscription-plans/add",
	"adminEditPlan": "/admin/subscription-plans/edit/:planUID",
}

const routes: RouteObject[] = [
	{
		path: "/auth",
		element: <AuthBase/>,
		id: "authbase",
		children: [
			{
				index: true,
				element: <Navigate to={urls["login"]} replace={true}/>
			},
			{
				path: urls["login"],
				element: <Login/>
			},
			{
				path: urls["signup"],
				element: <Signup/>
			},
			{
				path: urls["forgotPassword"],
				element: <ForgotPassword/>
			},
			{
				path: urls["changePassword"],
				element: <ChangePassword/>
			},
		]
	},
	{
		path: urls["logout"],
		loader: async () => {
			return await logoutUser();
		}
	},
	{
		path: "/admin",
		element: <AdminBase/>,
		id: "adminbase",
		children: [
			{
				index: true,
				element: <Navigate to={urls["adminDashboard"]} replace={true}/>
			},
			{
				path: urls["adminDashboard"],
				element: <AdminDashboard/>,
			},
			{
				path: urls["adminAllUsers"],
				element: <AdminAllUsers/>,
			},
			{
				path: urls["adminUserDetails"],
				element: <AdminUserDetails/>,
			},
			{
				path: urls["adminUserVerifiedNumbers"],
				element: <AdminUserVerifiedNumbers/>,
			},
			{
				path: urls["adminUserPurchasedNumbers"],
				element: <AdminUserPurchasedNumbers/>,
			},
			{
				path: urls["adminUserCallLogs"],
				element: <AdminUserCallLogs/>,
			},
			{
				path: urls["adminUserConversationCallLog"],
				element: <AdminUserConversationCallLog/>
			},
			{
				path: urls["adminUserMeetingConversationCallLog"],
				element: <AdminUserConversationMeetingCallLog/>
			},
			{
				path: urls["adminUserDelegateCallLog"],
				element: <AdminUserDelegateCallLog/>
			},
			{
				path: urls["adminUserMeetingDelegateCallLog"],
				element: <AdminUserMeetingDelegateCallLog/>
			},
			{
				path: urls["adminUserBulkCallProjectDetails"],
				element: <AdminUserBulkCallProjectDetails/>
			},
			{
				path: urls["adminUserBulkCallProjectCallDetails"],
				element: <AdminUserBulkCallProjectCallDetails/>
			},
			{
				path: urls["adminVoiceBuilder"],
				element: <AdminVoiceBuilder/>,
			},
			{
				path: urls["adminAddVoice"],
				element: <AdminAddVoice/>,
			},
			{
				path: urls["adminEditVoice"],
				element: <AdminEditVoice/>,
			},
			{
				path: urls["adminVoiceGreetings"],
				element: <AdminVoiceGreetings/>,
			},
			{
				path: urls["adminVoiceFillers"],
				element: <AdminVoiceFillers/>,
			},
			{
				path: urls["adminVoiceSamples"],
				element: <AdminVoiceSample/>,
			},
			{
				path: urls["adminSubscriptionPlans"],
				element: <AdminSubscriptionPlans/>,
			},
			{
				path: urls["adminAddPlan"],
				element: <AdminAddPlan/>,
			},
			{
				path: urls["adminEditPlan"],
				element: <AdminEditPlan/>,
			},
		],
	},
	{
		path: "/",
		element: <Base/>,
		id: "base",
		errorElement: <ErrorPage/>,
		children: [
			{
				index: true,
				element: <Navigate to={urls["dashboard"]} replace={true}/>
			},
			{
				path: urls["dashboard"],
				element: <Dashboard/>,
			},
			// {
			//     path: urls["profile"],
			//     element: <Profile/>,
			// },
			{
				path: urls["conversation"],
				element: <Conversation/>,
			},
			{
				path: urls["delegate"],
				element: <Delegate/>,
			},
			{
				path: urls["bulkCalls"],
				element: <BulkCalls/>,
			},
			{
				path: urls["bulkCallsCreate"],
				element: <BulkCallsCreate/>,
			},
			{
				path: urls["bulkCallsHistory"],
				element: <BulkCallsHistory/>,
			},
			{
				path: urls["bulkCallsProjectDetails"],
				element: <BulkCallsProjectDetails/>,
			},
			{
				path: urls["bulkCallsProjectCallDetails"],
				element: <BulkCallsProjectCallDetails/>,
			},
			{
				path: urls["bulkDelegateMeeting"],
				element: <BulkDelegateMeeting/>,
			},
			{
				path: urls["bulkDelegateMeetingProject"],
				element: <BulkDelegateMeetingProject/>,
			},
			{
				path: urls["bulkDelegateMeetingCallDetails"],
				element: <BulkDelegateMeetingCallDetails/>,
			},
			{
				path: urls["callLogs"],
				element: <CallLogs/>,
			},
			{
				path: urls["callLogDetails"],
				element: <PhoneCallLogDetails/>,
			},
			{
				path: urls["meetingCallLogDetails"],
				element: <MeetingCallLogDetails/>,
			},
			{
				path: urls["delegateMeetingCallLogDetails"],
				element: <DelegateMeetingCallLogDetails/>,
			},
			{
				path: urls["integrations"],
				element: <Integrations/>,
			},
			{
				path: urls["googleAuthRedirect"],
				element: <GoogleAuthRedirect/>,
			},
			{
				path: urls["quotaExhausted"],
				element: <QuotaExhausted/>,
			},
			{
				path: urls["userRestApis"],
				element: <UserRestApi/>
			},
			{
				path: urls["subscription"],
				element: <Subscription/>
			},
			{
				path: urls["phoneNumber"],
				element: <PhoneNumber/>,
			},
			{
				path: urls["inboundCalls"],
				element: <InboundCalls/>,
			},
			{
				path: urls["createInboundCallAgent"],
				element: <CreateInboundCallAgent/>,
			},
			{
				path: urls["editInboundCallAgent"],
				element: <EditInboundCallAgent/>,
			},
		]
	},
	{
		path: urls["startDelegateMeeting"],
		element: <StartDelegateMeetingCall/>,
		errorElement: <ErrorPage/>,
		id: "startDelegateMeeting",
	},
	{
		path: urls["startBulkDelegateMeeting"],
		element: <StartBulkDelegateMeeting/>,
		errorElement: <ErrorPage/>,
		id: "startBulkDelegateMeeting",
	},
]

async function logoutUser() {
	const tokens = retrieveTokens();

	// If refresh token is already missing, clear out access token (if present) and redirect to login page.
	if (!tokens.refresh) {
		clearTokens();
		return redirect(urls["login"]);
	}

	// Send request to logout endpoint to blacklist current tokens
	try {
		await axios({
			method: "post",
			url: process.env.REACT_APP_BACKEND + "/api/logout/",
			data: {
				refresh: tokens.refresh
			},
			responseType: "json"
		});
	} catch (err) {
		// Logout failed due to client or server error. Return to dashboard page
		// Should not ideally happen.
		console.error(err);
		return redirect(urls['dashboard'])
	}

	// Delete tokens and redirect to login page
	clearTokens();
	return redirect(urls['login']);
}

export default routes
