import {useQuery} from "@tanstack/react-query";
import {ApiRequestFailed, authenticateAndFetchData, retryFn} from "@lib/apis";
import {useEffect, useState} from "react";
import {Link, useSearchParams} from "react-router-dom";
import {buttonVariants} from "@components/ui/button";
import {urls} from "@routes";
import {ArrowLeft} from "lucide-react";
import PageLoading from "@components/ui/pageloading";

interface PageData {
    status_code: number
    status_text: string
}

export default function GoogleAuthRedirect() {
    const [searchParams] = useSearchParams();
    const authCode = searchParams.get("code") || "";

    const [pageData, setPageData] = useState<PageData>();

    // Fetch page data
    const pageDataQuery = useQuery({
        queryKey: ["googleAuthRedirect"],
        queryFn: () => authenticateAndFetchData("/api/google-auth-redirect?code=" + authCode),
        gcTime: 0,
        retry: retryFn,
    });
    useEffect(() => {
        if (pageDataQuery.data) {
            setPageData(pageDataQuery.data.data as PageData);
        }
    }, [pageDataQuery.data]);

    // ========================================================================
    // --------------------------- MAIN RENDER CODE ---------------------------
    // ========================================================================

    if (pageDataQuery.isLoading) {
        return (
            <PageLoading/>
        )

    } else if (pageDataQuery.error as unknown as ApiRequestFailed) {
        return (
            <div className="flex flex-col items-center w-full">
                <div className="flex flex-col items-center w-4/12 border rounded-2xl mt-12 p-6">
                    <h1 className="text-4xl font-helvetica-neue-bold text-center">
                        Authentication Failed.
                    </h1>
                    <p className="text-2xl mt-4 text-center">
                        {pageDataQuery.error?.data.message || "Something went wrong. PLease try again."}
                    </p>
                    <Link to={urls["integrations"]} className={buttonVariants({variant: "default", className: "mt-6"})}>
                        <ArrowLeft className="h-4 w-4 mr-2"/>Go Back
                    </Link>
                </div>
            </div>
        )

    } else if (pageData) {
        return (
            <div className="flex flex-col items-center w-full">
                <div className="flex flex-col items-center w-4/12 border rounded-2xl mt-12 p-6">
                    <h1 className="text-4xl font-helvetica-neue-bold text-center">
                        Authentication Complete!
                    </h1>
                    <p className="text-2xl mt-4 text-center">You can now use Google Calendar as a post-call agent.</p>
                    <Link to={urls["delegate"]} className={buttonVariants({variant: "default", className: "mt-6"})}>
                        <ArrowLeft className="h-4 w-4 mr-2"/>Make a Call
                    </Link>
                </div>
            </div>
        )

    } else {
        // Ideally it should not reach here.
        return <></>
    }
}