import {useState} from "react";
import {Label} from "@components/ui/label";
import {Input} from "@components/ui/input";
import {Checkbox} from "@components/ui/checkbox";
import {Button} from "@components/ui/button";
import {useMutation} from "@tanstack/react-query";
import {ApiRequestFailed, authenticateAndPostData, retryFn} from "@lib/apis";
import {useNavigate} from "react-router-dom";
import {urls} from "@routes";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "@components/ui/select";
import {Textarea} from "@components/ui/textarea";

export default function AdminAddPlan() {
	const navigate = useNavigate();

	const [displayName, setDisplayName] = useState("");
	const [description, setDescription] = useState("");
	const [features, setFeatures] = useState("");
	const [backendUID, setBackendUID] = useState("");
	const [productID, setProductID] = useState("");
	const [variantID, setVariantID] = useState("");
	const [renewalType, setRenewalType] = useState("");
	const [displayOrder, setDisplayOrder] = useState<number>(0);
	const [usageQuota, setUsageQuota] = useState<number>(0);
	const [meetingUsageQuota, setMeetingUsageQuota] = useState<number>(0);
	const [maxLocalNumbers, setMaxLocalNumbers] = useState<number>(0);
	const [maxTollFreeNumbers, setMaxTollFreeNumbers] = useState<number>(0);
	const [price, setPrice] = useState("9.99");
	const [popularPlan, setPopularPlan] = useState<boolean>(false);
	const [activatePlan, setActivatePlan] = useState<boolean>(false);
	const [errorText, setErrorText] = useState("");

	// Mutation for submitting form data.
	const createPlanMutation = useMutation({
		mutationKey: ["createPlan"],
		mutationFn: () => authenticateAndPostData("/api/admin/create-subscription-plan/", {
			"display_name": displayName.trim(),
			"description": description.trim(),
			"features": features.trim(),
			"backend_uid": backendUID.trim(),
			"product_id": productID.trim() || null,
			"variant_id": variantID.trim() || null,
			"renewal_type": renewalType,
			"display_order": displayOrder,
			"usage_quota": usageQuota,
			"meeting_usage_quota": meetingUsageQuota,
			"max_local_numbers": maxLocalNumbers,
			"max_toll_free_numbers": maxTollFreeNumbers,
			"price": price,
			"popular_plan": popularPlan,
			"activate_plan": activatePlan,
		}),
		gcTime: 0,
		retry: retryFn,
		onSuccess: () => {
			navigate(urls["adminSubscriptionPlans"]);
		},
		onError: (error: ApiRequestFailed) => {
			console.error(error);
			setErrorText(error.data.message);
		}
	});

	function createSubscriptionPlan() {
		if (displayName && backendUID && renewalType && (displayOrder >= 0) && (usageQuota > 0) &&
			(maxTollFreeNumbers >= 0) && (maxLocalNumbers >= 0) && price) {
			createPlanMutation.mutate();
		} else {
			setErrorText("Please fill in all the details.")
		}
	}

	// ========================================================================
	// --------------------------- MAIN RENDER CODE ---------------------------
	// ========================================================================

	return (
		<div className="w-full flex flex-col items-center">
			<h1 className="text-4xl font-helvetica-neue-bold">Create A New Subscription Plan</h1>
			<div className="w-4/12 flex flex-col mt-6 p-6 border rounded-2xl">
				<div className="w-full grid grid-cols-2 gap-4">
					{/* Product Display Name */}
					<div className="w-full flex flex-col">
						<Label htmlFor="display-name" className="font-bold">
							<span className="text-destructive">*</span>&nbsp;Plan Name:
						</Label>
						<Input type="text"
							   id="display-name"
							   className="mt-2 border"
							   value={displayName}
							   onChange={e => setDisplayName(e.target.value)}
							   placeholder="Basic"/>
					</div>
					{/* Backend UID */}
					<div className="w-full flex flex-col">
						<Label htmlFor="backend-uid" className="font-bold">
							<span className="text-destructive">*</span>&nbsp;Backend UID:
						</Label>
						<Input type="text"
							   id="backend-uid"
							   className="mt-2 border"
							   value={backendUID}
							   onChange={e => setBackendUID(e.target.value)}
							   placeholder="basic_plan"/>
					</div>
				</div>
				{/* Display Order */}
				<div className="w-full flex flex-col mt-6">
					<Label htmlFor="display-order" className="font-bold">
						<span className="text-destructive">*</span>&nbsp;Display Order:
					</Label>
					<Input type="number"
						   id="display-order"
						   className="mt-2 border"
						   value={displayOrder}
						   onChange={e => {
							   let value = parseInt(e.target.value);
							   if (isNaN(value)) {
								   setDisplayOrder(0);
							   } else {
								   setDisplayOrder(value);
							   }
						   }}/>
				</div>
				{/* Plan Description */}
				<div className="w-full h-full flex flex-col col-span-2 mt-6">
					<Label htmlFor="plan-description" className="font-helvetica-neue-bold">
						Description Text:
					</Label>
					<Textarea id="plan-description"
							  className="mt-4 border h-full"
							  value={description}
							  placeholder="Provide short description for this plan..."
							  rows={5}
							  onChange={e => setDescription(e.target.value)}
							  required/>
				</div>
				{/* Feature List */}
				<div className="w-full h-full flex flex-col col-span-2 mt-6">
					<Label htmlFor="feature-list" className="font-helvetica-neue-bold">
						Feature List:
					</Label>
					<Textarea id="feature-list"
							  className="mt-4 border h-full"
							  value={features}
							  placeholder="Add each feature on a new line..."
							  rows={5}
							  onChange={e => setFeatures(e.target.value)}
							  required/>
				</div>
				{/* Product ID */}
				<div className="w-full flex flex-col mt-6">
					<Label htmlFor="product-id" className="font-bold">
						Product ID:
					</Label>
					<Input type="text"
						   id="product-id"
						   className="mt-2 border"
						   value={productID}
						   onChange={e => setProductID(e.target.value)}
						   placeholder="Products > Row > ... > Copy ID"/>
				</div>
				{/* Variant ID */}
				<div className="w-full flex flex-col mt-6">
					<Label htmlFor="variant-id" className="font-bold">
						Variant ID:
					</Label>
					<Input type="text"
						   id="variant-id"
						   className="mt-2 border"
						   value={variantID}
						   onChange={e => setVariantID(e.target.value)}
						   placeholder="Products > Row > ... > Copy Variant ID"/>
				</div>
				{/* Renewal Type */}
				<div className="w-full flex flex-col mt-6">
					<Label htmlFor="renewal-type-select">
						<span className="text-destructive">*</span>&nbsp;Select Billing Period (Renewal Type):
					</Label>
					<Select onValueChange={setRenewalType} value={renewalType} required>
						<SelectTrigger id="renewal-type-select" className="mt-2 border-muted-foreground/50">
							<SelectValue placeholder="Source"/>
						</SelectTrigger>
						<SelectContent>
							<SelectItem value="monthly" key="monthly">Monthly</SelectItem>
							<SelectItem value="annual" key="annual">Annual</SelectItem>
						</SelectContent>
					</Select>
				</div>
				<p className="mt-6 text-sm">Note: For prices, please keep 2 decimal digits at the end.</p>
				{/* Price in USD */}
				<div className="w-full flex flex-col mt-6">
					<Label htmlFor="price-inr-monthly" className="font-bold">
						<span className="text-destructive">*</span>&nbsp;Price in USD (ex. 0.00, 49.99, 120.00):
					</Label>
					<Input type="text"
						   id="price-inr-monthly"
						   className="mt-2 border"
						   value={price}
						   onChange={e => setPrice(e.target.value)}
						   placeholder="9.99"/>
				</div>
				{/* Phone Usage Quota */}
				<div className="w-full flex flex-col mt-6">
					<Label htmlFor="usage-quota" className="font-bold">
						<span className="text-destructive">*</span>&nbsp;Phone Call Limit (in seconds):
					</Label>
					<Input type="number"
						   id="usage-quota"
						   className="mt-2 border"
						   value={usageQuota}
						   onChange={e => {
							   let value = parseInt(e.target.value);
							   if (isNaN(value)) {
								   setUsageQuota(0);
							   } else {
								   setUsageQuota(value);
							   }
						   }}/>
				</div>
				{/* Meeting Usage Quota */}
				<div className="w-full flex flex-col mt-6">
					<Label htmlFor="meeting-usage-quota" className="font-bold">
						<span className="text-destructive">*</span>&nbsp;Meeting Call Limit (in seconds):
					</Label>
					<Input type="number"
						   id="meeting-usage-quota"
						   className="mt-2 border"
						   value={meetingUsageQuota}
						   onChange={e => {
							   let value = parseInt(e.target.value);
							   if (isNaN(value)) {
								   setMeetingUsageQuota(0);
							   } else {
								   setMeetingUsageQuota(value);
							   }
						   }}/>
				</div>
				<div className="w-full grid grid-cols-2 gap-4 mt-6">
					{/* Local Numbers Limit */}
					<div className="flex flex-col">
						<Label htmlFor="local-numbers-limit" className="font-bold">
							<span className="text-destructive">*</span>&nbsp;Max Local Numbers:
						</Label>
						<Input type="number"
							   id="local-numbers-limit"
							   className="mt-2 border"
							   value={maxLocalNumbers}
							   onChange={e => {
								   let value = parseInt(e.target.value);
								   if (isNaN(value)) {
									   setMaxLocalNumbers(0);
								   } else {
									   setMaxLocalNumbers(value);
								   }
							   }}/>
					</div>
					{/* Toll Free Numbers Limit */}
					<div className="flex flex-col">
						<Label htmlFor="tollfree-numbers-limit" className="font-bold">
							<span className="text-destructive">*</span>&nbsp;Max Toll Free Numbers:
						</Label>
						<Input type="number"
							   id="tollfree-numbers-limit"
							   className="mt-2 border"
							   value={maxTollFreeNumbers}
							   onChange={e => {
								   let value = parseInt(e.target.value);
								   if (isNaN(value)) {
									   setMaxTollFreeNumbers(0);
								   } else {
									   setMaxTollFreeNumbers(value);
								   }
							   }}/>
					</div>
				</div>
				{/* Popular Plan */}
				<div className="flex items-center space-x-2 mt-6">
					<Checkbox id="popular-plan"
							  className="rounded"
							  defaultChecked={popularPlan}
							  onClick={() => {
								  setPopularPlan(!popularPlan);
							  }}/>
					<label
						htmlFor="popular-plan"
						className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
					>
						Popular Plan
					</label>
				</div>
				{/* Activate Plan */}
				<div className="flex items-center space-x-2 mt-6">
					<Checkbox id="activate-plan"
							  className="rounded"
							  defaultChecked={activatePlan}
							  onClick={() => {
								  setActivatePlan(!activatePlan);
							  }}/>
					<label
						htmlFor="activate-plan"
						className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
					>
						Activate Plan
					</label>
				</div>
				<hr className="my-6"/>
				<Button onClick={createSubscriptionPlan}>Create Subscription Plan</Button>
			</div>
			{errorText && <p className="text-destructive mt-4">{errorText}</p>}
		</div>
	)
}
