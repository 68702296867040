import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from "axios";
import {renewAccessToken, retrieveTokens} from "@lib/jwt";


// ======================================================================
// ----------------------- SOME UTILITY FUNCTIONS -----------------------
// ======================================================================

export interface ApiRequestFailed {
    statusCode: number;
    statusText: string;
    data: any
}

function axiosInterceptorSuccessFn(response: AxiosResponse) {
    return response;
}

async function axiosInterceptorFailFn(error: AxiosError) {
    if (error.response?.status === 401) {
        const renewalSuccessful: boolean = await renewAccessToken();
        // Renew access token
        if (renewalSuccessful) {
            // send back the same error (status code 401) so that we can trigger the retry.
            return Promise.reject({
                statusCode: error.response.status,
                statusText: error.response.statusText,
                data: error.response.data
            } as ApiRequestFailed);
        } else {
            return Promise.reject({
                statusCode: 499,
                statusText: "Access token renewal failed",
                data: null
            } as ApiRequestFailed);
        }
    } else {
        // some other error
        return Promise.reject({
            statusCode: error.response?.status,
            statusText: error.response?.statusText,
            data: error.response?.data
        } as ApiRequestFailed);
    }
}

/**
 * Use this as retry function on all useQery and useMutation calls.
 */
export const retryFn = (failureCount: number, error: ApiRequestFailed) => {
    return (error.statusCode === 401) && failureCount <= 2;
}


// =============================================================================
// ----------------------- ADD YOUR APIS BELOW THIS LINE -----------------------
// =============================================================================

/**
 * Boilerplate code to fetch data from secure AiCallerBackend api endpoints.
 * Uses GET request. Returns Axios instance.
 *
 * @param apiPath - Abun DRF api endpoint path (ex: /api/get-some-data/). Starting forward slash is required.
 */

export function authenticateAndFetchData(apiPath: string) {
    const tokens = retrieveTokens();
    const axiosInstance = axios.create();
    const axiosConfig: AxiosRequestConfig = {
        method: "get",
        url: process.env.REACT_APP_BACKEND + apiPath,
        responseType: "json",
    }

    // Add auth header only if access token is available (not null). Otherwise CORS might incorrectly flag it as 401.
    if (tokens.access) {
        axiosConfig["headers"] = {'Authorization': 'Bearer ' + tokens.access}
    }

    axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
    return axiosInstance(axiosConfig);
}

/**
 * Boilerplate code to authenticate and post data to AiCallerBackend api endpoints.
 *
 * @param apiPath - Abun DRF api endpoint path (ex: /api/get-some-data/). Starting forward slash is required.
 * @param data - json data to post.
 */
export function authenticateAndPostData(apiPath: string, data: any) {
    const tokens = retrieveTokens();
    const axiosInstance = axios.create();
    const axiosConfig: AxiosRequestConfig = {
        method: "post",
        url: process.env.REACT_APP_BACKEND + apiPath,
        responseType: "json",
        data: data
    }

    // Add auth header only if access token is available (not null). Otherwise CORS might incorrectly flag it as 401.
    if (tokens.access) {
        console.log('Bearer ' + tokens.access)
        axiosConfig["headers"] = {'Authorization': 'Bearer ' + tokens.access}
    }

    axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
    return axiosInstance(axiosConfig);
}


export function authenticateAndPostFormData(apiPath: string, formData: FormData) {
    const tokens = retrieveTokens();
    const axiosInstance = axios.create();
    const axiosConfig: AxiosRequestConfig = {
        method: "post",
        url: process.env.REACT_APP_BACKEND + apiPath,
        headers: {
            'Content-Type': 'multipart/form-data',
        },

        responseType: "json",
        data: formData
    }

    // Add auth header only if access token is available (not null). Otherwise CORS might incorrectly flag it as 401.
    if (tokens.access) {
        console.log('Bearer ' + tokens.access)
        axiosConfig["headers"]!.Authorization = 'Bearer ' + tokens.access
    }

    axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
    return axiosInstance(axiosConfig);
}