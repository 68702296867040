import {Label} from "@components/ui/label";
import {Input} from "@components/ui/input";
import {Dispatch, SetStateAction, useEffect, useState} from "react";
import {Button} from "@components/ui/button";
import {CircleCheckBig, CircleSlash, Shell} from "lucide-react";
import {useMutation, UseMutationResult, useQuery} from "@tanstack/react-query";
import {ApiRequestFailed, authenticateAndFetchData, authenticateAndPostData, retryFn} from "@lib/apis";
import PageLoading from "@components/ui/pageloading";
import PageDataErrorHandler from "@components/data/pageDataErrorHandler";
import {useOutletContext} from "react-router-dom";
import {Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle} from "@components/ui/card";
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger
} from "@components/ui/alert-dialog";
import {AxiosResponse} from "axios";

interface PageData {
    status_code: number
    status_text: string
    username: string
    user_email: string
    sms_country_code: string
    sms_number: string
    wa_country_code: string
    wa_number: string
    slackbot_webhook: string
    google_auth_active: boolean
}

export default function Integrations() {
    const [pageData, setPageData] = useState<PageData>();
    const [smsCountryCode, setSmsCountryCode] = useState<string>("");
    const [smsNumber, setSmsNumber] = useState<string>("");
    const [waCountryCode, setWaCountryCode] = useState<string>("");
    const [waNumber, setWaNumber] = useState<string>("");
    const [slackBotWebhook, setSlackBotWebhook] = useState<string>("");
    const [googleAuthActive, setGoogleAuthActive] = useState<boolean>(false);
    const [errorText, setErrorText] = useState<string>("");

    // Fetch and set page data
    const pageDataQuery = useQuery({
        queryKey: ["integrations"],
        queryFn: () => authenticateAndFetchData("/api/integrations/"),
        gcTime: 0,
        refetchOnWindowFocus: false,
        retry: retryFn
    });
    useEffect(() => {
        if (pageDataQuery.data) {
            let data = pageDataQuery.data.data as PageData;
            setPageData(data);
            setSmsCountryCode(data.sms_country_code || "");
            setSmsNumber(data.sms_number || "");
            setWaCountryCode(data.wa_country_code || "");
            setWaNumber(data.wa_number || "");
            setSlackBotWebhook(data.slackbot_webhook || "");
            setGoogleAuthActive(data.google_auth_active);
        }
    }, [pageDataQuery.data]);

    // // Set username on sidebar
    // const {setUserName} = useOutletContext() as any;
    // useEffect(() => {
    //     if (pageData) {
    //         setUserName(pageData.user_email)
    //     }
    // }, [pageData, setUserName]);

    // API for adding sms integration.
    const smsAddIntegrationMutation = useMutation({
        mutationKey: ["smsAddIntegration"],
        mutationFn: (args: { countryCode: string, number: string }) => authenticateAndPostData(
            "/api/sms-integration/",
            {
                "action": "add",
                "sms_country_code": args.countryCode,
                "sms_number": args.number,
            }),
        gcTime: 0,
        retry: retryFn,
        onSuccess: () => {
            setErrorText("");
            pageDataQuery.refetch().then();
        },
        onError: (error: ApiRequestFailed) => {
            console.error(error);
            setErrorText(error.data.message);
        }
    });

    // API for removing sms integration.
    const smsRemoveIntegrationMutation = useMutation({
        mutationKey: ["smsRemoveIntegration"],
        mutationFn: () => authenticateAndPostData("/api/sms-integration/", {
            "action": "remove",
        }),
        gcTime: 0,
        retry: retryFn,
        onSuccess: () => {
            setErrorText("");
            pageDataQuery.refetch().then();
        },
        onError: (error: ApiRequestFailed) => {
            console.error(error);
            setErrorText(error.data.message);
        }
    });

    // API for adding whatsapp integration.
    const whatsappAddIntegrationMutation = useMutation({
        mutationKey: ["whatsappAddIntegration"],
        mutationFn: (args: { countryCode: string, number: string }) => authenticateAndPostData(
            "/api/whatsapp-integration/",
            {
                "action": "add",
                "wa_country_code": args.countryCode,
                "wa_number": args.number,
            }),
        gcTime: 0,
        retry: retryFn,
        onSuccess: () => {
            setErrorText("");
            pageDataQuery.refetch().then();
        },
        onError: (error: ApiRequestFailed) => {
            console.error(error);
            setErrorText(error.data.message);
        }
    });

    // API for removing whatsapp integration.
    const whatsappRemoveIntegrationMutation = useMutation({
        mutationKey: ["whatsappRemoveIntegration"],
        mutationFn: () => authenticateAndPostData("/api/whatsapp-integration/", {
            "action": "remove",
        }),
        gcTime: 0,
        retry: retryFn,
        onSuccess: () => {
            setErrorText("");
            pageDataQuery.refetch().then();
        },
        onError: (error: ApiRequestFailed) => {
            console.error(error);
            setErrorText(error.data.message);
        }
    });

    // API for adding slack integration.
    const slackAddIntegrationMutation = useMutation({
        mutationKey: ["slackAddIntegration"],
        mutationFn: (url: string) => authenticateAndPostData("/api/slack-integration/", {
            "action": "add",
            "slackbot_webhook": url,
        }),
        gcTime: 0,
        retry: retryFn,
        onSuccess: () => {
            setErrorText("");
            pageDataQuery.refetch().then();
        },
        onError: (error: ApiRequestFailed) => {
            console.error(error);
            setErrorText(error.data.message);
        }
    });

    // API for removing slack integration.
    const slackRemoveIntegrationMutation = useMutation({
        mutationKey: ["slackRemoveIntegration"],
        mutationFn: () => authenticateAndPostData("/api/slack-integration/", {
            "action": "remove",
        }),
        gcTime: 0,
        retry: retryFn,
        onSuccess: () => {
            setErrorText("");
            pageDataQuery.refetch().then();
        },
        onError: (error: ApiRequestFailed) => {
            console.error(error);
            setErrorText(error.data.message);
        }
    });

    // API for performing google auth.
    const googleCalendarAuthMutation = useMutation({
        mutationKey: ["googleCalendarAuth"],
        mutationFn: () => authenticateAndPostData("/api/google-calendar-auth/", {}),
        gcTime: 0,
        retry: retryFn,
        onSuccess: response => {
            let status: string = response["data"]["status"];

            if (response["data"]["status"] === "redirect") {
                window.location.href = response["data"]["auth_url"];

            } else if (status === "authenticated") {
                console.log("User was already authenticated.");
            }
        },
        onError: (error: ApiRequestFailed) => {
            setErrorText(error.data.message);
        }
    })

    // API for revoking google auth.
    const googleCalendarRevokeAuthMutation = useMutation({
        mutationKey: ["googleCalendarRevokeAuth"],
        mutationFn: () => authenticateAndPostData("/api/revoke-google-services-auth/", {}),
        gcTime: 0,
        retry: retryFn,
        onSuccess: () => {
            // Refetch page data from server.
            pageDataQuery.refetch().then();
        },
        onError: (error: ApiRequestFailed) => {
            setErrorText(error.data.message);
        }
    })

    // ========================================================================
    // --------------------------- MAIN RENDER CODE ---------------------------
    // ========================================================================

    if (pageDataQuery.isLoading) {
        return (
            <PageLoading/>
        )

    } else if (pageDataQuery.error as unknown as ApiRequestFailed) {
        return <PageDataErrorHandler error={pageDataQuery.error as unknown as ApiRequestFailed}/>

    } else if (pageData) {
        return (
            <div className="flex flex-col items-center w-full">
                {/* -------------------- AGENT DETAILS -------------------- */}
                <div className="flex flex-col items-center">
                    <h1 className="text-3xl font-helvetica-neue-bold text-center">Agent Integrations</h1>
                    <p className="text-center text-xl mt-2">
                        You can use these integrations to take certain actions once the call is over.
                    </p>
                </div>
                <div className="w-full lg:w-9/12 grid grid-cols-1 lg:grid-cols-2 gap-4 mt-10">
                    {/* ------------------ SMS ------------------ */}
                    <Card className="rounded-2xl border-muted-foreground/50">
                        <CardHeader>
                            <CardTitle>SMS Integration</CardTitle>
                            <CardDescription className="text-lg">
                                Send call summaries, reports or some other agent responses
                                to your mobile number as a text message.
                            </CardDescription>
                        </CardHeader>
                        <CardContent>
                            {(smsNumber && smsCountryCode) ?
                                <Button variant={"destructive"}
                                        disabled={smsRemoveIntegrationMutation.isPending}
                                        onClick={() => {
                                            smsRemoveIntegrationMutation.mutate();
                                        }}>
                                    {smsRemoveIntegrationMutation.isPending ?
                                        <><Shell className="w-4 h-4 mr-2 animate-spin"/>Please Wait...</> :
                                        <>Disable Integration</>}
                                </Button> :
                                <SMSModal smsAddIntegrationMutation={smsAddIntegrationMutation}/>}
                        </CardContent>
                        <CardFooter className="flex flex-row justify-start">
                            {(smsNumber && smsCountryCode) ?
                                <><CircleCheckBig size={20} color="#2bb409"/>&nbsp;&nbsp;<p>Enabled</p></> :
                                <><CircleSlash size={20} color="#e01b24"/>&nbsp;&nbsp;<p>Not Enabled</p></>}
                        </CardFooter>
                    </Card>
                    {/* ------------------ WHATSAPP ------------------ */}
                    <Card className="rounded-2xl border-muted-foreground/50">
                        <CardHeader>
                            <CardTitle>WhatsApp Integration</CardTitle>
                            <CardDescription className="text-lg">
                                Send call summaries, reports or some other agent responses
                                to your WhatsApp number.
                            </CardDescription>
                        </CardHeader>
                        <CardContent>
                            {(waNumber && waCountryCode) ?
                                <Button variant={"destructive"}
                                        disabled={whatsappRemoveIntegrationMutation.isPending}
                                        onClick={() => {
                                            whatsappRemoveIntegrationMutation.mutate();
                                        }}>
                                    {whatsappRemoveIntegrationMutation.isPending ?
                                        <><Shell className="w-4 h-4 mr-2 animate-spin"/>Please Wait...</> :
                                        <>Disable Integration</>}
                                </Button> :
                                <WhatsappModal waCountryCode={waCountryCode}
                                               setWaCountryCode={setWaCountryCode}
                                               waNumber={waNumber}
                                               setWaNumber={setWaNumber}
                                               whatsappAddIntegrationMutation={whatsappAddIntegrationMutation}/>}
                        </CardContent>
                        <CardFooter className="flex flex-row justify-start">
                            {(waNumber && waCountryCode) ?
                                <><CircleCheckBig size={20} color="#2bb409"/>&nbsp;&nbsp;<p>Enabled</p></> :
                                <><CircleSlash size={20} color="#e01b24"/>&nbsp;&nbsp;<p>Not Enabled</p></>}
                        </CardFooter>
                    </Card>
                    {/* ------------------ SLACK ------------------ */}
                    <Card className="rounded-2xl border-muted-foreground/50">
                        <CardHeader>
                            <CardTitle>Slack Integration</CardTitle>
                            <CardDescription className="text-lg">
                                Send call summaries, reports or some other agent responses
                                to your Slack workspace.
                            </CardDescription>
                        </CardHeader>
                        <CardContent>
                            {slackBotWebhook ?
                                <Button variant={"destructive"}
                                        disabled={slackRemoveIntegrationMutation.isPending}
                                        onClick={() => {
                                            slackRemoveIntegrationMutation.mutate();
                                        }}>
                                    {slackRemoveIntegrationMutation.isPending ?
                                        <><Shell className="w-4 h-4 mr-2 animate-spin"/>Please Wait...</> :
                                        <>Disable Integration</>}
                                </Button> :
                                <SlackModal slackBotWebhook={slackBotWebhook}
                                            setSlackBotWebhook={setSlackBotWebhook}
                                            slackAddIntegrationMutation={slackAddIntegrationMutation}/>}
                        </CardContent>
                        <CardFooter className="flex flex-row justify-start">
                            {slackBotWebhook ?
                                <><CircleCheckBig size={20} color="#2bb409"/>&nbsp;&nbsp;<p>Enabled</p></> :
                                <><CircleSlash size={20} color="#e01b24"/>&nbsp;&nbsp;<p>Not Enabled</p></>}
                        </CardFooter>
                    </Card>
                    {/* ------------------ GOOGLE CALENDAR ------------------ */}
                    <Card className="rounded-2xl border-muted-foreground/50">
                        <CardHeader>
                            <CardTitle>Google Calendar Integration</CardTitle>
                            <CardDescription className="text-lg">
                                Add events or schedule some reminder in your Google Calendar based on call outcomes.
                            </CardDescription>
                        </CardHeader>
                        <CardContent>
                            {googleAuthActive ?
                                <Button variant={"destructive"}
                                        disabled={googleCalendarRevokeAuthMutation.isPending}
                                        onClick={() => {
                                            googleCalendarRevokeAuthMutation.mutate();
                                        }}>
                                    {googleCalendarRevokeAuthMutation.isPending ?
                                        <><Shell className="w-4 h-4 mr-2 animate-spin"/>Please Wait...</> :
                                        <>Disable Integration</>}
                                </Button> :
                                <Button onClick={() => {
                                    googleCalendarAuthMutation.mutate();
                                }}>
                                    Authenticate & Add
                                </Button>}
                        </CardContent>
                        <CardFooter className="flex flex-row justify-start">
                            {googleAuthActive ?
                                <><CircleCheckBig size={20} color="#2bb409"/>&nbsp;&nbsp;<p>Enabled</p></> :
                                <><CircleSlash size={20} color="#e01b24"/>&nbsp;&nbsp;<p>Not Enabled</p></>}
                        </CardFooter>
                    </Card>
                </div>
                {errorText && <p className="text-red-600 mt-6">{errorText}</p>}
            </div>
        )

    } else {
        // Ideally it should not reach here.
        return <></>
    }
}

function SlackModal(props: {
    slackBotWebhook: string,
    setSlackBotWebhook: Dispatch<SetStateAction<string>>,
    slackAddIntegrationMutation: UseMutationResult<AxiosResponse<any, any>, ApiRequestFailed, string, unknown>
}) {
    const [url, setUrl] = useState<string>("");

    return (
        <AlertDialog>
            <AlertDialogTrigger asChild>
                <Button>Get Started</Button>
            </AlertDialogTrigger>
            <AlertDialogContent>
                <AlertDialogHeader>
                    <AlertDialogTitle>Slack Integration</AlertDialogTitle>
                    <AlertDialogDescription asChild>
                        <div className="flex flex-col mt-6">
                            <Label htmlFor="whatsapp-number-input" className="font-bold">
                                Add your AI Caller slack bot webhook URL here:
                            </Label>
                            <Input type="text" className="border mt-4"
                                   value={url}
                                   placeholder="Add your slack webhook for AI Caller Bot"
                                   onChange={e => setUrl(e.target.value)}
                                   required/>
                        </div>
                    </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                    <AlertDialogCancel>Cancel</AlertDialogCancel>
                    <AlertDialogAction asChild>
                        <Button onClick={() => {
                            props.slackAddIntegrationMutation.mutate(url);
                        }}>
                            Continue
                        </Button>
                    </AlertDialogAction>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}

function WhatsappModal(props: {
    waCountryCode: string,
    setWaCountryCode: Dispatch<SetStateAction<string>>,
    waNumber: string,
    setWaNumber: Dispatch<SetStateAction<string>>,
    whatsappAddIntegrationMutation: UseMutationResult<AxiosResponse<any, any>, ApiRequestFailed, {
        countryCode: string;
        number: string;
    }, unknown>
}) {
    const [countryCode, setCountryCode] = useState<string>("");
    const [number, setNumber] = useState<string>("");

    return (
        <AlertDialog>
            <AlertDialogTrigger asChild>
                <Button variant={"secondary"} disabled={true}>Coming Soon</Button>
            </AlertDialogTrigger>
            <AlertDialogContent>
                <AlertDialogHeader>
                    <AlertDialogTitle>WhatsApp Integration</AlertDialogTitle>
                    <AlertDialogDescription asChild>
                        <div className="flex flex-col">
                            <Label htmlFor="whatsapp-number-input" className="font-bold">
                                Set Your WhatsApp Number (with country code):
                            </Label>
                            <div id="whatsapp-number-input" className="flex flex-row mt-4">
                                <span className="text-3xl mr-3 font-bold">+</span>
                                <Input type="text"
                                       className="w-16 mr-3 text-lg border"
                                       value={countryCode}
                                       onChange={e => setCountryCode(e.target.value)}
                                       placeholder="91"
                                       required/>
                                <Input type="tel"
                                       className="text-lg border"
                                       value={number}
                                       onChange={e => setNumber(e.target.value)}
                                       placeholder="9833123456"
                                       required/>
                            </div>
                        </div>
                    </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                    <AlertDialogCancel>Cancel</AlertDialogCancel>
                    <AlertDialogAction asChild>
                        <Button>
                            Continue
                        </Button>
                    </AlertDialogAction>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}

function SMSModal(props: {
    smsAddIntegrationMutation: UseMutationResult<AxiosResponse<any, any>, ApiRequestFailed, {
        countryCode: string;
        number: string;
    }, unknown>
}) {
    const [countryCode, setCountryCode] = useState<string>("");
    const [number, setNumber] = useState<string>("");

    return (
        <AlertDialog>
            <AlertDialogTrigger asChild>
                <Button>Get Started</Button>
            </AlertDialogTrigger>
            <AlertDialogContent>
                <AlertDialogHeader>
                    <AlertDialogTitle>Text SMS Integration</AlertDialogTitle>
                    <AlertDialogDescription asChild>
                        <div className="flex flex-col">
                            <Label htmlFor="whatsapp-number-input" className="font-bold">
                                Set Your Mobile Number (with country code):
                            </Label>
                            <div id="whatsapp-number-input" className="flex flex-row mt-4">
                                <span className="text-3xl mr-3 font-bold">+</span>
                                <Input type="text"
                                       className="w-16 mr-3 text-lg border"
                                       value={countryCode}
                                       onChange={e => setCountryCode(e.target.value)}
                                       placeholder="91"
                                       required/>
                                <Input type="tel"
                                       className="text-lg border"
                                       value={number}
                                       onChange={e => setNumber(e.target.value)}
                                       placeholder="9833123456"
                                       required/>
                            </div>
                        </div>
                    </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                    <AlertDialogCancel>Cancel</AlertDialogCancel>
                    <AlertDialogAction asChild>
                        <Button onClick={() => props.smsAddIntegrationMutation.mutate({
                            countryCode: countryCode, number: number
                        })}>
                            Continue
                        </Button>
                    </AlertDialogAction>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}
