import {useEffect, useState} from "react";
import {ApiRequestFailed, authenticateAndFetchData, retryFn} from "@lib/apis";
import {useQuery} from "@tanstack/react-query";
import PageLoading from "@components/ui/pageloading";
import PageDataErrorHandler from "@components/data/pageDataErrorHandler";
import ConversationCallLogs, {ConversationCall} from "@pages/CallLogs/ConversationCallLogs";
import ConversationMeetingCallLogs, {ConversationMeetingCall} from "@pages/CallLogs/ConversationMeetingCallLogs";
import DelegateCallLogs, {DelegateCall} from "@pages/CallLogs/DelegateCallLogs";
import {Tabs, TabsContent, TabsList, TabsTrigger} from "@components/ui/tabs";
import DelegateMeetingCallLogs, {DelegateMeetingCalls} from "@pages/CallLogs/DelegateMeetingCallLogs";

interface PageData {
	status_code: number
	status_text: string
	user_email: string
	conversation_calls: Array<ConversationCall>
	conversation_meeting_calls: Array<ConversationMeetingCall>
	delegate_calls: Array<DelegateCall>
	delegate_meeting_calls: Array<DelegateMeetingCalls>
}

export default function CallLogs() {
	const [tab, setTab] = useState("conversation_calls");
	const [pageData, setPageData] = useState<PageData>();

	// Fetch page data
	const pageDataQuery = useQuery({
		queryKey: ["callLogDetails"],
		queryFn: () => authenticateAndFetchData("/api/call-logs/"),
		gcTime: 0,
		retry: retryFn,
	});
	useEffect(() => {
		if (pageDataQuery.data) {
			setPageData(pageDataQuery.data.data as PageData);
		}
	}, [pageDataQuery.data]);

	// ========================================================================
	// --------------------------- MAIN RENDER CODE ---------------------------
	// ========================================================================

	if (pageDataQuery.isLoading) {
		return (
			<PageLoading/>
		)

	} else if (pageDataQuery.error as unknown as ApiRequestFailed) {
		return <PageDataErrorHandler error={pageDataQuery.error as unknown as ApiRequestFailed}/>

	} else if (pageData) {
		return (
			<div className="w-full flex flex-col items-center">
				<div className="w-full flex flex-col items-center">
					<h1 className="text-4xl font-extrabold text-center">Your Call History</h1>
					<p className="text-xl text-center mt-2">
						View transcriptions, analyze & keep tabs on all your calls till date.
					</p>
				</div>
				<Tabs value={tab} onValueChange={setTab} className="w-full mt-12">
					<TabsList className="grid w-full grid-cols-1 md:grid-cols-2 lg:grid-cols-4 h-auto">
						<TabsTrigger value="conversation_calls">Conversation Calls</TabsTrigger>
						<TabsTrigger value="conversation_meeting_calls">Conversation Meeting Calls</TabsTrigger>
						<TabsTrigger value="delegate_calls">Delegate Task Calls</TabsTrigger>
						<TabsTrigger value="delegate_meeting_calls">Delegate Task Meeting Calls</TabsTrigger>
					</TabsList>
					<TabsContent value="conversation_calls">
						<ConversationCallLogs calls={pageData.conversation_calls}/>
					</TabsContent>
					<TabsContent value="conversation_meeting_calls">
						<ConversationMeetingCallLogs calls={pageData.conversation_meeting_calls}/>
					</TabsContent>
					<TabsContent value="delegate_calls">
						<DelegateCallLogs calls={pageData.delegate_calls}/>
					</TabsContent>
					<TabsContent value="delegate_meeting_calls">
						<DelegateMeetingCallLogs calls={pageData.delegate_meeting_calls}/>
					</TabsContent>
				</Tabs>
			</div>
		)

	} else {
		// Ideally it should not reach here.
		return <></>
	}
}
