import {Button, buttonVariants} from "@components/ui/button";
import {ArrowLeft, ChevronLeft} from "lucide-react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {urls} from "@routes";
import {useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {ApiRequestFailed, authenticateAndFetchData, retryFn} from "@lib/apis";
import PageLoading from "@components/ui/pageloading";
import PageDataErrorHandler from "@components/data/pageDataErrorHandler";
import {Label} from "@components/ui/label";
import {formattedDateTime} from "@lib/utils";

interface PageData {
	status_code: number
	status_text: string

	project_name: string
	callee_name: string
	callee_number: string
	ai_name: string
	voice_model_name: string
	call_instructions: string
	post_call_instructions: string
	post_call_result: string | null
	call_log: Array<CallTranscription>
	call_duration_minutes: number
	call_duration_seconds: number
	call_end_reason: string | null
	call_status: "connecting" | "ongoing" | "ended"
}

interface CallTranscription {
	role: string
	content: string
	timestamp?: number
}

export default function AdminUserBulkCallProjectCallDetails() {
	const navigate = useNavigate();

	const {userID, projectUID, callUID} = useParams();

	const [pageData, setPageData] = useState<PageData>();

	// Fetch page data.
	const pageDataQuery = useQuery({
		queryKey: ["AdminBulkCallsProjectCallDetailsPageData"],
		queryFn: () => authenticateAndFetchData(
			`/api/admin/get-bulk-call-project-call-details?user_id=${userID}&project_uid=${projectUID}&call_uid=${callUID}`,
		),
		gcTime: 0,
		retry: retryFn,
		refetchOnWindowFocus: false,
	});
	useEffect(() => {
		if (pageDataQuery.data) {
			setPageData(pageDataQuery.data.data as PageData);
		}
	}, [pageDataQuery.data]);

	/**
	 * Returns call duration as a string in the following format:
	 * "MM min SS sec"
	 */
	function callDurationFormatter(minutes: number, seconds: number) {
		let minute_string = minutes < 10 ? `0${minutes} min` : `${minutes} min`;
		let seconds_string = seconds < 10 ? `0${seconds} sec` : `${seconds} sec`;
		return `${minute_string} ${seconds_string}`
	}

	function goBack() {
		navigate(
			urls["adminUserBulkCallProjectDetails"].replace(
				":userID", userID || "").replace(
				":projectUID", projectUID || "")
		);
	}

	// ========================================================================
	// --------------------------- MAIN RENDER CODE ---------------------------
	// ========================================================================

	if (pageDataQuery.isLoading) {
		return (
			<PageLoading/>
		)

	} else if (pageDataQuery.error as unknown as ApiRequestFailed) {
		return <PageDataErrorHandler error={pageDataQuery.error as unknown as ApiRequestFailed}/>

	} else if (pageData) {
		if (pageData.call_status === "ended") {
			return (
				<div className="grid-rows-4 w-full">
					<div className="flex flex-row justify-start items-center mb-12">
						<Button variant="ghost" className="text-xl" onClick={goBack}>
							<ChevronLeft className="w-6 h-6 mr-2"/>Go Back
						</Button>
						<p className="text-xl font-bold text-center ml-4">
							Bulk Call Project: {pageData.project_name}
						</p>
					</div>
					<div className="flex flex-row justify-between">
						<div className="border rounded-2xl p-6 w-64 mr-4">
							<p className="font-bold text-muted-foreground">Callee Name:</p>
							<p className="mt-2 text-2xl">{pageData.callee_name}</p>
						</div>
						<div className="border rounded-2xl p-6 w-64 mr-4">
							<p className="font-bold text-muted-foreground">Callee Number:</p>
							<p className="mt-2 text-2xl">{pageData.callee_number}</p>
						</div>
						<div className="border rounded-2xl p-6 w-64 mr-4">
							<p className="font-bold text-muted-foreground">AI (Caller) Name:</p>
							<p className="mt-2 text-2xl">{pageData.ai_name}</p>
						</div>
						<div className="border rounded-2xl p-6 w-64 mr-4">
							<p className="font-bold text-muted-foreground">AI Voice Model:</p>
							<p className="mt-2 text-2xl">{pageData.voice_model_name}</p>
						</div>
						<div className="border rounded-2xl p-6 w-64 mr-4">
							<p className="font-bold text-muted-foreground">Call Duration:</p>
							<p className="mt-2 text-2xl">
								{callDurationFormatter(
									pageData.call_duration_minutes,
									pageData.call_duration_seconds
								)}
							</p>
						</div>
						<div className="border rounded-2xl p-6 w-64">
							<p className="font-bold text-muted-foreground">Call End Reason:</p>
							<p className="mt-2 text-2xl capitalize">{pageData.call_end_reason}</p>
						</div>
					</div>
					{/* Post Call Results */}
					<div className="w-full flex flex-col mt-12">
						<Label htmlFor={"post-call-result"} className="font-helvetica-neue-bold">
							Post Call Result:
						</Label>
						<div className="w-full border rounded-2xl flex flex-col p-6 mt-2" id={"post-call-result"}>
							<pre className="whitespace-pre-wrap">{pageData.post_call_result}</pre>
						</div>
					</div>
					{/* Call Transcription */}
					<div className="w-full flex flex-col mt-12">
						<Label htmlFor={"post-call-result"} className="font-helvetica-neue-bold">
							Call Transcription:
						</Label>
						<div className="w-full border rounded-2xl flex flex-col p-6 mt-2" id={"post-call-result"}>
							{pageData.call_log ? pageData.call_log.map((transcription, index) => {
								if (transcription.role === "user") {
									return <TranscriptionUserRow key={`${pageData.callee_name}_${index}`}
																 name={pageData.callee_name}
																 text={transcription.content}
																 timestamp={transcription.timestamp}/>
								} else {
									return <TranscriptionAiRow key={`${pageData.ai_name}_${index}`}
															   name={pageData.ai_name}
															   text={transcription.content}
															   timestamp={transcription.timestamp}/>
								}
							}) : <p className="text-center">No Call Transcription Available</p>}
						</div>
					</div>
				</div>
			)

		} else if (pageData.call_status === "connecting") {
			return (
				<div className="flex flex-col justify-center items-center w-full mt-32">
					<h1 className="text-4xl font-extrabold">Waiting in queue...</h1>
					<p className="mt-6 text-xl text-center">
						Your call is currently in a queue due to increased traffic.<br/>
						It might take anywhere from 2-5 minutes. Sorry for the inconvenience.
					</p>
					<Link to={urls["adminUserBulkCallProjectDetails"].replace(
						":projectUID", projectUID || ""
					).replace(":userID", userID || "")}
						  className={buttonVariants({
							  variant: "default",
							  className: "mt-12 !font-extrabold"
						  })}>
						<ArrowLeft/>&nbsp;Let's Go Back
					</Link>
				</div>
			)

		} else {
			return (
				<div className="flex flex-col justify-center items-center w-full mt-32">
					<h1 className="text-4xl font-extrabold">Still chatting away...</h1>
					<p className="mt-6 text-xl text-center">
						Your call details will be available once the call has ended.
					</p>
					<Link to={urls["adminUserBulkCallProjectDetails"].replace(
						":projectUID", projectUID || ""
					).replace(":userID", userID || "")}
						  className={buttonVariants({
							  variant: "default",
							  className: "mt-12 !font-extrabold"
						  })}>
						<ArrowLeft/>&nbsp;Let's Go Back
					</Link>
				</div>
			)
		}

	} else {
		// Ideally it should not reach here.
		return <></>
	}
}

function TranscriptionUserRow(props: { name: string, text: string, timestamp?: number }) {
	return (
		<div className="flex flex-col rounded bg-muted/35 p-4">
			<p className="text-lg font-bold text-muted-foreground">
				{props.name}: <Timestamp timestamp={props.timestamp}/>
			</p>
			<p>{props.text}</p>
		</div>
	)
}

function TranscriptionAiRow(props: { name: string, text: string, timestamp?: number }) {
	return <div className="flex flex-col rounded p-4">
		<p className="text-lg font-bold text-muted-foreground">
			{props.name}: <Timestamp timestamp={props.timestamp}/>
		</p>
		<p>{props.text}</p>
	</div>;
}

function Timestamp(props: { timestamp: number | undefined }) {
	if (props.timestamp) {
		return (
			<span className="mt-2 text-sm text-muted-foreground font-normal">
                {formattedDateTime(new Date(props.timestamp * 1000))}
            </span>
		)
	} else {
		return <></>
	}
}
