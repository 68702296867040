import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {useMutation, useQuery} from "@tanstack/react-query";
import {
    ApiRequestFailed,
    authenticateAndFetchData, authenticateAndPostData,
    authenticateAndPostFormData,
    retryFn
} from "@lib/apis";
import {ColumnDef} from "@tanstack/react-table";
import {Button} from "@components/ui/button";
import {CloudUpload, Trash2} from "lucide-react";
import PageLoading from "@components/ui/pageloading";
import PageDataErrorHandler from "@components/data/pageDataErrorHandler";
import DataTable from "@components/ui/datatable";
import {Input} from "@components/ui/input";
import {Label} from "@components/ui/label";

interface PageData {
    status_code: number
    status_text: string

    audio_files: Array<FillerAudio>
}

interface FillerAudio {
    file_id: number
    file_name: string
    file_link: string
}

export default function AdminVoiceFillers() {
    const {voiceID} = useParams();

    const [pageData, setPageData] = useState<PageData>();
    const [fillerAudioFile, setFillerAudioFile] = useState<FileList>();
    const [errorText, setErrorText] = useState<string>("");

    // Fetch page data
    const pageDataQuery = useQuery({
        queryKey: ["getFillerAudios", voiceID],
        queryFn: () => authenticateAndFetchData("/api/admin/get-voice-filler-files?voice_id=" + voiceID),
        gcTime: 0,
        retry: retryFn
    });
    useEffect(() => {
        if (pageDataQuery.data) {
            let data = pageDataQuery.data.data as PageData;
            setPageData(data);
        }
    }, [pageDataQuery.data]);

    useEffect(() => {
        console.log(fillerAudioFile)
    }, [fillerAudioFile]);

    // Mutation to add audio files
    const addFillerAudioMutation = useMutation({
        mutationKey: ["addFillerAudio", voiceID],
        mutationFn: () => {
            let fd = new FormData();
            fd.append("voice_id", voiceID!);
            if (fillerAudioFile) {
                [...fillerAudioFile].forEach(audioFile => {
                    fd.append(audioFile.name, audioFile);
                });
            }
            return authenticateAndPostFormData("/api/admin/add-filler-audio/", fd);
        },
        gcTime: 0,
        retry: retryFn,
        onSuccess: () => {
            // Refetch the page data.
            pageDataQuery.refetch().then();
        },
        onError: (error: ApiRequestFailed) => {
            console.error(error);
            setErrorText(error.data.message);
        }
    });

    // Mutation to delete Filler audio files.
    const deleteFillerAudioMutation = useMutation({
        mutationKey: ["deleteFillerAudio", voiceID],
        mutationFn: (audioFileID: number) => authenticateAndPostData("/api/admin/delete-filler-audio/", {
            "voice_id": voiceID,
            "filler_audio_id": audioFileID,
        }),
        gcTime: 0,
        retry: retryFn,
        onSuccess: () => {
            // Refetch page data.
            pageDataQuery.refetch().then();
        },
        onError: (error: ApiRequestFailed) => {
            console.error(error);
            setErrorText(error.data.message);
        }
    });

    // Build the table columns.
    const columns: ColumnDef<FillerAudio>[] = [
        {
            accessorKey: "file_name",
            header: "File Name",
        },
        {
            header: "Play Audio",
            cell: props => {
                return <audio controls src={props.row.original.file_link}></audio>
            }
        },
        {
            accessorKey: "file_link",
            header: "Link",
            cell: props => (
                <a href={props.row.original.file_link}
                   className="underline text-primary"
                   target="_blank"
                   rel="noreferrer">
                    {props.row.original.file_link}
                </a>
            ),
            enableGlobalFilter: false
        },
        {
            header: "Delete Audio",
            cell: props => {
                return (
                    <Button variant="destructive"
                            size="sm"
                            onClick={() => deleteFillerAudio(props.row.original.file_id)}>
                        <Trash2 className="mr-2 w-4 h-4"/>Delete
                    </Button>
                )
            },
            enableGlobalFilter: false
        }
    ]

    function deleteFillerAudio(audioFileID: number) {
        deleteFillerAudioMutation.mutate(audioFileID);
    }

    function addFillerAudio() {
        setErrorText("");
        if (fillerAudioFile) {
            addFillerAudioMutation.mutate();
        } else {
            setErrorText("Please add some files.");
        }
    }

    // ========================================================================
    // --------------------------- MAIN RENDER CODE ---------------------------
    // ========================================================================

    if (pageDataQuery.isLoading) {
        return (
            <PageLoading/>
        )

    } else if (pageDataQuery.error as unknown as ApiRequestFailed) {
        return <PageDataErrorHandler error={pageDataQuery.error as unknown as ApiRequestFailed}/>

    } else if (pageData) {
        // @ts-ignore
        return (
            <div className="w-full">
                <div className="flex flex-col items-center px-6 py-6 border rounded-2xl">
                    <h1 className="text-4xl text-center font-helvetica-neue-bold">Add New Filler Audio</h1>
                    <p className="text-center text-lg mt-4 w-1/2">
                        <b className="underline">IMPORTANT:</b> Before uploading, please make sure the audio files
                        have a <b>sample rate of 8000Hz</b> and are in&nbsp;
                        <a href="https://en.wikipedia.org/wiki/%CE%9C-law_algorithm"
                           className="underline text-primary"
                           target="_blank"
                           rel="noreferrer">
                            mu-law
                        </a> format. Otherwise the audio won't work or might play loud static noises.
                    </p>
                    <p className="text-center text-lg mt-4 w-1/2">
                        Also keep the filename in following format (optional):<br/>
                        <b>[ainame]_filler_[id]_8k_ulaw.wav</b>
                    </p>
                    <div className="grid w-full max-w-sm items-center gap-1.5 mt-10">
                        <Label htmlFor="filler-audio-input">Select File (multiple selection possible)</Label>
                        <Input id="filler-audio-input"
                               type="file"
                               accept="wav"
                               multiple={true}
                               onChange={e => {
                                   e.target.files && setFillerAudioFile(e.target.files)
                               }}/>
                    </div>
                    <Button className="mt-6" onClick={addFillerAudio}>
                        <CloudUpload className="mr-2 w-4 h-4"/>Upload Audio File
                    </Button>
                    {errorText && <p className="mt-6 text-destructive text-center">{errorText}</p>}
                </div>
                {/* ---------- Fillers Audio Table ---------- */}
                <div className="w-full flex flex-col items-center mt-12">
                    <DataTable columns={columns} data={pageData.audio_files}/>
                </div>
            </div>
        )

    } else {
        // Ideally it should not reach here.
        return <></>

    }
}