import {Button} from "@components/ui/button";
import {MessageCircleMore} from "lucide-react";

export default function UserRestApi() {
    function showTawktoWindow() {
        // @ts-ignore
        if (window.Tawk_API) {
            // @ts-ignore
            window.Tawk_API.toggle();
        }
    }

    return (
        <div className="flex flex-col justify-center items-center w-full mt-14">
            <h1 className="text-4xl font-extrabold">Coming Soon!</h1>
            <p className="text-2xl mt-4 text-muted-foreground text-center">
              Contact us via live chat for early access.
            </p>
            <Button className="mt-6" onClick={showTawktoWindow}>
                <MessageCircleMore/>&nbsp;&nbsp;Start Chat
            </Button>
        </div>
    )
}
