import {ReactNode, useEffect, useState} from "react";
import {useMutation, useQuery} from "@tanstack/react-query";
import {ApiRequestFailed, authenticateAndFetchData, authenticateAndPostData, retryFn} from "@lib/apis";
import PageLoading from "@components/ui/pageloading";
import PageDataErrorHandler from "@components/data/pageDataErrorHandler";
import {ColumnDef} from "@tanstack/react-table";
import {formattedDateTime} from "@lib/utils";
import {Button, buttonVariants} from "@components/ui/button";
import {Pencil, Plus, Trash} from "lucide-react";
import DataTable from "@components/ui/datatable";
import {Link, useOutletContext} from "react-router-dom";
import {urls} from "@routes";
import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
	AlertDialogTrigger
} from "@components/ui/alert-dialog";
import {BaseOutletContext} from "@pages/Base";

interface PageData {
	status_code: number
	status_text: string
	user_email: string
	inbound_call_ais: Array<InboundCallAI>
}

interface InboundCallAI {
	uid: string
	name: string
	number: string
	created_on_ts: number
}

export default function InboundCalls() {
	const [pageData, setPageData] = useState<PageData>();

	const {showToast} = useOutletContext<BaseOutletContext>();

	// Fetch page data.
	const pageDataQuery = useQuery({
		queryKey: ["inboundCallPageData"],
		queryFn: () => authenticateAndFetchData("/api/inbound-call/"),
		gcTime: 0,
		retry: retryFn,
		refetchOnWindowFocus: false,
	});
	useEffect(() => {
		if (pageDataQuery.data) {
			setPageData(pageDataQuery.data.data as PageData);
		}
	}, [pageDataQuery.data]);

	// Mutation to delete AI.
	const deleteInboundCallAiMuatation = useMutation({
		mutationKey: ["deleteInboundCallAI"],
		mutationFn: (uid: string) => authenticateAndPostData("/api/delete-inbound-call-ai/", {
			"uid": uid,
		}),
		gcTime: 0,
		retry: retryFn,
		onSuccess: () => {
			// Show toast and refetch data.
			showToast(
				"Success!",
				"Inbound Call AI has been deleted successfully.",
				"default",
			);
			pageDataQuery.refetch().then();
		},
		onError: (error: ApiRequestFailed) => {
			console.error(error);
			showToast(
				"Error",
				error.data.message,
				"destructive",
			);
		}
	})

	// Set up the table columns.
	const aiColumns: ColumnDef<InboundCallAI>[] = [
		{
			accessorKey: "name",
			header: "AI Name",
		},
		{
			accessorKey: "number",
			header: "Phone Number",
		},
		{
			accessorFn: originalRow => formattedDateTime(new Date(originalRow.created_on_ts)),
			header: "Created On",
			enableGlobalFilter: false,
		},
		{
			id: "edit_ai",
			header: () => (<div className="text-right">Edit AI</div>),
			cell: props => {
				return (
					<Link to={urls["editInboundCallAgent"].replace(":agentUID", props.row.original.uid)}
						  className={buttonVariants({variant: "outline", size: "sm"})}>
						<Pencil className="w-4 h-4 mr-2"/>Edit
					</Link>
				)
			},
			meta: {
				align: "right"
			}
		},
		{
			id: "delete_ai",
			header: () => (<div className="text-right">Delete AI</div>),
			cell: props => {
				return (
					<DeleteAiConfirmation number={props.row.original.number}
										  aiName={props.row.original.name}
										  uid={props.row.original.uid}
										  deleteInboundCallAI={deleteInboundCallAI}>
						<Button size={"sm"} variant={"destructive"}>
							<Trash className="w-4 h-4 mr-2"/>Delete
						</Button>
					</DeleteAiConfirmation>
				)
			},
			meta: {
				align: "right"
			}
		},
	]

	function deleteInboundCallAI(uid: string) {
		deleteInboundCallAiMuatation.mutate(uid);
	}

	// ========================================================================
	// --------------------------- MAIN RENDER CODE ---------------------------
	// ========================================================================

	if (pageDataQuery.isLoading) {
		return (
			<PageLoading/>
		)

	} else if (pageDataQuery.error as unknown as ApiRequestFailed) {
		return <PageDataErrorHandler error={pageDataQuery.error as unknown as ApiRequestFailed}/>

	} else if (pageData) {
		return (
			<div className="w-full flex flex-col items-center mt-8">
				<div className="flex flex-col items-center">
					<h1 className="text-4xl font-helvetica-neue-bold text-center">
						Inbound Calls
					</h1>
					<p className="text-xl text-center mt-2">
						Set up an AI agent to answer all incoming calls to your number.
					</p>
				</div>
				<div className="mt-8 w-full flex flex-col items-center">
					<Link to={urls["createInboundCallAgent"]} className={buttonVariants({variant: "default"})}>
						<Plus className="w-4 h-4 mr-2"/>Create New AI Agent
					</Link>
				</div>
				<div className="mt-6 w-full flex flex-col items-center">
					<DataTable columns={aiColumns} data={pageData.inbound_call_ais}/>
				</div>
			</div>
		)

	} else {
		// Ideally it should not reach here.
		return <></>
	}
}

function DeleteAiConfirmation(props: {
	children: ReactNode,
	uid: string,
	aiName: string,
	number: string,
	deleteInboundCallAI: (uid: string) => void
}) {
	return (
		<AlertDialog>
			<AlertDialogTrigger asChild>
				{props.children}
			</AlertDialogTrigger>
			<AlertDialogContent>
				<AlertDialogHeader>
					<AlertDialogTitle>Confirm deleting AI {props.aiName} for number {props.number}?</AlertDialogTitle>
					<AlertDialogDescription>
						This action cannot be undone. Once deleted, you can use this number to create another AI.
						All related call logs will still be accessible.
					</AlertDialogDescription>
				</AlertDialogHeader>
				<AlertDialogFooter>
					<AlertDialogCancel>
						Cancel
					</AlertDialogCancel>
					<AlertDialogAction onClick={() => props.deleteInboundCallAI(props.uid)}>
						Continue
					</AlertDialogAction>
				</AlertDialogFooter>
			</AlertDialogContent>
		</AlertDialog>
	)
}
