import {Link, useNavigate, useOutletContext, useParams} from "react-router-dom";
import {ReactNode, useEffect, useState} from "react";
import {useMutation, useQuery} from "@tanstack/react-query";
import {ApiRequestFailed, authenticateAndFetchData, authenticateAndPostData, retryFn} from "@lib/apis";
import PageLoading from "@components/ui/pageloading";
import PageDataErrorHandler from "@components/data/pageDataErrorHandler";
import {Button, buttonVariants} from "@components/ui/button";
import {ChevronLeft, LoaderCircle, Mail, Phone, Trash2} from "lucide-react";
import {urls} from "@routes";
import {Label} from "@components/ui/label";
import DataTable from "@components/ui/datatable";
import {ColumnDef} from "@tanstack/react-table";
import {formattedDateTime} from "@lib/utils";
import {Badge} from "@components/ui/badge";
import {BaseOutletContext} from "@pages/Base";
import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
	AlertDialogTrigger
} from "@components/ui/alert-dialog";

interface PageData {
	status_code: number
	status_text: string

	project_name: string
	participant_emails: Array<string>
	language: string
	ai_call_name: string
	ai_nationality: string
	user_call_name: string
	call_instructions: string
	post_call_instructions: string
	status: "active" | "completed"
	meetings: Array<Meeting>
}

interface Meeting {
	call_uid: string
	ai_call_name: string
	participant_email: string
	call_started_at_ts: number | null
	call_ended_at_ts: number | null
	call_status: "created" | "ongoing" | "ended"
}

export default function BulkDelegateMeetingProject() {
	const {showToast} = useOutletContext<BaseOutletContext>();
	const {projectUID} = useParams();
	const navigate = useNavigate();

	const [pageData, setPageData] = useState<PageData>();
	const [emailAllDisabled, setEmailAllDisabled] = useState(false);

	// Query for fetching page data.
	const pageDataQuery = useQuery({
		queryKey: ["getBulkMeetingProjectDetailsPageData"],
		queryFn: () => authenticateAndFetchData(`/api/get-bulk-meeting-project-details?project_uid=${projectUID}`),
		gcTime: 0,
		retry: retryFn,
		refetchOnWindowFocus: false,
	});
	useEffect(() => {
		if (pageDataQuery.data) {
			let data = pageDataQuery.data.data as PageData
			console.log(data);
			setPageData(data);
		}
	}, [pageDataQuery.data]);

	// Mutation to send link email to one user.
	const sendLinkToUserMutation = useMutation({
		mutationKey: ["sendLinkToUser"],
		mutationFn: (callUID: string) => authenticateAndPostData(
			"/api/bulk-delegate-meeting-email-user/",
			{
				"project_uid": projectUID,
				"call_uid": callUID,
			}),
		gcTime: 0,
		retry: retryFn,
		onSuccess: () => {
			showToast(
				"Success!",
				"Meeting link has been emailed to this address successfully.",
				"default",
			)
		},
		onError: (error: ApiRequestFailed) => {
			console.error(error);
			showToast(
				"Error",
				error.data.message,
				"destructive",
			);
		}
	});

	// Mutation to send link email to all users.
	const sendLinkToAllMutation = useMutation({
		mutationKey: ["sendLinkToAll"],
		mutationFn: () => authenticateAndPostData(
			"/api/bulk-delegate-meeting-email-all/",
			{
				"project_uid": projectUID,
			}),
		gcTime: 0,
		retry: retryFn,
		onSuccess: () => {
			setTimeout(() => {
				setEmailAllDisabled(false);
			}, 10000);
			showToast(
				"Success!",
				"Meeting link has been emailed to all participants successfully.",
				"default",
			)
		},
		onError: (error: ApiRequestFailed) => {
			console.error(error);
			setEmailAllDisabled(false);
			showToast(
				"Error",
				error.data.message,
				"destructive",
			)
		}
	});

	// Mutation to delete call.
	const deleteCallMutation = useMutation({
		mutationKey: ["bulkDelegateMeetingDeleteCall"],
		mutationFn: (callUID: string) => authenticateAndPostData(
			"/api/bulk-delegate-meeting-delete-call/",
			{
				"project_uid": projectUID,
				"call_uid": callUID
			}),
		gcTime: 0,
		retry: retryFn,
		onSuccess: () => {
			showToast(
				"Success!",
				"Call has been removed from this project successfully.",
				"default",
			);
			pageDataQuery.refetch().then();
		},
		onError: (error: ApiRequestFailed) => {
			console.error(error);
			showToast(
				"Error",
				error.data.message,
				"destructive",
			);
		}
	});

	// Table columns
	const columns: ColumnDef<Meeting>[] = [
		{
			accessorKey: "participant_email",
			header: "Email ID",
		},
		{
			header: "Call Started At",
			accessorFn: originalRow => {
				if (originalRow.call_started_at_ts) {
					return formattedDateTime(new Date(originalRow.call_started_at_ts));
				} else {
					return "---"
				}
			},
		},
		{
			header: "Call Ended At",
			accessorFn: originalRow => {
				if (originalRow.call_ended_at_ts) {
					return formattedDateTime(new Date(originalRow.call_ended_at_ts));
				} else {
					return "---"
				}
			},
		},
		{
			header: "Status",
			cell: props => {
				if (props.row.original.call_status === "created") {
					return (
						<Badge className={"bg-emerald-800"}>Created</Badge>
					)
				} else if (props.row.original.call_status === "ongoing") {
					return (
						<Badge variant="destructive">In Call</Badge>
					)

				} else {
					return (
						<Badge variant="default">Completed</Badge>
					)
				}
			},
			enableColumnFilter: false,
		},
		{
			header: "Send Meeting Link",
			cell: props => {
				if (props.row.original.call_status === "ended") {
					return (
						<Button size={"sm"}
										disabled={true}>
							<Mail className={"w-4 h-4 mr-2"}/>Send Link
						</Button>
					)
				} else {
					return (
						<Button size={"sm"}
										disabled={sendLinkToUserMutation.isPending}
										onClick={() => sendMeetingLink(props.row.original.call_uid)}>
							<Mail className={"w-4 h-4 mr-2"}/>Send Link
						</Button>
					)
				}

			}
		},
		{
			header: "Call Details",
			cell: props => {
				if (props.row.original.call_status === "ended") {
					return (
						<Link to={urls["bulkDelegateMeetingCallDetails"].replace(
							":projectUID", projectUID || ""
						).replace(
							":callUID", props.row.original.call_uid
						)}
									className={buttonVariants({variant: "default", size: "sm"})}>
							<Phone className={"w-4 h-4 mr-2"}/>View Call
						</Link>
					)
				} else {
					return (
						<Button size={"sm"} disabled={true}>
							<Phone className={"w-4 h-4 mr-2"}/>View Call
						</Button>
					)
				}
			},
		},
		{
			header: "Remove Call",
			cell: props => {
				if (props.row.original.call_status === "created") {
					return (
						<RemoveCallAlert callUID={props.row.original.call_uid} removeCallHandler={removeCallHandler}>
							<Button size={"icon"}
											disabled={deleteCallMutation.isPending}
											variant={"destructive"}>
								<Trash2 className={"w-4 h-4"}/>
							</Button>
						</RemoveCallAlert>

					)
				} else {
					return (
						<Button size={"sm"} disabled={true} variant={"destructive"}>
							<Trash2 className={"w-4 h-4"}/>
						</Button>
					)
				}
			},
		},
	]

	function goBack() {
		navigate(urls["bulkDelegateMeeting"]);
	}

	/**
	 * Emails "start meeting" to email id associated with given call.
	 * @param callUID - email id of participant.
	 */
	function sendMeetingLink(callUID: string) {
		sendLinkToUserMutation.mutate(callUID);
	}

	function removeCallHandler(callUID: string) {
		deleteCallMutation.mutate(callUID);
	}

	// ========================================================================
	// --------------------------- MAIN RENDER CODE ---------------------------
	// ========================================================================

	if (pageDataQuery.isLoading) {
		return (
			<PageLoading/>
		)

	} else if (pageDataQuery.error as unknown as ApiRequestFailed) {
		return <PageDataErrorHandler error={pageDataQuery.error as unknown as ApiRequestFailed}/>

	} else if (pageData) {
		return (
			<div className={"w-full flex flex-col"}>
				<div className="flex flex-row justify-start items-center mb-12">
					<Button variant="ghost" className="text-xl" onClick={goBack}>
						<ChevronLeft className="w-6 h-6 mr-2"/>Go Back
					</Button>
					<p className="text-xl font-bold text-center ml-4">
						Project: {pageData.project_name}
					</p>
				</div>
				{/* Meeting Stats */}
				<div className={"w-full flex flex-row justify-between"}>
					{/* AI Voice Details */}
					<div className="flex-grow border rounded-2xl p-4 w-64 mr-4">
						<p className="font-bold text-muted-foreground">AI Name:</p>
						<p className="mt-2">
							{pageData.ai_call_name} | {pageData.ai_nationality || "N/A"}
						</p>
					</div>
					{/* User Call Name */}
					<div className="flex-grow border rounded-2xl p-4 w-64 mr-4">
						<p className="font-bold text-muted-foreground">Your Name:</p>
						<p className="mt-2">
							{pageData.user_call_name}
						</p>
					</div>
					{/* Call Language */}
					<div className="flex-grow border rounded-2xl p-4 w-64 mr-4">
						<p className="font-bold text-muted-foreground">Meeting Language:</p>
						<p className="mt-2">
							{pageData.language}
						</p>
					</div>
					{/* Status */}
					<div className="flex-grow border rounded-2xl p-4 w-64">
						<p className="font-bold text-muted-foreground">Meeting Status:</p>
						<p className="mt-2">
							{pageData.status}
						</p>
					</div>
				</div>
				{/* Call Instructions */}
				<div className="w-full flex flex-col mt-6">
					<Label htmlFor={"call-instruction-text"} className="font-helvetica-neue-bold">
						Call Instructions:
					</Label>
					<div className="w-full border rounded-2xl p-6 mt-2" id={"call-instruction-text"}>
						<pre className="whitespace-pre-wrap">
							{pageData.call_instructions}
						</pre>
					</div>
				</div>
				{/* Post Call Instructions */}
				<div className="w-full flex flex-col mt-6">
					<Label htmlFor={"post-call-instruction-text"} className="font-helvetica-neue-bold">
						Post Call Instructions:
					</Label>
					<div className="w-full border rounded-2xl p-6 mt-2" id={"post-call-instruction-text"}>
						<pre className="whitespace-pre-wrap">
							{pageData.post_call_instructions}
						</pre>
					</div>
				</div>
				{/* Calls Table */}
				<div className="w-full flex flex-col justify-center items-center mt-12">
					<DataTable columns={columns} data={pageData.meetings}/>
					<Button className={"mt-6"}
									disabled={emailAllDisabled || (pageData.status === "completed")}
									onClick={() => {
										setEmailAllDisabled(true);
										sendLinkToAllMutation.mutate();
									}}>
						{sendLinkToAllMutation.isPending ?
							<><LoaderCircle className={"w-4 h-4 mr-2 animate-spin"}/>Sending...</> :
							<><Mail className={"w-4 h-4 mr-2"}/>Send Link To All</>}
					</Button>
				</div>
			</div>
		)

	} else {
		// Ideally it should not reach here.
		return <></>
	}
}

export function RemoveCallAlert(props: {
	children: ReactNode,
	callUID: string,
	removeCallHandler: (callUID: string) => void
}) {
	return (
		<AlertDialog>
			<AlertDialogTrigger asChild>
				{props.children}
			</AlertDialogTrigger>
			<AlertDialogContent>
				<AlertDialogHeader>
					<AlertDialogTitle>Confirm removing this call?</AlertDialogTitle>
					<AlertDialogDescription>
						This action cannot be undone. Call will be permanently removed from
						this project.
					</AlertDialogDescription>
				</AlertDialogHeader>
				<AlertDialogFooter>
					<AlertDialogCancel>Cancel</AlertDialogCancel>
					<AlertDialogAction onClick={() => props.removeCallHandler(props.callUID)}>
						Confirm
					</AlertDialogAction>
				</AlertDialogFooter>
			</AlertDialogContent>
		</AlertDialog>
	)
}
