import {Input} from "@components/ui/input";
import {Button} from "@components/ui/button";
import {useEffect, useState} from "react";
import {useMutation} from "@tanstack/react-query";
import {ApiRequestFailed, authenticateAndPostData, retryFn} from "@lib/apis";
import {InputOTP, InputOTPGroup, InputOTPSeparator, InputOTPSlot} from "@components/ui/input-otp";


interface Props {
    number_verified: () => void
}

const OTP_LEN = 6


export function VerifyNumber(props: Props) {
    // stage 0 - Number Input & Send OTP | stage 1 - Enter OTP & Verify
    const [stage, setStage] = useState(0);
    const [countryCode, setCountryCode] = useState<string>("");
    const [mobileNumber, setMobileNumber] = useState<string>("");
    const [otp, setOtp] = useState<string>("");
    const [disableOtpInput, setDisableOtpInput] = useState<boolean>(false);
    const [errorText, setErrorText] = useState<string>("");

    const sendOTPMutation = useMutation({
        mutationKey: ['sendOTP', countryCode, mobileNumber],
        mutationFn: () => authenticateAndPostData("/api/sendotp/", {
            "country_code": countryCode,
            "mobile_number": mobileNumber,
        }),
        gcTime: 0,
        retry: retryFn,
        onSuccess: (response) => {
            // Reset any error text and move to otp input stage.
            console.log(response.data);
            setErrorText("");
            setStage(1);
        },
        onError: (error: ApiRequestFailed) => {
            setErrorText(error.data.message);
        }
    });

    const verifyOTPMutation = useMutation({
        mutationKey: ['verifyOTP', otp],
        mutationFn: () => authenticateAndPostData("/api/verifyotp/", {
            "country_code": countryCode,
            "mobile_number": mobileNumber,
            "otp": otp,
        }),
        gcTime: 0,
        retry: retryFn,
        onSuccess: () => {
            // Reset everything and mark this number as verified.
            props.number_verified();
            setCountryCode("");
            setMobileNumber("");
            setOtp("");
            setDisableOtpInput(false);
            setStage(0);
        },
        onError: (error: ApiRequestFailed) => {
            // Re-enable OTP input and show error text.
            setDisableOtpInput(false);
            setErrorText(error.data.message);
        }
    });

    // Checks if all OTP digits have been entered. If so disables the input temporarily
    // and sends it to server for verification.
    useEffect(() => {
        if (!disableOtpInput && (otp.length >= OTP_LEN)) {
            setDisableOtpInput(true);
            verifyOTPMutation.mutate();
        }
    }, [otp, verifyOTPMutation, disableOtpInput])

    /**
     * Sends OTP to given country code + mobile number.
     * If successful, the mutation onSuccess will advance the stage.
     */
    function submitOTPForm() {
        if (countryCode && mobileNumber) {
            sendOTPMutation.mutate();
        } else {
            setErrorText("Please enter a valid mobile number and country code");
        }
    }

    // ========================================================================
    // --------------------------- MAIN RENDER CODE ---------------------------
    // ========================================================================

    if (stage === 0) {
        return (
            <div className="flex flex-col items-center">
                <h1 className="scroll-m-20 text-4xl text-center font-helvetica-neue-bold tracking-tight">
                    Verify Your Number To Get Started
                </h1>
                <p className="text-xl text-center mt-2">Enter your country code & mobile number to proceed</p>
                <div id="number-input" className="flex flex-row w-full md:w-1/2 mt-12">
                    <span className="text-3xl mr-3 font-bold">+</span>
                    <Input type="text"
                           className="w-16 mr-3 text-lg border-muted-foreground/50"
                           value={countryCode}
                           onChange={e => setCountryCode(e.target.value)}
                           placeholder="91"
                           required/>
                    <Input type="tel" className="text-lg border-muted-foreground/50"
                           value={mobileNumber}
                           onChange={e => setMobileNumber(e.target.value)}
                           placeholder="9833123456"
                           required/>
                </div>
                <Button className="mt-12 px-10 text-lg" onClick={submitOTPForm}>Send OTP</Button>
                <p className="text-red-600 mt-4">{errorText}</p>
            </div>
        );

    } else if (stage === 1) {
        return (
            <div className="flex flex-col items-center">
                <h1 className="scroll-m-20 text-4xl text-center font-helvetica-neue-bold tracking-tight">
                    Verify Your Number To Get Started
                </h1>
                <p className="text-xl text-center mt-2">
                    One Time Password (OTP) was sent to this number.<br/>
                    Enter the OTP below to verify it.
                </p>
                <div className="mt-10">
                    <InputOTP maxLength={6} value={otp} onChange={setOtp} disabled={disableOtpInput}>
                        <InputOTPGroup>
                            <InputOTPSlot index={0}/>
                            <InputOTPSlot index={1}/>
                            <InputOTPSlot index={2}/>
                        </InputOTPGroup>
                        <InputOTPSeparator/>
                        <InputOTPGroup>
                            <InputOTPSlot index={3}/>
                            <InputOTPSlot index={4}/>
                            <InputOTPSlot index={5}/>
                        </InputOTPGroup>
                    </InputOTP>
                </div>
                <p className="text-red-600 mt-4">{errorText}</p>
            </div>
        );

    } else {
        return (
            <p>Bad stage number</p>
        )
    }
}
