import {ReactNode, useEffect, useState} from "react";
import {useMutation, useQuery} from "@tanstack/react-query";
import {ApiRequestFailed, authenticateAndFetchData, authenticateAndPostData, retryFn} from "@lib/apis";
import PageLoading from "@components/ui/pageloading";
import PageDataErrorHandler from "@components/data/pageDataErrorHandler";
import {ColumnDef} from "@tanstack/react-table";
import DataTable from "@components/ui/datatable";
import {Link} from "react-router-dom";
import {Button, buttonVariants} from "@components/ui/button";
import {CircleX, HandMetal, Pencil, PlayCircle, PlusCircle, Speech} from "lucide-react";
import {urls} from "@routes";
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger
} from "@components/ui/alert-dialog";

interface PageData {
    status_code: number
    status_text: string
    voices: Array<Voice>
}

interface Voice {
    voice_id: string
    voice_name: string
    source: string
    is_active: boolean
    gender: string
    personalities: Array<string>
    nationality: string
}

export default function AdminVoiceBuilder() {
    const [pageData, setPageData] = useState<PageData>();

    // Build the table columns.
    const columns: ColumnDef<Voice>[] = [
        {
            accessorKey: "voice_name",
            header: "Voice Name",
        },
        {
            accessorKey: "source",
            header: "Source",
        },
        {
            accessorKey: "gender",
            accessorFn: originalRow => originalRow.gender === "M" ? "Male" : "Female",
            header: "Gender",
        },
        {
            accessorKey: "nationality",
            header: "Nationality"
        },
        {
            accessorKey: "personalities",
            // accessorFn: originalRow => originalRow.personalities.join("\n"),
            header: "Personality",
            cell: props => {
                return (
                    <ul className="list-none">
                        {props.row.original.personalities.map(value => <li key={value}>{value}</li>)}
                    </ul>
                )
            },
            enableGlobalFilter: false
        },
        {
            accessorKey: "is_active",
            header: "Active",
            cell: props => {
                if (props.row.original.is_active) {
                    return <span className="text-primary">Active</span>
                } else {
                    return <span className="text-destructive">Inactive</span>
                }
            },
            enableGlobalFilter: true
        },
        {
            header: "Greeting Voices",
            cell: props => {
                return (
                    <Link to={urls["adminVoiceGreetings"].replace(":voiceID", props.row.original.voice_id)}
                          className={buttonVariants({variant: "secondary", size: "sm"})}>
                        <HandMetal className="w-4 h-4"/>&nbsp;&nbsp;Greetings
                    </Link>
                )
            },
            enableGlobalFilter: false
        },
        {
            header: "Filler Voices",
            cell: props => {
                return (
                    <Link to={urls["adminVoiceFillers"].replace(":voiceID", props.row.original.voice_id)}
                          className={buttonVariants({variant: "secondary", size: "sm", className: "px-5"})}>
                        <Speech className="w-4 h-4"/>&nbsp;&nbsp;Fillers
                    </Link>
                )
            },
            enableGlobalFilter: false
        },
        {
            header: "Sample Audio",
            cell: props => {
                return (
                    <Link to={urls["adminVoiceSamples"].replace(":voiceID", props.row.original.voice_id)}
                          className={buttonVariants({variant: "secondary", size: "sm", className: "px-5"})}>
                        <PlayCircle className="w-4 h-4"/>&nbsp;&nbsp;Sample
                    </Link>
                )
            },
            enableGlobalFilter: false
        },
        {
            header: "Edit Voice",
            cell: props => {
                return (
                    <Link to={urls["adminEditVoice"]
                        .replace(":voiceID", props.row.original.voice_id)
                        .replace(":source", props.row.original.source)}
                          className={buttonVariants({variant: "outline", size: "sm"})}>
                        <Pencil className="w-4 h-4"/>&nbsp;&nbsp;Edit Voice
                    </Link>
                )
            },
            enableGlobalFilter: false
        },
        {
            header: "Delete Voice",
            cell: props => {
                return (
                    <DeleteVoiceConfirmation voiceID={props.row.original.voice_id}
                                             voiceName={props.row.original.voice_name}
                                             deleteVoice={deleteVoice}>
                        <Button variant="destructive" size="sm">
                            <CircleX className="w-4 h-4"/>&nbsp;&nbsp;Delete
                        </Button>
                    </DeleteVoiceConfirmation>
                )
            },
            enableGlobalFilter: false
        }
    ]

    // Fetch page data.
    const pageDataQuery = useQuery({
        queryKey: ["adminVoiceBuilder"],
        queryFn: () => authenticateAndFetchData("/api/admin/get-voices/"),
        gcTime: 0,
        retry: retryFn
    });
    useEffect(() => {
        if (pageDataQuery.data) {
            setPageData(pageDataQuery.data.data as PageData);
        }
    }, [pageDataQuery.data]);

    // Mutation for deleting voice.
    const deleteVoiceMutation = useMutation({
        mutationKey: ["deleteVoice"],
        mutationFn: (voiceID: string) => authenticateAndPostData("/api/admin/delete-voice/", {
            "voice_id": voiceID,
        }),
        gcTime: 0,
        retry: retryFn,
        onSuccess: () => {
            // Refetch the page data.
            pageDataQuery.refetch().then();
        },
        onError: (error: ApiRequestFailed) => {
            console.error(error);
        }
    });

    function deleteVoice(voiceID: string) {
        deleteVoiceMutation.mutate(voiceID);
    }

    // ========================================================================
    // --------------------------- MAIN RENDER CODE ---------------------------
    // ========================================================================

    if (pageDataQuery.isLoading) {
        return (
            <PageLoading/>
        )

    } else if (pageDataQuery.error as unknown as ApiRequestFailed) {
        return <PageDataErrorHandler error={pageDataQuery.error as unknown as ApiRequestFailed}/>

    } else if (pageData) {
        return (
            <div className="w-full flex flex-col items-center">
                <div className="mb-10">
                    <h1 className="text-4xl font-extrabold text-center mb-2">All Voice Models</h1>
                </div>
                <DataTable columns={columns} data={pageData.voices}/>
                <Link to={urls["adminAddVoice"]} className={buttonVariants({variant: "default", className: "mt-8"})}>
                    <PlusCircle/>&nbsp;&nbsp;Add New Voice
                </Link>
            </div>
        )

    } else {
        // Ideally it should not reach here.
        return <></>
    }
}

export function DeleteVoiceConfirmation(props: {
    children: ReactNode,
    voiceID: string,
    voiceName: string,
    deleteVoice: CallableFunction
}) {
    return (
        <AlertDialog>
            <AlertDialogTrigger asChild>
                {props.children}
            </AlertDialogTrigger>
            <AlertDialogContent>
                <AlertDialogHeader>
                    <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
                    <AlertDialogDescription>
                        This action cannot be undone. This will permanently delete <b>{props.voiceName}</b> voice model.
                        All existing calls that used this voice will remain intact.
                        <br/><br/>
                        Any Greeting & Filler audio files associated with this voice will not be deleted.
                        You will have to delete them manually.
                    </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                    <AlertDialogCancel>
                        Cancel
                    </AlertDialogCancel>
                    <AlertDialogAction onClick={() => props.deleteVoice(props.voiceID)}>
                        Continue
                    </AlertDialogAction>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}
