import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import {BaseOutletContext} from "@pages/Base";
import {ReactNode, useEffect, useState} from "react";
import {ApiRequestFailed, authenticateAndFetchData, authenticateAndPostData, retryFn} from "@lib/apis";
import {useMutation, useQuery} from "@tanstack/react-query";
import {urls} from "@routes";
import PageLoading from "@components/ui/pageloading";
import PageDataErrorHandler from "@components/data/pageDataErrorHandler";
import {Button} from "@components/ui/button";
import {ChevronLeft, LoaderCircle, Plus, Trash} from "lucide-react";
import {ColumnDef} from "@tanstack/react-table";
import DataTable from "@components/ui/datatable";
import {Label} from "@components/ui/label";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "@components/ui/select";
import {formattedDateTime} from "@lib/utils";
import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
	AlertDialogTrigger
} from "@components/ui/alert-dialog";

interface PageData {
	status_code: number
	status_text: string

	user_email: string
	username: string
	local_numbers_count: number
	local_numbers_max: number
	tollfree_numbers_count: number
	tollfree_numbers_max: number
	purchased_numbers: Array<PurchasedNumber>
}

interface PurchasedNumber {
	twilio_number_sid: string
	number: string
	number_type: "toll_free" | "local"
	purchased_on_ts: number
}

interface AvailableNumbers {
	phone_number: string
	iso_country: string
	voice_available: boolean
	sms_available: boolean
	mms_available: boolean
}

interface GetNumbersResponse {
	status_code: number
	status_text: string
	available_numbers: Array<AvailableNumbers>
}

export default function AdminUserPurchasedNumbers() {
	const {userID} = useParams();
	const navigate = useNavigate();
	const {showToast} = useOutletContext<BaseOutletContext>();

	const [
		pageData,
		setPageData
	] = useState<PageData>();
	const [
		purchasedNumbers,
		setPurchasedNumbers
	] = useState<PurchasedNumber[]>([]);
	const [
		availableNumbers,
		setAvailableNumbers
	] = useState<Array<AvailableNumbers>>([]);
	const [
		numberType,
		setNumberType
	] = useState("");

	// Fetch page data.
	const pageDataQuery = useQuery({
		queryKey: ["adminUserPurchasedNumbersPageData"],
		queryFn: () => authenticateAndFetchData("/api/admin/user-purchased-numbers?user_id=" + userID),
		gcTime: 0,
		retry: retryFn,
		refetchOnWindowFocus: false,
	});
	useEffect(() => {
		if (pageDataQuery.data) {
			let data = pageDataQuery.data.data as PageData
			setPageData(data);
			setPurchasedNumbers(data.purchased_numbers);
		}
	}, [pageDataQuery.data]);

	// Mutation for fetching available numbers.
	const getNumbersMutation = useMutation({
		mutationKey: ["getAvailableNumbers"],
		mutationFn: (numberType: string) => authenticateAndFetchData(
			"/api/get-available-numbers?number_type=" + numberType
		),
		gcTime: 0,
		retry: retryFn,
		onSuccess: (response) => {
			let data: GetNumbersResponse = response.data;
			setAvailableNumbers(data.available_numbers);
		},
		onError: (error: ApiRequestFailed) => {
			console.error(error);
			showToast(
				"Error",
				error.data.message,
				"destructive"
			);
		}
	});

	// Mutation for purchasing number.
	const purchaseNumberMutation = useMutation({
		mutationKey: ["adminPurchaseNumber"],
		mutationFn: (data: { number: string, number_type: string }) => authenticateAndPostData(
			"/api/admin/purchase-number/",
			{
				"user_id": userID || "",
				"number": data.number,
				"number_type": data.number_type,
			}),
		gcTime: 0,
		retry: retryFn,
		onSuccess: (response) => {
			// Show alert and refetch the page data.
			showToast(
				"Purchase Successful",
				`Number ${response.data.number} has been added to this account successfully!`,
				"default",
			);
			pageDataQuery.refetch().then();
			onNumberTypeChange("");
		},
		onError: (error: ApiRequestFailed) => {
			console.error(error);
			showToast(
				"Purchase Failed",
				error.data.message,
				"destructive",
			);
		}
	});

	// Mutation for deleting purchased number
	const deletePurchasedNumberMutation = useMutation({
		mutationKey: ["adminDeletePurchasedNumber"],
		mutationFn: (numberSID: string) => authenticateAndPostData("/api/admin/release-purchased-number/", {
			"user_id": userID || "",
			"sid": numberSID,
		}),
		gcTime: 0,
		retry: retryFn,
		onSuccess: () => {
			// show alert and refetch the data.
			showToast(
				"Success",
				"Purchased number removed from this account successfully.",
				"default"
			);
			onNumberTypeChange("");
			pageDataQuery.refetch().then();
		},
		onError: (error: ApiRequestFailed) => {
			console.error(error);
			showToast(
				"Oops! Something went wrong",
				error.data.message,
				"destructive",
			);
		}
	});

	// Set up the table columns for purchased numbers.
	const purchasedNumbersTableColumns: ColumnDef<PurchasedNumber>[] = [
		{
			accessorKey: "number",
			header: "Number",
		},
		{
			accessorKey: "number_type",
			header: "Number Type",
		},
		{
			header: "Purchased On",
			accessorFn: originalRow => {
				return formattedDateTime(new Date(originalRow.purchased_on_ts));
			}
		},
		{
			header: "Remove Number",
			cell: props => {
				return (
					<RemoveNumberAlert number={props.row.original.number}
									   twilioNumberSID={props.row.original.twilio_number_sid}
									   deleteEventHandler={removePurchasedNumber}>
						<Button size="sm" variant={"destructive"}>
							<Trash className="w-4 h-4 mr-2"/>Remove
						</Button>
					</RemoveNumberAlert>
				)
			},
			enableGlobalFilter: false,
		},
	];

	// Set up the table columns for purchasing new numbers.
	const availableNumberscolumns: ColumnDef<AvailableNumbers>[] = [
		{
			accessorKey: "phone_number",
			header: "Number"
		},
		{
			accessorKey: "iso_country",
			header: "Country",
		},
		{
			header: "Voice Available",
			cell: props => {
				if (props.row.original.voice_available) {
					return <span className="text-primary">Yes</span>
				} else {
					return <span className="text-destructive">No</span>
				}
			},
			enableGlobalFilter: false,
		},
		{
			header: "SMS Available",
			cell: props => {
				if (props.row.original.sms_available) {
					return <span className="text-primary">Yes</span>
				} else {
					return <span className="text-destructive">No</span>
				}
			},
			enableGlobalFilter: false,
		},
		{
			header: "MMS Available",
			cell: props => {
				if (props.row.original.mms_available) {
					return <span className="text-primary">Yes</span>
				} else {
					return <span className="text-destructive">No</span>
				}
			},
			enableGlobalFilter: false,
		},
		{
			header: "Purchase",
			cell: props => {
				return (
					<Button size="sm"
							disabled={purchaseNumberMutation.isPending}
							onClick={() => purchaseNumberMutation.mutate(
								{number: props.row.original.phone_number, number_type: numberType}
							)}>
						<Plus className="w-4 h-4 mr-2"/>Purcahse
					</Button>
				)
			},
			enableGlobalFilter: false,
		},
	]

	function goBack() {
		navigate(urls["adminUserDetails"].replace(":userID", userID || ""));
	}

	// This will update the table on select box change.
	function onNumberTypeChange(value: string) {
		if (value === "") {
			setNumberType(value);
			setAvailableNumbers([]);
		} else {
			setNumberType(value);
			getNumbersMutation.mutate(value);
		}
	}

	/**
	 * Function to handle removing selected purchased number from user account.
	 * @param twilioNumberSID - Twilio SID for given number.
	 */
	function removePurchasedNumber(twilioNumberSID: string) {
		deletePurchasedNumberMutation.mutate(twilioNumberSID);
	}

	// ========================================================================
	// --------------------------- MAIN RENDER CODE ---------------------------
	// ========================================================================

	if (pageDataQuery.isLoading) {
		return (
			<PageLoading/>
		)

	} else if (pageDataQuery.error as unknown as ApiRequestFailed) {
		return <PageDataErrorHandler error={pageDataQuery.error as unknown as ApiRequestFailed}/>

	} else if (pageData) {
		return (
			<div className="w-full flex flex-col">
				{/* Header */}
				<div className="flex flex-row justify-start items-center">
					<Button variant="ghost" className="text-xl" onClick={goBack}>
						<ChevronLeft className="w-6 h-6 mr-2"/>Go Back
					</Button>
					<h1 className="font-helvetica-neue-bold text-xl ml-6">
						Purchased Numbers
						for <span className="text-primary">{pageData.user_email}</span> ({pageData.username})
					</h1>
				</div>
				{/* Stats */}
				<div className="w-full grid grid-cols-4 gap-6 mt-12">
					<div className="flex flex-col items-start p-4 border rounded-2xl">
						<h1 className="text-center text-lg">Local Numbers Purchased:</h1>
						<p className="text-center text-2xl font-helvetica-neue-bold mt-2">
							{pageData.local_numbers_count} / {pageData.local_numbers_max}
						</p>
					</div>
					<div className="flex flex-col items-start p-4 border rounded-2xl">
						<h1 className="text-center text-lg">Toll Free Numbers Purchased:</h1>
						<p className="text-center text-2xl font-helvetica-neue-bold mt-2">
							{pageData.tollfree_numbers_count} / {pageData.tollfree_numbers_max}
						</p>
					</div>
				</div>
				{/* Main Content */}
				<div className="w-full flex flex-col mt-6 items-center justify-center">
					{/* Purchased Numbers Table */}
					<div className="w-full">
						<DataTable columns={purchasedNumbersTableColumns} data={purchasedNumbers}/>
					</div>

					{/* Purchase New Number */}
					<div className="w-full flex flex-col items-center justify-center mt-12">
						<h1 className="text-center text-4xl font-helvetica-neue-bold">
							Purchase New Number
						</h1>
						<p className="text-center text-xl mt-2">
							Purchase a new number for this user account.
						</p>
						<div className="w-2/12 flex flex-col mt-12 mb-12">
							<Label htmlFor="number-type-select">
								Number Type:
							</Label>
							<Select value={numberType}
									onValueChange={(value) => onNumberTypeChange(value)}
									required>
								<SelectTrigger id="number-type-select" className="mt-2 border">
									<SelectValue placeholder="Select type of Number"/>
								</SelectTrigger>
								<SelectContent>
									<SelectItem value="toll_free" key="toll_free">Toll Free</SelectItem>
									<SelectItem value="local" key="local">Local</SelectItem>
								</SelectContent>
							</Select>
						</div>
						{getNumbersMutation.isPending ?
							<LoaderCircle className="w-10 h-10 animate-spin"/> :
							<DataTable columns={availableNumberscolumns} data={availableNumbers}/>}
					</div>
				</div>
			</div>
		)

	} else {
		// Ideally it should not reach here.
		return <></>
	}
}

export function RemoveNumberAlert(props: {
	children: ReactNode,
	number: string,
	twilioNumberSID: string,
	deleteEventHandler: (twilioNumberSID: string) => void
}) {
	return (
		<AlertDialog>
			<AlertDialogTrigger asChild>
				{props.children}
			</AlertDialogTrigger>
			<AlertDialogContent>
				<AlertDialogHeader>
					<AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
					<AlertDialogDescription>
						This action cannot be undone. This will permanently remove the purchased
						number <b>{props.number}</b> from this account, and release it on Twilio.
					</AlertDialogDescription>
				</AlertDialogHeader>
				<AlertDialogFooter>
					<AlertDialogCancel>Cancel</AlertDialogCancel>
					<AlertDialogAction onClick={() => props.deleteEventHandler(props.twilioNumberSID)}>
						Confirm
					</AlertDialogAction>
				</AlertDialogFooter>
			</AlertDialogContent>
		</AlertDialog>
	)
}
