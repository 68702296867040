import {buttonVariants} from "@components/ui/button";
import {ArrowLeft} from "lucide-react";
import {Link} from "react-router-dom";
import {urls} from "@routes";

export default function QuotaExhausted() {
    return (
        <div className="flex flex-col justify-center items-center w-full mt-12">
            <div className="flex flex-col items-center w-5/12 px-6 py-12 border-2 rounded-2xl">
                <h1 className="text-4xl font-extrabold text-center">
                    You've Reached Your Call Limit
                </h1>
                <p className="text-xl mt-4 text-center leading-relaxed text-muted-foreground">
                    You have used up your allocated call time. If you would like to <b>gain some additional minutes</b>,
                    you can get in touch with us on our <b>live chat</b>!
                </p>
                <Link to={urls["dashboard"]} className={buttonVariants({variant: "default", className: "mt-12"})}>
                    <ArrowLeft/>&nbsp;&nbsp;Go back to Dashboard
                </Link>
            </div>
        </div>
    )
}