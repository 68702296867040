import loadingIcon from "@images/loading_icon.png";
import {getCurrentTheme} from "@lib/utils";

export default function PageLoading() {
    return (
        <div className="flex flex-col justify-center items-center w-full mt-48 text-muted-foreground">
            {getCurrentTheme() === "dark" ?
                <img src={loadingIcon} alt="loading..." className="animate-loading-logo-fade" width={100} height={100}/> :
                <img src={loadingIcon} alt="loading..." className="animate-loading-logo-fade contrast-0" width={100} height={100}/>}
        </div>
    );
}