import CsvUpload from "@components/ui/csv-upload";
import {useEffect, useState} from "react";
import {Label} from "@components/ui/label";
import {Input} from "@components/ui/input";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "@components/ui/select";
import {Textarea} from "@components/ui/textarea";
import {Tooltip, TooltipContent, TooltipProvider, TooltipTrigger} from "@components/ui/tooltip";
import {CircleHelp, LoaderCircle, Plus} from "lucide-react";
import {Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle} from "@components/ui/dialog";
import {Button} from "@components/ui/button";
import {useNavigate, useOutletContext} from "react-router-dom";
import {BaseOutletContext} from "@pages/Base";
import {ApiRequestFailed, authenticateAndFetchData, authenticateAndPostData, retryFn} from "@lib/apis";
import {useMutation, useQuery} from "@tanstack/react-query";
import PageLoading from "@components/ui/pageloading";
import PageDataErrorHandler from "@components/data/pageDataErrorHandler";
import {urls} from "@routes";


interface PageData {
	status_code: number
	status_text: string
	voice_details: Array<VoiceDetails>
}

interface VoiceDetails {
	source: string
	voice_id: string
	voice_name: string
	gender: "M" | "F"
	personalities: Array<string>
	nationality: string
	sample_audio_url: string | null
}

interface CreateBulkMeetingsPostResponse {
	project_uid: string
}


export default function CreateBulkDelegateMeetingProject() {
	const {showToast} = useOutletContext<BaseOutletContext>();
	const navigate = useNavigate();
	const [pageData, setPageData] = useState<PageData>();
	const [
		allNationalities,
		setAllNationalities
	] = useState<Array<string>>([]);
	const [
		filteredVoices,
		setFilteredVoices
	] = useState<Array<{ voiceId: string, voiceName: string }>>([]);

	const [contactList, setContactList] = useState<Array<Array<string>>>([]);
	const [projectName, setProjectName] = useState("");
	const [gender, setGender] = useState("");
	const [nationality, setNationality] = useState("");
	const [voiceID, setVoiceID] = useState("");
	const [voiceSampleURL, setVoiceSampleURL] = useState<string | null>(null);
	const [language, setLanguage] = useState("");
	const [aiCallName, setAiCallName] = useState("");
	const [userCallName, setUserCallName] = useState("");
	const [callInstructions, setCallInstructions] = useState<string>("");
	const [postCallInstructions, setPostCallInstructions] = useState<string>("");

	const [
		openSelectColumnsDialog,
		setOpenSelectColumnsDialog
	] = useState<boolean>(false);
	const [
		rows,
		setRows
	] = useState<Array<Array<string>>>([]);
	const [
		colNames,
		setColNames
	] = useState<Array<string>>([]);
	const [
		minRequiredCallTime,
		setMinRequiredCallTime
	] = useState<number>();

	const MINUTES_PER_PARTICIPANT = 1

	const agentInstructionTooltipText = "AI will follow these instructions to generate " +
		"the results. Ex. You can ask it to summarize the conversation or give ratings."

	// Query for fetching page data
	const pageDataQuery = useQuery({
		queryKey: ["bulkMeetingsPageData"],
		queryFn: () => authenticateAndFetchData("/api/create-bulk-meetings/"),
		gcTime: 0,
		refetchOnWindowFocus: false,
		retry: retryFn,
	});

	// Mutation for creating new project.
	const createProjectMutation = useMutation({
		mutationKey: ["createBulkDelegateMeetingProject"],
		mutationFn: (emails: Array<string>) => authenticateAndPostData("/api/create-bulk-meetings/", {
			project_name: projectName,
			emails: emails,
			language: language,
			voice_id: voiceID,
			ai_call_name: aiCallName,
			call_creator_name: userCallName,
			call_instructions: callInstructions,
			post_call_instructions: postCallInstructions,
		}),
		gcTime: 0,
		retry: retryFn,
		onSuccess: (response => {
			let data = response.data as CreateBulkMeetingsPostResponse;
			showToast(
				"Success!",
				`Project ${data.project_uid} has been created successfully.`,
				"default"
			);
			navigate(urls["bulkDelegateMeetingProject"].replace(":projectUID", data.project_uid));
		}),
		onError: (error: ApiRequestFailed) => {
			console.error(error);
			showToast(
				"Oops! Something went wrong.",
				error.data.message,
				"destructive",
			)
		}
	});

	// Sets page date when query GET request is complete.
	useEffect(() => {
		if (pageDataQuery.data) {
			setPageData(pageDataQuery.data.data as PageData);
		}
	}, [pageDataQuery.data]);

	// This will fetch all distinct nationalities from the voice data.
	useEffect(() => {
		if (pageData) {
			let nationalities: Array<string> = []
			pageData.voice_details.forEach(voice => {
				if (!nationalities.includes(voice.nationality)) {
					nationalities.push(voice.nationality);
				}
			});
			setAllNationalities(nationalities);
		}
	}, [pageData]);

	// This will update voice list when any of page data, gender or nationality changes.
	useEffect(() => {
		if (pageData && gender && nationality) {
			setFilteredVoices(pageData.voice_details.filter(voice => {
				return (voice.gender === gender) && (voice.nationality === nationality) && (voice.source === "cartesia")
			}).map(voice => {
				return {voiceId: voice.voice_id, voiceName: voice.voice_name}
			}));
			setVoiceID("");
		}
	}, [pageData, gender, nationality]);

	/**
	 * Handles event when csv file is selected by user.
	 * @param columnNames - Array of column names
	 * @param rows - Array of row data.
	 */
	function csvFileSelected(columnNames: Array<string>, rows: Array<Array<string>>) {
		setColNames(columnNames)
		setRows(rows)
		setOpenSelectColumnsDialog(true);
		setMinRequiredCallTime(rows.length * MINUTES_PER_PARTICIPANT)
	}

	function changeVoice(voiceId: string) {
		if (pageData) {
			let voice: VoiceDetails = pageData.voice_details.filter(value => {
				return value.voice_id === voiceId
			})[0];

			setVoiceID(voiceId);
			setVoiceSampleURL(voice.sample_audio_url);
		}
	}

	function handleProjectCreation() {
		if ((contactList.length > 0) && projectName && voiceID && language && aiCallName &&
			userCallName && callInstructions && postCallInstructions) {

			// Fetch all emails from csv data.
			let emails: Array<string> = [];
			contactList.forEach(row => {
				emails.push(row[0])
			});
			createProjectMutation.mutate(emails);

		} else {
			showToast(
				"Missing Values",
				"Please make sure all the fields are filled in.",
				"destructive"
			)
		}
	}

	// ========================================================================
	// --------------------------- MAIN RENDER CODE ---------------------------
	// ========================================================================

	if (pageDataQuery.isLoading) {
		return (
			<PageLoading/>
		)

	} else if (pageDataQuery.error as unknown as ApiRequestFailed) {
		return <PageDataErrorHandler error={pageDataQuery.error as unknown as ApiRequestFailed}/>

	} else if (pageData) {
		return (
			<div className={"w-100 flex flex-col items-center justify-center border rounded-2xl mt-12 px-6 py-6 md:py-12"}>
				<h1 className={"font-helvetica-neue-bold text-2xl text-center"}>
					Create New Bulk Delegate Meeting Project
				</h1>
				<p className={"mt-2 text-xl text-center w-full xl:w-1/2"}>
					Upload your contact list and let our AI share personalized meeting room links to hundreds of customers, leads,
					or prospects at once.
				</p>
				{/* ------------------ Contact List ------------------ */}
				<CsvUpload classname={"mt-8 w-full md:w-1/2"} fileSelectedCallback={csvFileSelected}/>
				<p className="mt-4 text-center font-bold">
					Upload a .csv file containing 1 column - email id of users. Max 25 email ids.
					<br/>Make sure the CSV has a header row with appropriate column names.
				</p>
				{minRequiredCallTime && <div className={"w-full md:w-1/2 rounded-2xl mt-8 p-6 bg-secondary"}>
					<p className={"text-center"}>
						NOTE: You will require at least {minRequiredCallTime} minutes of
						remaining meeting call time to create this project.
					</p>
				</div>}
				<div className="mt-8 w-full xl:w-1/2 border rounded-2xl p-6 flex flex-col justify-center items-center">
					{/* ------------------ PROJECT NAME ------------------ */}
					<div className="w-full flex flex-col">
						<Label htmlFor="project-name">
							Give a name for this project:
						</Label>
						<Input type={"text"}
									 id="project-name"
									 className="mt-4"
									 value={projectName}
									 onChange={e => setProjectName(e.target.value)}
									 placeholder="Project Name"
									 required/>
					</div>
					{/* ------------------ GENDER & NATIONALITY SELECTION ------------------ */}
					<div className={"w-full grid grid-cols-2 mt-6 gap-6"}>
						<div className="w-full flex flex-col">
							<Label htmlFor="gender-select">
								Talk with:
							</Label>
							<Select onValueChange={setGender} required>
								<SelectTrigger id="gender-select" className="mt-4">
									<SelectValue placeholder={"Select Gender"}/>
								</SelectTrigger>
								<SelectContent>
									<SelectItem value="M">Him</SelectItem>
									<SelectItem value="F">Her</SelectItem>
								</SelectContent>
							</Select>
						</div>
						<div className="w-full flex flex-col">
							<Label htmlFor="nationality-select">
								AI Nationality:
							</Label>
							<Select onValueChange={setNationality} required>
								<SelectTrigger id="nationality-select" className="mt-4">
									<SelectValue placeholder={"Select Nationality"}/>
								</SelectTrigger>
								<SelectContent>
									{allNationalities.map(nationality => (
										<SelectItem value={nationality} key={nationality}>{nationality}</SelectItem>
									))}
								</SelectContent>
							</Select>
						</div>
					</div>
					{/* ------------------ VOICE LIST ------------------ */}
					<div className="w-full flex flex-col mt-6">
						<Label htmlFor="voice-model-select">
							Select Voice Type:
						</Label>
						<Select value={voiceID} onValueChange={changeVoice} required>
							<SelectTrigger id="voice-model-select" className="mt-4">
								<SelectValue placeholder={"Select Voice"}/>
							</SelectTrigger>
							<SelectContent>
								{filteredVoices.length === 0 && <p className="text-center text-sm py-4">No Voice Available.</p>}
								{filteredVoices.map(voice => (
									<SelectItem value={voice.voiceId} key={voice.voiceId}>
										{voice.voiceName}
									</SelectItem>
								))}
							</SelectContent>
						</Select>
						{voiceSampleURL && <audio className="mt-4"
                                      controls
                                      src={voiceSampleURL}></audio>}
					</div>
					{/* ------------------ CALL LANGUAGE ------------------ */}
					<div className="w-full flex flex-col mt-6">
						<Label htmlFor="language-select">
							Select Language:
						</Label>
						<Select value={language} onValueChange={setLanguage} required>
							<SelectTrigger id="language-select" className="mt-4">
								<SelectValue placeholder={"Select Language"}/>
							</SelectTrigger>
							<SelectContent>
								<SelectItem value={"English"}>
									English
								</SelectItem>
							</SelectContent>
						</Select>
					</div>
					{/* ------------------ USER & AI NAME ------------------ */}
					<div className={"w-full grid grid-cols-2 mt-6 gap-6"}>
						{/* AI */}
						<div className="w-full flex flex-col">
							<Label htmlFor="ai-name">
								AI Name:
							</Label>
							<Input type="text"
										 id="ai-name"
										 className="mt-4"
										 value={aiCallName}
										 onChange={e => setAiCallName(e.target.value)}
										 placeholder="ex. John"
										 required/>
						</div>
						{/* USER */}
						<div className="w-full flex flex-col">
							<Label htmlFor="user-name">
								Your Name:
							</Label>
							<Input type="text"
										 id="user-name"
										 className="mt-4"
										 value={userCallName}
										 onChange={e => setUserCallName(e.target.value)}
										 placeholder="ex. Jane"
										 required/>
						</div>
					</div>
					{/* ------------------ CALL INSTRUCTIONS ------------------ */}
					<div className="w-full flex flex-col mt-6">
						<Label htmlFor="call-instructions">
							What should the AI do during the call?
						</Label>
						<Textarea id="call-instructions"
											className="mt-4 border"
											value={callInstructions}
											onChange={e => setCallInstructions(e.target.value)}
											placeholder="Ex. Ask if callee is available at 6 PM today for..."
											rows={5}
											required/>
					</div>
					{/* ------------------ POST CALL INSTRUCTIONS ------------------ */}
					<div className="w-full flex flex-col mt-6">
						<Label htmlFor="post-call-instructions" className="flex">
							<QuestionMarkTooltip text={agentInstructionTooltipText}/>
							&nbsp;&nbsp;What should the AI do after the call?
						</Label>
						<Textarea id="post-call-instructions"
											className="mt-4"
											value={postCallInstructions}
											onChange={e => setPostCallInstructions(e.target.value)}
											placeholder="Ex. Generate a summary of the conversation."
											rows={5}
											required/>
					</div>
					<Button className="mt-8"
									onClick={handleProjectCreation}
									disabled={createProjectMutation.isPending}>
						{createProjectMutation.isPending ?
							<><LoaderCircle className="w-4 h-4 mr-2 animate-spin"/>Creating...</> :
							<><Plus className="w-4 h-4 mr-2"/>Create Bulk Call</>}
					</Button>
				</div>
				<SelectColumnsDialog open={openSelectColumnsDialog}
														 setOpen={setOpenSelectColumnsDialog}
														 colNames={colNames}
														 rows={rows}
														 setContactList={setContactList} callToast={showToast}/>
			</div>
		)

	} else {
		// Ideally it should not reach here.
		return <></>
	}
}

function QuestionMarkTooltip(props: { text: string }) {
	return (
		<TooltipProvider>
			<Tooltip>
				<TooltipTrigger asChild>
					<CircleHelp className="h-4 w-4 cursor-pointer text-muted-foreground"/>
				</TooltipTrigger>
				<TooltipContent className={"w-72"}>
					<p>{props.text}</p>
				</TooltipContent>
			</Tooltip>
		</TooltipProvider>
	)
}

function SelectColumnsDialog(props: {
	open: boolean,
	setOpen: (value: boolean) => void,
	rows: Array<Array<string>>,
	colNames: Array<string>,
	setContactList: (value: Array<Array<string>>) => void,
	callToast: (title: string, text: string, variant: "default" | "destructive") => void,
}) {

	const [
		emailColIndex,
		setEmailColIndex
	] = useState<number | null>(null);

	/**
	 * Updates contactList state and closes the Dialog.
	 */
	function confirmButtonHandler() {
		if (emailColIndex === null) {
			props.callToast(
				"Column Not Set",
				"Please select the email id column.",
				"destructive"
			)

		} else {
			// Fetch all email ids from email id column.
			let contacts: Array<Array<string>> = []
			props.rows.forEach(row => {
				contacts.push([row[emailColIndex]]);
			});
			console.log(contacts);
			props.setContactList(contacts);

			// Show a simple acknowledgement message.
			props.callToast(
				"CSV File Ready",
				"Email ID column has been set. CSV file is ready for upload!",
				"default"
			)

			// Close the dialog.
			props.setOpen(false);
		}
	}

	return (
		<Dialog open={props.open} onOpenChange={props.setOpen}>
			<DialogContent hideCloseButton={true} className="sm:max-w-[425px]">
				<DialogHeader>
					<DialogTitle>Select Column</DialogTitle>
					<DialogDescription>
						Select the correct <b>Email ID</b> column in your uploaded CSV file.
					</DialogDescription>
				</DialogHeader>
				<div className="grid gap-4 py-4">
					{/* fetch name column index */}
					<div className="w-full flex flex-col">
						<Label htmlFor="email-id-col-index">
							Email ID Column:
						</Label>
						<Select onValueChange={value => setEmailColIndex(parseInt(value))} required>
							<SelectTrigger id="email-id-col-index" className="mt-4">
								<SelectValue placeholder={"Select Email ID Column"}/>
							</SelectTrigger>
							<SelectContent>
								{props.colNames.map((name, index) => (
									<SelectItem value={index.toString()} key={index}>
										{name}
									</SelectItem>
								))}
							</SelectContent>
						</Select>
					</div>
				</div>
				<DialogFooter>
					<div className="flex flex-row justify-end">
						<Button onClick={confirmButtonHandler} className="ml-4">Continue</Button>
					</div>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	)
}
