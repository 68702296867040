import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {useMutation, useQuery} from "@tanstack/react-query";
import {ApiRequestFailed, authenticateAndFetchData, authenticateAndPostData, retryFn} from "@lib/apis";
import PageLoading from "@components/ui/pageloading";
import PageDataErrorHandler from "@components/data/pageDataErrorHandler";
import {Label} from "@components/ui/label";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "@components/ui/select";
import {Input} from "@components/ui/input";
import {Textarea} from "@components/ui/textarea";
import {Checkbox} from "@components/ui/checkbox";
import {Button} from "@components/ui/button";
import {Save} from "lucide-react";
import {Badge} from "@components/ui/badge";
import {urls} from "@routes";

interface PageData {
    status_code: number
    status_text: string

    voice_name: string
    gender: string
    personalities: Array<string>
    nationality: string
    is_active: boolean

    elevenlabs_model: string
}

export default function AdminEditVoice() {
    const {source, voiceID} = useParams();
    const navigate = useNavigate();

    const [pageData, setPageData] = useState<PageData>();
    const [voiceName, setVoiceName] = useState<string>("");
    const [voiceGender, setVoiceGender] = useState<string>("");
    const [voiceNationality, setVoiceNationality] = useState<string>("");
    const [voicePersonality, setVoicePersonality] = useState<string>("");
    const [isActive, setIsActive] = useState<boolean>(false);
    const [elevenlabsVoiceModel, setElevenlabsVoiceModel] = useState<string>("");
    const [errorText, setErrorText] = useState<string>("");

    // Fetch page data
    const pageDataQuery = useQuery({
        queryKey: ["getVoiceDetails", source, voiceID],
        queryFn: () => authenticateAndFetchData("/api/admin/get-voice-details?voice_id=" + voiceID),
        gcTime: 0,
        retry: retryFn
    });
    useEffect(() => {
        if (pageDataQuery.data) {
            let data = pageDataQuery.data.data as PageData;
            setPageData(data);
            setVoiceName(data.voice_name);
            setVoiceGender(data.gender);
            setVoiceNationality(data.nationality);
            setVoicePersonality(data.personalities.join("\n"))
            setIsActive(data.is_active);
            setElevenlabsVoiceModel(data.elevenlabs_model);
        }
    }, [pageDataQuery.data]);

    // Mutation for editing voice.
    const editVoiceMutation = useMutation({
        mutationKey: ["editVoice"],
        mutationFn: () => authenticateAndPostData("/api/admin/edit-voice/", {
            "voice_id": voiceID,
            "voice_name": voiceName,
            "gender": voiceGender,
            "personalities": voicePersonality.split("\n"),
            "nationality": voiceNationality,
            "elevenlabs_model": elevenlabsVoiceModel,
            "is_active": isActive,
        }),
        gcTime: 0,
        retry: retryFn,
        onSuccess: () => {
            navigate(urls["adminVoiceBuilder"]);
        },
        onError: (error: ApiRequestFailed) => {
            setErrorText(error.data.message);
            console.error(error);
        }
    });

    /**
     * Checks values and makes API request to edit this voice.
     */
    function editVoice() {
        setErrorText("");
        if (source === "elevenlabs") {
            if (voiceID && voiceName && voiceGender && voiceNationality && voicePersonality && elevenlabsVoiceModel) {
                editVoiceMutation.mutate();

            } else {
                setErrorText("Please fill in all the required fields for elevenlabs.")
            }

        } else if (["azure", "cartesia"].includes(source || "")) {
            if (voiceID && voiceName && voiceGender && voiceNationality && voicePersonality) {
                editVoiceMutation.mutate();

            } else {
                setErrorText("Please fill in all the required fields for azure.")
            }

        } else {
            setErrorText("Missing voice source.");
        }
    }

    useEffect(() => {
        console.log(isActive)
    }, [isActive]);

    // ========================================================================
    // --------------------------- MAIN RENDER CODE ---------------------------
    // ========================================================================

    if (pageDataQuery.isLoading) {
        return (
            <PageLoading/>
        )

    } else if (pageDataQuery.error as unknown as ApiRequestFailed) {
        return <PageDataErrorHandler error={pageDataQuery.error as unknown as ApiRequestFailed}/>

    } else if (pageData) {
        return (
            <div className="w-full flex flex-col items-center justify-center">
                <h1 className="text-4xl font-helvetica-neue-bold">Edit Voice</h1>
                <div className="w-4/12 flex flex-col mt-10 px-6 py-10 border-2 border-muted-foreground/30 rounded-2xl transition-all">
                    {/* AI Voice Name & Gender Selection */}
                    <div className="flex items-center">
                        <Badge variant="secondary">Voice ID: {voiceID}</Badge>
                    </div>
                    <div className="w-full grid grid-cols-2 mt-6 gap-6">
                        <div className="w-full flex flex-col">
                            <Label htmlFor="voice-model-name">
                                Voice Model Name:
                            </Label>
                            <Input type="text"
                                   id="voice-model-name"
                                   className="mt-2 border-muted-foreground/50"
                                   value={voiceName}
                                   onChange={e => setVoiceName(e.target.value)}
                                   placeholder="ex. Chris"/>
                        </div>
                        <div className="w-full flex flex-col">
                            <Label htmlFor="gender-select">
                                Select Gender:
                            </Label>
                            <Select onValueChange={setVoiceGender} defaultValue={voiceGender} required>
                                <SelectTrigger id="gender-select" className="mt-2 border-muted-foreground/50">
                                    <SelectValue placeholder="Gender"/>
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectItem value="M" key="Male">Male</SelectItem>
                                    <SelectItem value="F" key="Female">Female</SelectItem>
                                </SelectContent>
                            </Select>
                        </div>
                    </div>
                    {/* Voice Nationality */}
                    <div className="w-full flex flex-col mt-6">
                        <Label htmlFor="voice-nationality">
                            Voice Nationality / Accent:
                        </Label>
                        <Input type="text"
                               id="voice-nationality"
                               className="mt-2 border-muted-foreground/50"
                               value={voiceNationality}
                               onChange={e => setVoiceNationality(e.target.value)}
                               placeholder="ex. Indian"/>
                    </div>
                    {/* Voice Personality */}
                    <div className="w-full flex flex-col mt-6">
                        <Label htmlFor="voice-personality-list">
                            Personality (<a href="https://ideonomy.mit.edu/essays/traits.html"
                                            rel="noreferrer"
                                            target="_blank"
                                            className="underline">refer to this site</a>):
                        </Label>
                        <Textarea id="voice-personality-list"
                                  className="mt-2 border-muted-foreground/50"
                                  rows={4}
                                  value={voicePersonality}
                                  placeholder="Add one personality on each line (1 Positive, 2 Neutral & 1 Negative)"
                                  onChange={e => setVoicePersonality(e.target.value)}>
                        </Textarea>
                    </div>

                    {/* ------------------- ElevenLabs only ------------------- */}
                    <hr className="my-8"/>
                    <div className="w-full flex flex-col">
                        <Label htmlFor="elevenlabs-model-select">
                            ElevenLabs Model <b>(Only for "elevenlabs" source)</b>:
                        </Label>
                        <Select onValueChange={setElevenlabsVoiceModel} defaultValue={elevenlabsVoiceModel} required>
                            <SelectTrigger id="elevenlabs-model-select" className="mt-2 border-muted-foreground/50">
                                <SelectValue placeholder="Select Model"/>
                            </SelectTrigger>
                            <SelectContent>
                                <SelectItem value="eleven_turbo_v2" key="eleven_turbo_v2">
                                    Turbo V2
                                </SelectItem>
                                <SelectItem value="eleven_multilingual_v2" key="eleven_multilingual_v2">
                                    Multilingual V2
                                </SelectItem>
                            </SelectContent>
                        </Select>
                    </div>

                    {/* Activate Voice */}
                    <hr className="my-8"/>
                    <div className="w-full flex flex-col">
                        <div className="flex items-center space-x-2">
                            <Checkbox id="terms"
                                      className="rounded"
                                      defaultChecked={isActive}
                                      onClick={() => {
                                          setIsActive(!isActive);
                                      }}/>
                            <label
                                htmlFor="terms"
                                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                            >
                                Activate Voice
                            </label>
                        </div>
                    </div>
                    <Button className="mt-8" onClick={editVoice}>
                        <Save className="w-5 h-5"/>&nbsp;&nbsp;Save Changes
                    </Button>
                    {errorText && <p className="text-destructive mt-4 text-center">{errorText}</p>}
                </div>
            </div>
        )

    } else {
        // Ideally it should not reach here.
        return <></>
    }
}
