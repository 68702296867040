import {ColumnDef} from "@tanstack/react-table";
import {formattedDateTime} from "@lib/utils";
import {Dot} from "lucide-react";
import {Link} from "react-router-dom";
import {urls} from "@routes";
import {buttonVariants} from "@components/ui/button";
import DataTable from "@components/ui/datatable";

export interface ConversationMeetingCall {
	call_uid: string
	caller_name: string
	call_status: string
	date: number  // timestamp
}


export default function ConversationMeetingCallLogs(props: {
	calls: Array<ConversationMeetingCall>
}) {
	// Table columns
	const columns: ColumnDef<ConversationMeetingCall>[] = [
		{
			accessorKey: "caller_name",
			header: "Caller (AI) Name"
		},
		{
			accessorKey: "date",
			header: "Date",
			accessorFn: originalRow => {
				return formattedDateTime(new Date(originalRow.date));
			}
		},
		{
			header: "Status",
			cell: props => {
				if (props.row.original.call_status === "connecting") {
					return (
						<div className="flex flex-row items-center">
							<Dot className="w-8 h-8 mr-2" color="#ff9200"/>&nbsp;Connecting...
						</div>
					)

				} else if (props.row.original.call_status === "ongoing") {
					return (
						<div className="flex flex-row items-center">
							<Dot className="w-8 h-8 mr-2" color="#00f900"/>&nbsp;Call Ongoing
						</div>
					)

				} else {
					return (
						<div className="flex flex-row items-center">
							<Dot className="w-8 h-8 mr-2" color="#ff2600"/>&nbsp;Ended
						</div>
					)
				}
			}
		},
		{
			header: "View Call Details",
			cell: props => {
				return (
					<Link to={urls["meetingCallLogDetails"].replace(
						":callType",
						"conversation_meeting"
					).replace(
						":callUID",
						props.row.original.call_uid
					)}
								className={buttonVariants({variant: "default", size: "sm"})}>
						View Details
					</Link>
				)
			},
			enableGlobalFilter: false
		}
	]

	return (
		<div className="w-full mt-4">
			<DataTable columns={columns} data={props.calls}/>
		</div>
	)
}
