import {CircleHelp, Plus} from "lucide-react";
import {Label} from "@components/ui/label";
import {Textarea} from "@components/ui/textarea";
import {Tooltip, TooltipContent, TooltipProvider, TooltipTrigger} from "@components/ui/tooltip";
import {Button} from "@components/ui/button";
import {Input} from "@components/ui/input";
import {useEffect, useState} from "react";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "@components/ui/select";
import CsvUpload from "@components/ui/csv-upload";
import {allTimezones} from "react-timezone-select"
// import {TimePicker} from "@components/ui/timepicker";
import {Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle} from "@components/ui/dialog";
import {useToast} from "@components/ui/use-toast";
import {cn} from "@lib/utils";
import {useMutation, useQuery} from "@tanstack/react-query";
import {ApiRequestFailed, authenticateAndFetchData, authenticateAndPostData, retryFn} from "@lib/apis";
import {useNavigate} from "react-router-dom";
import {urls} from "@routes";

interface PageData {
	status_code: number
	status_text: string
	voice_details: Array<VoiceDetails>
}

interface VoiceDetails {
	source: string
	voice_id: string
	voice_name: string
	gender: "M" | "F"
	personalities: Array<string>
	nationality: string
	sample_audio_url: string | null
}

interface CreateBulkCallResponse {
	project_uid: string
}

export default function BulkCallsCreate() {
	const {toast} = useToast();
	const navigate = useNavigate();

	const [pageData, setPageData] = useState<PageData>();
	const [
		allNationalities,
		setAllNationalities
	] = useState<Array<string>>([]);
	const [
		filteredVoices,
		setFilteredVoices
	] = useState<Array<{ voiceId: string, voiceName: string }>>([]);

	const [contactList, setContactList] = useState<Array<Array<string>>>([]);
	const [projectName, setProjectName] = useState("");
	const [gender, setGender] = useState("");
	const [nationality, setNationality] = useState("");
	const [voiceID, setVoiceID] = useState("");
	const [voiceSampleURL, setVoiceSampleURL] = useState<string | null>(null);
	const [aiCallName, setAiCallName] = useState("");
	const [userCallName, setUserCallName] = useState("");
	const [callInstructions, setCallInstructions] = useState<string>("");
	const [postCallInstructions, setPostCallInstructions] = useState<string>("");
	const [callTimezone, setCallTimezone] = useState("Etc/GMT");

	// const [callFromTime, setCallFromTime] = useState<Date>();
	// const [callToTime, setCallToTime] = useState<Date>();

	const [fromHours, setFromHours] = useState<number>(12);
	const [fromMinutes, setFromMinutes] = useState<number>(0);
	const [fromSeconds, setFromSeconds] = useState<number>(0);
	const [toHours, setToHours] = useState<number>(18);
	const [toMinutes, setToMinutes] = useState<number>(0);
	const [toSeconds, setToSeconds] = useState<number>(0);

	const [openSelectColumnsDialog, setOpenSelectColumnsDialog] = useState<boolean>(false);
	const [rows, setRows] = useState<Array<Array<string>>>([]);
	const [colNames, setColNames] = useState<Array<string>>([]);

	const agentInstructionTooltipText = "AI will follow these instructions to generate " +
		"the results. Ex. You can ask it to summarize the conversation or give ratings."

	// const labelStyle = "original"
	const timezones = {
		...allTimezones,
		"Europe/Berlin": "Frankfurt",
	}
	// const {options, parseTimezone} = useTimezoneSelect(
	// 	{labelStyle, timezones}
	// )

	// Query for fetching page data
	const pageDataQuery = useQuery({
		queryKey: ["bulkCallsPageData"],
		queryFn: () => authenticateAndFetchData("/api/bulk-calls/"),
		gcTime: 0,
		refetchOnWindowFocus: false,
		retry: retryFn,
	});
	useEffect(() => {
		if (pageDataQuery.data) {
			setPageData(pageDataQuery.data.data as PageData);
		}
	}, [pageDataQuery.data]);

	// Mutation for creating bulk call project.
	const createBulkCallMutation = useMutation({
		mutationKey: ["createBulkCallMutation"],
		mutationFn: () => authenticateAndPostData("/api/bulk-calls/", {
			"contact_data": contactList,
			"project_name": projectName,
			"voice_id": voiceID,
			"ai_call_name": aiCallName,
			"user_call_name": userCallName,
			"ai_call_instructions": callInstructions,
			"post_call_instructions": postCallInstructions,
			"call_timezone": callTimezone,
			"from_hour": fromHours,
			"from_minute": fromMinutes,
			"from_seconds": fromSeconds,
			"to_hour": toHours,
			"to_minute": toMinutes,
			"to_seconds": toSeconds,
		}),
		gcTime: 0,
		retry: retryFn,
		onSuccess: (response) => {
			// Send user to project page
			let data: CreateBulkCallResponse = response.data;
			callToast(
				"Bulk Call Execution Started",
				"All calls are now queued and being processed. Progress can be viewed in History tab.",
				"default",
			);
			navigate(urls["bulkCallsProjectDetails"].replace(":projectUID", data.project_uid))
		},
		onError: (error: ApiRequestFailed) => {
			callToast(
				"Error",
				error.data.message,
				"destructive",
			);
		}
	})

	// This will fetch all distinct nationalities from the voice data.
	useEffect(() => {
		if (pageData) {
			let nationalities: Array<string> = []
			pageData.voice_details.forEach(voice => {
				if (!nationalities.includes(voice.nationality)) {
					nationalities.push(voice.nationality);
				}
			});
			setAllNationalities(nationalities);
		}
	}, [pageData]);

	// This will update voice list when any of page data, gender or nationality changes.
	useEffect(() => {
		if (pageData && gender && nationality) {
			setFilteredVoices(pageData.voice_details.filter(voice => {
				return (voice.gender === gender) && (voice.nationality === nationality)  && (voice.source !== "cartesia")
			}).map(voice => {
				return {voiceId: voice.voice_id, voiceName: voice.voice_name}
			}));
			setVoiceID("");
		}
	}, [pageData, gender, nationality]);

	/**
	 * Handles event when csv file is selected by user.
	 * @param columnNames - Array of column names
	 * @param rows - Array of row data.
	 */
	function csvFileSelected(columnNames: Array<string>, rows: Array<Array<string>>) {
		setColNames(columnNames)
		setRows(rows)
		setOpenSelectColumnsDialog(true);
	}

	/**
	 * Fires when any value in start time is changed.
	 * @param value - new date value.
	 */
	// function startTimeSelectionHandler(value: Date | undefined) {
	// 	setCallFromTime(value);
	//
	// 	if (value) {
	// 		setFromHours(value.getHours());
	// 		setFromMinutes(value.getMinutes());
	// 		setFromSeconds(value.getSeconds());
	// 	}
	// }

	/**
	 * Fires when any value in end time is changed.
	 * @param value - new date value.
	 */
	// function endTimeSelectionHandler(value: Date | undefined) {
	// 	setCallToTime(value);
	//
	// 	if (value) {
	// 		setToHours(value.getHours());
	// 		setToMinutes(value.getMinutes());
	// 		setToSeconds(value.getSeconds());
	// 	}
	// }

	/**
	 * Makes API request to create bulk call.
	 */
	function createBulkCall() {
		if ((contactList.length > 0) && projectName && voiceID && aiCallName && userCallName &&
			callInstructions && postCallInstructions && callTimezone) {

			if (toHours - fromHours >= 1) {
				console.log(callTimezone);
				console.log(fromHours, fromMinutes, fromSeconds);
				console.log(toHours, toMinutes, toSeconds);
				createBulkCallMutation.mutate();

			} else {
				callToast(
					"Invalid Calling Time.",
					"Please make sure there is at least 1 hour of calling time available.",
					"destructive"
				);
			}

		} else {
			callToast(
				"Missing Required Information",
				"We're missing some essential details. " +
				"Please fill in all the fields to successfully create the bulk call.",
				"destructive"
			);
		}
	}

	/**
	 * Shows a toast on top right for a few seconds.
	 * @param title - Toast title.
	 * @param text - Toast main text.
	 * @param variant - Type of toast.
	 */
	function callToast(title: string, text: string, variant: "default" | "destructive") {
		toast({
			title: title,
			description: text,
			variant: variant,
			className: cn(
				'top-0 right-0 flex fixed md:max-w-[420px] md:top-4 md:right-4'
			),
		});
	}

	function changeVoice(voiceId: string) {
		if (pageData) {
			let voice: VoiceDetails = pageData.voice_details.filter(value => {
				return value.voice_id === voiceId
			})[0];

			setVoiceID(voiceId);
			setVoiceSampleURL(voice.sample_audio_url);
		}
	}

	// ========================================================================
	// --------------------------- MAIN RENDER CODE ---------------------------
	// ========================================================================

	return (
		<div className="w-full border rounded-2xl px-6 py-6 md:py-12 flex flex-col justify-center items-center">
			<h1 className="font-helvetica-neue-bold text-2xl text-center">
				Create New Bulk Call Project
			</h1>
			<p className="mt-2 text-xl text-center w-full xl:w-1/2">
				Upload your contact list and let our AI make personalized calls to hundreds of customers,
				leads, or prospects at once.
			</p>
			<CsvUpload classname={"mt-8 w-full md:w-1/2"} fileSelectedCallback={csvFileSelected}/>
			<p className="mt-4 text-center font-bold">
				Upload a .csv file containing 2 columns - phone number & name. Max 100 numbers.
				<br/>Make sure the CSV has a header row with appropriate column names.
			</p>
			<div className="mt-8 w-full xl:w-1/2 border rounded-2xl p-6 flex flex-col justify-center items-center">
				{/* ------------------ PROJECT NAME ------------------ */}
				<div className="w-full flex flex-col">
					<Label htmlFor="project-name">
						Give a name for this bulk call project:
					</Label>
					<Input type={"text"}
						   id="project-name"
						   className="mt-4"
						   value={projectName}
						   onChange={e => setProjectName(e.target.value)}
						   placeholder="Project Name"
						   required/>
				</div>
				{/* ------------------ GENDER & NATIONALITY SELECTION ------------------ */}
				<div className={"w-full grid grid-cols-2 mt-6 gap-6"}>
					<div className="w-full flex flex-col">
						<Label htmlFor="gender-select">
							Talk with:
						</Label>
						<Select onValueChange={setGender} required>
							<SelectTrigger id="gender-select" className="mt-4">
								<SelectValue placeholder={"Select Gender"}/>
							</SelectTrigger>
							<SelectContent>
								<SelectItem value="M">Him</SelectItem>
								<SelectItem value="F">Her</SelectItem>
							</SelectContent>
						</Select>
					</div>
					<div className="w-full flex flex-col">
						<Label htmlFor="nationality-select">
							AI Nationality:
						</Label>
						<Select onValueChange={setNationality} required>
							<SelectTrigger id="nationality-select" className="mt-4">
								<SelectValue placeholder={"Select Nationality"}/>
							</SelectTrigger>
							<SelectContent>
								{allNationalities.map(nationality => (
									<SelectItem value={nationality} key={nationality}>{nationality}</SelectItem>
								))}
							</SelectContent>
						</Select>
					</div>
				</div>
				{/* ------------------ VOICE LIST ------------------ */}
				<div className="w-full flex flex-col mt-6">
					<Label htmlFor="voice-model-select">
						Select Voice Type:
					</Label>
					<Select value={voiceID} onValueChange={changeVoice} required>
						<SelectTrigger id="voice-model-select" className="mt-4">
							<SelectValue placeholder={"Select Voice"}/>
						</SelectTrigger>
						<SelectContent>
							{filteredVoices.length === 0 && <p className="text-center text-sm py-4">No Voice Available.</p>}
							{filteredVoices.map(voice => (
								<SelectItem value={voice.voiceId} key={voice.voiceId}>
									{voice.voiceName}
								</SelectItem>
							))}
						</SelectContent>
					</Select>
					{voiceSampleURL && <audio className="mt-4"
                                              controls
                                              src={voiceSampleURL}></audio>}
				</div>
				{/* ------------------ USER, AI & CALLEE NAME ------------------ */}
				<div className={"w-full grid grid-cols-2 mt-6 gap-6"}>
					{/* AI */}
					<div className="w-full flex flex-col">
						<Label htmlFor="ai-name">
							AI Name:
						</Label>
						<Input type="text"
							   id="ai-name"
							   className="mt-4"
							   value={aiCallName}
							   onChange={e => setAiCallName(e.target.value)}
							   placeholder="ex. John"
							   required/>
					</div>
					{/* USER */}
					<div className="w-full flex flex-col">
						<Label htmlFor="user-name">
							Your Name:
						</Label>
						<Input type="text"
							   id="user-name"
							   className="mt-4"
							   value={userCallName}
							   onChange={e => setUserCallName(e.target.value)}
							   placeholder="ex. Jane"
							   required/>
					</div>
				</div>
				{/* ------------------ CALL INSTRUCTIONS ------------------ */}
				<div className="w-full flex flex-col mt-6">
					<Label htmlFor="call-instructions">
						What should the AI do during the call?
					</Label>
					<Textarea id="call-instructions"
							  className="mt-4 border"
							  value={callInstructions}
							  onChange={e => setCallInstructions(e.target.value)}
							  placeholder="Ex. Ask if callee is available at 6 PM today for..."
							  rows={5}
							  required/>
				</div>
				{/* ------------------ POST CALL INSTRUCTIONS ------------------ */}
				<div className="w-full flex flex-col mt-6">
					<Label htmlFor="post-call-instructions" className="flex">
						<QuestionMarkTooltip text={agentInstructionTooltipText}/>
						&nbsp;&nbsp;What should the AI do after the call?
					</Label>
					<Textarea id="post-call-instructions"
							  className="mt-4"
							  value={postCallInstructions}
							  onChange={e => setPostCallInstructions(e.target.value)}
							  placeholder="Ex. Generate a summary of the conversation."
							  rows={5}
							  required/>
				</div>

				{/*<hr className="w-full my-6"/>*/}

				{/*/!* ------------------ CALL TIMEZONE ------------------ *!/*/}
				{/*<div className="w-full flex flex-col">*/}
				{/*	<Label htmlFor="timezone-select">*/}
				{/*		Select timezone for call timings:*/}
				{/*	</Label>*/}
				{/*	<Select value={callTimezone} onValueChange={(value) => {*/}
				{/*		setCallTimezone(parseTimezone(value).value);*/}
				{/*	}} required>*/}
				{/*		<SelectTrigger id="timezone-select" className="mt-4">*/}
				{/*			<SelectValue placeholder={"Select Timezone"}/>*/}
				{/*		</SelectTrigger>*/}
				{/*		<SelectContent>*/}
				{/*			{options.map((option) => (*/}
				{/*				<SelectItem value={option.value} key={option.label}>*/}
				{/*					{option.label}*/}
				{/*				</SelectItem>*/}
				{/*			))}*/}
				{/*		</SelectContent>*/}
				{/*	</Select>*/}
				{/*</div>*/}
				{/*/!* ------------------ CALL TIMIMG ------------------ *!/*/}
				{/*<p className="mt-6">*/}
				{/*	Set the calling hours. Calls will only be made during these hours.*/}
				{/*	Any remaining calls will be made the next day during same hours.*/}
				{/*</p>*/}
				{/*<div className="w-full grid grid-cols-2 gap-6">*/}
				{/*	<div className="w-full flex flex-col mt-6 items-center">*/}
				{/*		<Label htmlFor="call-start-time" className="font-bold">*/}
				{/*			Start Time (24-hrs format):*/}
				{/*		</Label>*/}
				{/*		<TimePicker time={callFromTime} setTime={startTimeSelectionHandler} id="call-start-time"*/}
				{/*					classname="mt-4"/>*/}
				{/*	</div>*/}
				{/*	<div className="w-full flex flex-col mt-6 items-center">*/}
				{/*		<Label htmlFor="call-end-time" className="font-bold">*/}
				{/*			End Time (24-hrs format):*/}
				{/*		</Label>*/}
				{/*		<TimePicker time={callToTime} setTime={endTimeSelectionHandler} id="call-end-time"*/}
				{/*					classname="mt-4"/>*/}
				{/*	</div>*/}
				{/*</div>*/}

				<Button className="mt-8" onClick={createBulkCall}>
					<Plus className="w-4 h-4 mr-2"/>Create Bulk Call
				</Button>
			</div>
			<SelectColumnsDialog open={openSelectColumnsDialog}
								 setOpen={setOpenSelectColumnsDialog}
								 colNames={colNames}
								 rows={rows}
								 setContactList={setContactList} callToast={callToast}/>
		</div>
	)
}

function QuestionMarkTooltip(props: { text: string }) {
	return (
		<TooltipProvider>
			<Tooltip>
				<TooltipTrigger asChild>
					<CircleHelp className="h-4 w-4 cursor-pointer text-muted-foreground"/>
				</TooltipTrigger>
				<TooltipContent className={"w-72"}>
					<p>{props.text}</p>
				</TooltipContent>
			</Tooltip>
		</TooltipProvider>
	)
}

function SelectColumnsDialog(props: {
	open: boolean,
	setOpen: (value: boolean) => void,
	rows: Array<Array<string>>,
	colNames: Array<string>,
	setContactList: (value: Array<Array<string>>) => void,
	callToast: (title: string, text: string, variant: "default" | "destructive") => void,
}) {
	const [
		nameColIndex,
		setNameColIndex
	] = useState<number | null>(null);
	const [
		phoneNumberColIndex,
		setPhoneNumberColIndex
	] = useState<number | null>(null);

	/**
	 * Updates contactList state and closes the Dialog.
	 */
	function confirmButtonHandler() {
		if (nameColIndex === null || phoneNumberColIndex === null) {
			props.callToast(
				"Columns Not Set",
				"Please select the name and phone number columns.",
				"destructive"
			)

		} else if (nameColIndex === phoneNumberColIndex) {
			props.callToast(
				"Columns Not Set",
				"Name and Phone Number columns cannot be the same.",
				"destructive"
			)

		} else {
			// We need to add name and number from their respective indexes in the rows data.
			let contacts: Array<Array<string>> = []
			props.rows.forEach(row => {
				contacts.push([row[nameColIndex], row[phoneNumberColIndex]])
			});
			console.log(contacts);
			props.setContactList(contacts);

			// Show a simple acknowledgement message.
			props.callToast(
				"CSV File Ready",
				"Name and Phone Number columns have been set. CSV file ready for upload!",
				"default"
			)

			// Close the dialog.
			props.setOpen(false);
		}
	}

	return (
		<Dialog open={props.open} onOpenChange={props.setOpen}>
			<DialogContent hideCloseButton={true} className="sm:max-w-[425px]">
				<DialogHeader>
					<DialogTitle>Select Columns</DialogTitle>
					<DialogDescription>
						Select the correct <b>Name</b> and <b>Phone Number</b> columns in your uploaded CSV file.
					</DialogDescription>
				</DialogHeader>
				<div className="grid gap-4 py-4">
					{/* fetch name column index */}
					<div className="w-full flex flex-col">
						<Label htmlFor="name-col-index">
							Names Column:
						</Label>
						<Select onValueChange={value => setNameColIndex(parseInt(value))} required>
							<SelectTrigger id="name-col-index" className="mt-4">
								<SelectValue placeholder={"Select Name Column"}/>
							</SelectTrigger>
							<SelectContent>
								{props.colNames.map((name, index) => (
									<SelectItem value={index.toString()} key={index}>
										{name}
									</SelectItem>
								))}
							</SelectContent>
						</Select>
					</div>
					{/* fetch phone number column index */}
					<div className="w-full flex flex-col mt-6">
						<Label htmlFor="phone-numbers-col-index">
							Phone Numbers Column:
						</Label>
						<Select onValueChange={
							value => setPhoneNumberColIndex(parseInt(value))
						} required>
							<SelectTrigger id="phone-numbers-col-index" className="mt-4">
								<SelectValue placeholder={"Select Number Column"}/>
							</SelectTrigger>
							<SelectContent>
								{props.colNames.map((name, index) => (
									<SelectItem value={index.toString()} key={index}>
										{name}
									</SelectItem>
								))}
							</SelectContent>
						</Select>
					</div>
				</div>
				<DialogFooter>
					<div className="flex flex-row justify-end">
						<Button onClick={confirmButtonHandler} className="ml-4">Continue</Button>
					</div>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	)
}
