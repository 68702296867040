import {Label} from "@components/ui/label";
import {Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle} from "@components/ui/card";
import {CheckIcon} from "lucide-react";
import {Button} from "@components/ui/button";
import {Badge} from "@components/ui/badge";
import {Switch} from "@components/ui/switch";
import {useEffect, useState} from "react";
import {useMutation, useQuery} from "@tanstack/react-query";
import {ApiRequestFailed, authenticateAndFetchData, authenticateAndPostData, retryFn} from "@lib/apis";
import PageLoading from "@components/ui/pageloading";
import PageDataErrorHandler from "@components/data/pageDataErrorHandler";
import {useOutletContext} from "react-router-dom";
import {BaseOutletContext} from "@pages/Base";
import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
	AlertDialogTrigger
} from "@components/ui/alert-dialog";

interface PageData {
	status_code: number
	status_text: string
	plans: {
		"monthly": Array<Plan>,
		"annual": Array<Plan>,
		free_plan: Plan,
	}
	current_plan_uid: string
	current_plan_type: "monthly" | "annual"
	subscription_id: string | null
}

interface Plan {
	display_name: string
	backend_uid: string
	description: string
	features: string
	renewal_type: string
	product_id: string
	variant_id: string
	popular_plan: boolean
	price: string
}

interface CheckoutResponse {
	status_code: number
	status_text: string
	checkout_url: string
}

export default function Subscription() {
	const {showToast} = useOutletContext<BaseOutletContext>();

	const [pageData, setPageData] = useState<PageData>();
	const [
		annualRenewal,
		setAnnualRenewal
	] = useState<boolean>(false);

	// Fetch the page data.
	const pageDataQuery = useQuery({
		queryKey: ["subscriptionPageData"],
		queryFn: () => authenticateAndFetchData("/api/get-subscription-plans/"),
		gcTime: 0,
		refetchOnWindowFocus: false,
		retry: retryFn,
	})
	useEffect(() => {
		if (pageDataQuery.data) {
			let data = pageDataQuery.data.data as PageData
			setPageData(data);
			setAnnualRenewal(data.current_plan_type === "annual");
		}
	}, [pageDataQuery.data]);

	// Mutation for checkout.
	const checkoutMutation = useMutation({
		mutationKey: ["checkoutMutation"],
		mutationFn: (variantID: string) => authenticateAndPostData("/api/checkout/", {
			"variant_id": variantID,
		}),
		gcTime: 0,
		retry: retryFn,
		onSuccess: (response) => {
			// Send user to checkout page.
			const data = response.data as CheckoutResponse;
			window.location.href = data.checkout_url;
		},
		onError: (error: ApiRequestFailed) => {
			console.error(error);
			showToast(
				"Error",
				error.data.message,
				"destructive"
			);
		}
	});

	// Mutation for change plan.
	const changePlanMutation = useMutation({
		mutationKey: ["changePlanMutation"],
		mutationFn: (
			data: { subscription_id: string, variantID: string }
		) => authenticateAndPostData("/api/change-plan/", {
			"subscription_id": data.subscription_id,
			"variant_id": data.variantID,
		}),
		gcTime: 0,
		retry: retryFn,
		onSuccess: () => {
			showToast(
				"Success!",
				"Your subscription plan has been changed successfully. Please refresh this page. " +
				"It might take a few seconds for changes to register.",
				"default",
			);
		},
		onError: (error: ApiRequestFailed) => {
			console.error(error);
			showToast(
				"Error",
				error.data.message,
				"destructive"
			);
		}
	});

	// Mutation for customer portal link.
	const customerPortalMutation = useMutation({
		mutationKey: ["customerPortalMutation"],
		mutationFn: () => authenticateAndPostData("/api/customer-portal-link/", {}),
		gcTime: 0,
		retry: retryFn,
		onSuccess: (response) => {
			let data = response.data;
			window.location.href = data.link;
		},
		onError: (error: ApiRequestFailed) => {
			console.error(error);
			showToast(
				"Error",
				error.data.message,
				"destructive"
			);
		}
	});

	function purchasePlan(variantID: string) {
		checkoutMutation.mutate(variantID);
	}

	function changePlan(subscriptionID: string, variantID: string) {
		changePlanMutation.mutate({subscription_id: subscriptionID, variantID: variantID});
	}

	// ========================================================================
	// --------------------------- MAIN RENDER CODE ---------------------------
	// ========================================================================

	if (pageDataQuery.isLoading) {
		return (
			<PageLoading/>
		)

	} else if (pageDataQuery.error as unknown as ApiRequestFailed) {
		return <PageDataErrorHandler error={pageDataQuery.error as unknown as ApiRequestFailed}/>

	} else if (pageData) {
		return (
			<div className="w-full flex flex-col items-center justify-center">
				<div className="container mt-10">
					{/* Title */}
					<div className="max-w-2xl mx-auto text-center mb-10 lg:mb-14">
						<h2 className="scroll-m-20 border-b pb-2 text-3xl font-semibold tracking-tight transition-colors first:mt-0">
							Pricing
						</h2>
						<p className="mt-1 text-muted-foreground">
							Whatever your status, our offers evolve according to your needs.
						</p>
					</div>
					{/* End Title */}

					{/* Switch */}
					<div className="flex justify-center items-center">
						<Label htmlFor="payment-schedule" className="me-3">
							Monthly
						</Label>
						<Switch id="payment-schedule"
								checked={annualRenewal}
								onCheckedChange={setAnnualRenewal}/>
						<Label htmlFor="payment-schedule" className="relative ms-3">
							Annual
							<span className="absolute -top-10 start-auto -end-28">
                            <span className="flex items-center">
                            <svg
								className="w-14 h-8 -me-6"
								width={45}
								height={25}
								viewBox="0 0 45 25"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
                              <path
								  d="M43.2951 3.47877C43.8357 3.59191 44.3656 3.24541 44.4788 2.70484C44.5919 2.16427 44.2454 1.63433 43.7049 1.52119L43.2951 3.47877ZM4.63031 24.4936C4.90293 24.9739 5.51329 25.1423 5.99361 24.8697L13.8208 20.4272C14.3011 20.1546 14.4695 19.5443 14.1969 19.0639C13.9242 18.5836 13.3139 18.4152 12.8336 18.6879L5.87608 22.6367L1.92723 15.6792C1.65462 15.1989 1.04426 15.0305 0.563943 15.3031C0.0836291 15.5757 -0.0847477 16.1861 0.187863 16.6664L4.63031 24.4936ZM43.7049 1.52119C32.7389 -0.77401 23.9595 0.99522 17.3905 5.28788C10.8356 9.57127 6.58742 16.2977 4.53601 23.7341L6.46399 24.2659C8.41258 17.2023 12.4144 10.9287 18.4845 6.96211C24.5405 3.00476 32.7611 1.27399 43.2951 3.47877L43.7049 1.52119Z"
								  fill="currentColor"
								  className="text-muted-foreground"
							  />
                            </svg>
                            <Badge className="mt-3 uppercase">Save up to 10%</Badge>
                          </span>
                        </span>
						</Label>
					</div>
					{/* End Switch */}

					{/* Plan Cards Grid */}
					<div className="mt-12 grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-6 lg:items-center">
						{pageData.plans[annualRenewal ? "annual" : "monthly"].map(plan => {
							if (plan.popular_plan) {
								return (
									<MostPopularPlanCard
										key={plan.backend_uid}
										name={plan.display_name}
										price={parseFloat(plan.price).toString()}
										currencyCode={"$"}
										description={plan.description}
										featureList={plan.features.split("\n").filter(
											value => value !== ""
										)}
										isCurrentPlan={plan.backend_uid === pageData.current_plan_uid}
										subscriptionID={pageData.subscription_id || ""}
										purchase={() => purchasePlan(plan.variant_id)}
										change={() => changePlan(pageData.subscription_id || "", plan.variant_id)}/>
								)
							} else {
								return (
									<PlanCard
										key={plan.backend_uid}
										name={plan.display_name}
										price={parseFloat(plan.price).toString()}
										currencyCode={"$"}
										description={plan.description}
										featureList={plan.features.split("\n").filter(
											value => value !== ""
										)}
										isCurrentPlan={plan.backend_uid === pageData.current_plan_uid}
										subscriptionID={pageData.subscription_id || ""}
										purchase={() => purchasePlan(plan.variant_id)}
										change={() => changePlan(pageData.subscription_id || "", plan.variant_id)}/>
								)
							}
						})}
					</div>
					{/* End Plan Cards Grid */}
				</div>
				{pageData.current_plan_uid !== "free_plan" &&
                    <div className="w-full mt-16 flex flex-col items-center"
                         onClick={() => customerPortalMutation.mutate()}>
                        <Button>Manage Your Subscription</Button>
                    </div>}
			</div>
		);

	} else {
		// Ideally it should not reach here.
		return <></>
	}
}

function PlanCard(props: {
	name: string,
	price: string,
	currencyCode: string,
	description: string,
	featureList: string[],
	isCurrentPlan: boolean,
	subscriptionID: string,
	purchase: () => void,
	change: () => void,
}) {
	return (
		<Card>
			<CardHeader className="text-center pb-2">
				<CardTitle className="mb-7">{props.name}</CardTitle>
				<span className="font-bold text-5xl">{props.currencyCode}{props.price}</span>
			</CardHeader>
			<CardDescription className="text-center  w-11/12 mx-auto">
				{props.description}
			</CardDescription>
			<CardContent>
				<ul className="mt-7 space-y-2.5 text-sm">
					{props.featureList.map((value, index) => (
						<li className="flex space-x-2" key={index}>
							<CheckIcon className="flex-shrink-0 mt-0.5 h-4 w-4"/>
							<span className="text-muted-foreground">{value}</span>
						</li>
					))}
				</ul>
			</CardContent>
			<CardFooter>
				<BuyButton isCurrentPlan={props.isCurrentPlan} subscriptionID={props.subscriptionID}
						   purchase={props.purchase} change={props.change}/>
			</CardFooter>
		</Card>
	)
}

function MostPopularPlanCard(props: {
	name: string,
	price: string,
	currencyCode: string,
	description: string,
	featureList: string[],
	isCurrentPlan: boolean,
	subscriptionID: string,
	purchase: () => void,
	change: () => void,
}) {
	return (
		<Card className="border-primary">
			<CardHeader className="text-center pb-2">
				<Badge className="uppercase w-max self-center mb-3">
					Most popular
				</Badge>
				<CardTitle className="!mb-7">{props.name}</CardTitle>
				<span className="font-bold text-5xl">{props.currencyCode}{props.price}</span>
			</CardHeader>
			<CardDescription className="text-center w-11/12 mx-auto">
				{props.description}
			</CardDescription>
			<CardContent>
				<ul className="mt-7 space-y-2.5 text-sm">
					{props.featureList.map((value, index) => (
						<li className="flex space-x-2" key={index}>
							<CheckIcon className="flex-shrink-0 mt-0.5 h-4 w-4"/>
							<span className="text-muted-foreground">{value}</span>
						</li>
					))}
				</ul>
			</CardContent>
			<CardFooter>
				<BuyButton isCurrentPlan={props.isCurrentPlan} subscriptionID={props.subscriptionID}
						   purchase={props.purchase} change={props.change}/>
			</CardFooter>
		</Card>
	)
}

function BuyButton(props: {
	isCurrentPlan: boolean,
	subscriptionID: string,
	purchase: () => void,
	change: () => void,
}) {
	if (props.isCurrentPlan) {
		return (
			<Button className="w-full" variant={"secondary"} disabled>
				Current Plan
			</Button>
		);
	} else {
		if (!props.subscriptionID) {
			return (
				<Button className="w-full"
						variant={"outline"}
						onClick={props.purchase}>
					Purchase
				</Button>
			)
		} else {
			return (
				<AlertDialog>
					<AlertDialogTrigger asChild>
						<Button className="w-full"
								variant={"outline"}>
							Purchase
						</Button>
					</AlertDialogTrigger>
					<AlertDialogContent>
						<AlertDialogHeader>
							<AlertDialogTitle>Confirm moving to new plan?</AlertDialogTitle>
							<AlertDialogDescription>
								You will be charged for any price difference and your account will be shifted to the
								new plan immediately.
							</AlertDialogDescription>
						</AlertDialogHeader>
						<AlertDialogFooter>
							<AlertDialogCancel>
								Cancel
							</AlertDialogCancel>
							<AlertDialogAction onClick={props.change}>
								Confirm
							</AlertDialogAction>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialog>
			)
		}
	}
}
