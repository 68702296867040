import {Link, useParams} from "react-router-dom";
import {Button} from "@components/ui/button";
import {useEffect, useState} from "react";
import logo_white from "@images/branding/ai_call_center_logo_white.png";
import logo_black from "@images/branding/ai_call_center_logo_black.png";
import {getCurrentTheme} from "@lib/utils";
import {urls} from "@routes";
import {useMutation, useQuery} from "@tanstack/react-query";
import {ApiRequestFailed, authenticateAndFetchData, authenticateAndPostData, retryFn} from "@lib/apis";
import {LoaderCircle, Phone} from "lucide-react";
import PageLoading from "@components/ui/pageloading";
import PageDataErrorHandler from "@components/data/pageDataErrorHandler";

interface PageData {
	status_code: number
	status_text: string

	meeting_join_url: string | null
}

interface StartCallResponse {
	status_code: number
	status_text: string

	meeting_join_url: string
}


export default function StartDelegateMeetingCall() {
	const {callUID} = useParams();

	// const [callStarted, setCallStarted] = useState(false);
	// const [linkExpired, setLinkExpired] = useState<boolean>(false);
	// const [logoDarkMode, setLogoDarkMode] = useState<boolean>(
	// 	getCurrentTheme() === "dark"
	// );
	const [
		meetingJoinUrl,
		setMeetingJoinUrl
	] = useState<string | null>(null);

	// Query to fetch page data.
	const pageDataQuery = useQuery({
		queryKey: ["startDelegateMeetingCall"],
		queryFn: () => authenticateAndFetchData(`/api/start-call/delegate-meeting/${callUID}/`),
		gcTime: 0,
		retry: retryFn,
		refetchOnWindowFocus: true,
	});
	useEffect(() => {
		if (pageDataQuery.data) {
			let data = pageDataQuery.data.data as PageData
			setMeetingJoinUrl(data.meeting_join_url)
		}
	}, [pageDataQuery.data]);

	// Mutation to start call.
	const startCallMutation = useMutation({
		mutationKey: ["startCallMutation"],
		mutationFn: () => authenticateAndPostData(`/api/start-call/delegate-meeting/${callUID}/`, {}),
		gcTime: 0,
		retry: retryFn,
		onSuccess: (response) => {
			let data: StartCallResponse = response.data;
			setMeetingJoinUrl(data.meeting_join_url);
			window.location.href = data.meeting_join_url;
		},
		onError: (error: ApiRequestFailed) => {
			console.error(error);
		}
	});

	function startCall() {
		startCallMutation.mutate();
	}

	function switchMainComponent() {
		console.log(meetingJoinUrl)
		if (meetingJoinUrl) {
			// Show the meeting url page if meeting url is available.
			return <ShowMeetingLink meetingJoinUrl={meetingJoinUrl}/>
		} else {
			// Show start call page if call was not yet started.
			return <StartCall startCall={startCall} startCallMutationRunning={startCallMutation.isPending}/>
		}
	}

	// ========================================================================
	// --------------------------- MAIN RENDER CODE ---------------------------
	// ========================================================================

	if (pageDataQuery.isLoading) {
		return (
			<PageLoading/>
		)

	} else if (pageDataQuery.error as unknown as ApiRequestFailed) {
		return <PageDataErrorHandler error={pageDataQuery.error as unknown as ApiRequestFailed}/>

	} else {
		return (
			<div className={"w-full h-screen flex flex-col items-center justify-center"}>
				<div className={"w-4/12 border rounded-2xl flex flex-col justify-center items-center px-6 py-12"}>
					<Link to={urls["dashboard"]} className={"mb-6"}>
						<img src={getCurrentTheme() === "dark" ? logo_white : logo_black} alt="logo" width={"300px"}/>
					</Link>
					{switchMainComponent()}
				</div>
			</div>
		)
	}
}

function StartCall(props: {
	startCall: () => void,
	startCallMutationRunning: boolean,
}) {
	return (
		<>
			<h1 className={"font-helvetica-neue-bold text-2xl text-center"}>
				Join Meeting Call
			</h1>
			<p className={"mt-2 text-center"}>You have been invited to a call. Use the below button to join.</p>
			<Button className={"mt-6"} onClick={props.startCall}>
				{props.startCallMutationRunning ?
					<><LoaderCircle className={"w-4 h-4 mr-2 animate-spin"}/>Joining. Please Wait...</> :
					<><Phone className={"w-4 h-4 mr-2"}/>Start Meeting Call</>}
			</Button>
		</>
	);
}

function ShowMeetingLink(props: {
	meetingJoinUrl: string
}) {
	return (
		<>
			<h1 className={"font-helvetica-neue-bold text-2xl text-center"}>
				Heads Up! The meeting has started.
			</h1>
			<p className={"mt-2 text-center"}>
				In case you were not automatically redirected to the meeting page, please use the link below to manually
				navigate to the page.
			</p>
			<div className={"mt-6 px-6 py-3 border rounded-2xl text-center bg-primary"}>
				<a href={props.meetingJoinUrl} className={"underline"} target={"_blank"} rel={"noreferrer"}>
					{props.meetingJoinUrl}
				</a>
			</div>
		</>
	)
}

// function CallLinkExpired() {
// 	return (
// 		<>
// 			<h1 className={"font-helvetica-neue-bold text-2xl text-center text-destructive"}>
// 				Oops! Link Expired.
// 			</h1>
// 			<p className={"mt-2 text-center"}>
// 				This link has already been used to start a call. Please schedule a new call to obtain a different link.
// 			</p>
// 		</>
// 	)
// }
