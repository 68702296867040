import {Link, useNavigate, useParams} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import {ApiRequestFailed, authenticateAndFetchData, retryFn} from "@lib/apis";
import PageLoading from "@components/ui/pageloading";
import PageDataErrorHandler from "@components/data/pageDataErrorHandler";
import {useEffect, useState} from "react";
import {Button, buttonVariants} from "@components/ui/button";
import {ArrowLeft, ChevronLeft} from "lucide-react";
import {urls} from "@routes";
import {formattedDateTime} from "@lib/utils";

interface PageData {
	status_code: number
	status_text: string
	user_email: string

	caller_name: string
	caller_nationality: string | null
	call_lang: string
	call_type: string
	call_status: "ongoing" | "ended"
	call_duration: {
		minutes: number
		seconds: number
	},
	call_log: Array<CallTranscription>,
}

interface CallTranscription {
	role: string
	content: string
	timestamp?: number
}

export default function AdminUserConversationMeetingCallLog() {
	const {userID, callUID} = useParams();
	const navigate = useNavigate();

	const [pageData, setPageData] = useState<PageData>();

	// Fetch page data
	const pageDataQuery = useQuery({
		queryKey: ["callLogDetails", callUID],
		queryFn: () => authenticateAndFetchData(
			`/api/admin/get-meeting-conversation-call-details?user_id=${userID}&call_uid=${callUID}`
		),
		gcTime: 0,
		retry: retryFn,
		refetchOnWindowFocus: false,
	});
	useEffect(() => {
		if (pageDataQuery.data) {
			setPageData(pageDataQuery.data.data as PageData);
			console.log(pageDataQuery.data.data)
		}
	}, [pageDataQuery.data]);

	/**
	 * Returns call duration as a string in the following format:
	 * "MM min SS sec"
	 */
	function callDurationFormatter(minutes: number, seconds: number) {
		let minute_string = minutes < 10 ? `0${minutes} min` : `${minutes} min`;
		let seconds_string = seconds < 10 ? `0${seconds} sec` : `${seconds} sec`;
		return `${minute_string} ${seconds_string}`
	}

	function goBack() {
		navigate(urls["adminUserDetails"].replace(":userID", userID || ""));
	}

	// ========================================================================
	// --------------------------- MAIN RENDER CODE ---------------------------
	// ========================================================================

	if (pageDataQuery.isLoading) {
		return (
			<PageLoading/>
		)

	} else if (pageDataQuery.error as unknown as ApiRequestFailed) {
		return <PageDataErrorHandler error={pageDataQuery.error as unknown as ApiRequestFailed}/>

	} else if (pageData) {
		if (pageData.call_status === "ended") {
			return (
				<div className="w-full flex flex-col">
					{/* Header */}
					<div className="flex flex-row justify-start items-center">
						<Button variant="ghost" className="text-xl" onClick={goBack}>
							<ChevronLeft className="w-6 h-6 mr-2"/>Go Back
						</Button>
						<h1 className="font-helvetica-neue-bold text-xl ml-6">
							Call Log for <span className="text-primary">{callUID}</span>
						</h1>
					</div>
					{/* Main Content */}
					<div className="grid-rows-2 w-full mt-6">
						<div className="flex flex-row justify-start">
							<div className="border-2 border-muted-foreground/30 rounded-2xl p-4 w-64 mr-2">
								<p className="font-bold text-muted-foreground">Caller / AI Name:</p>
								<p className="mt-2">
									{pageData.caller_name} | {pageData.caller_nationality || "N/A"} | {pageData.call_lang}
								</p>
							</div>
							<div className="border-2 border-muted-foreground/30 rounded-2xl p-4 w-64 mx-2">
								<p className="font-bold text-muted-foreground">Call Type:</p>
								<p className="mt-2">{pageData.call_type}</p>
							</div>
							<div className="border-2 border-muted-foreground/30 rounded-2xl p-4 w-64 ml-2">
								<p className="font-bold text-muted-foreground">Call Duration:</p>
								<p className="mt-2">
									{callDurationFormatter(
										pageData.call_duration.minutes,
										pageData.call_duration.seconds
									)}
								</p>
							</div>
						</div>
						<hr className="my-12 border-muted-foreground/30"/>
						{/* ------------------ CALL TRANSCRIPTION ------------------*/}
						<div className="w-full mt-6">
							<h1 className="text-2xl font-bold">Call Transcription:</h1>
							<div className="w-full border-2 border-muted-foreground/30 rounded-2xl p-6 mt-4">
								{pageData.call_log ? pageData.call_log.map((transcription, index) => {
									if (transcription.role === "user") {
										return <TranscriptionUserRow key={`You_${index}`}
																								 name={"You"}
																								 text={transcription.content}
																								 timestamp={transcription.timestamp}/>
									} else {
										return <TranscriptionAiRow key={`${pageData.caller_name}_${index}`}
																							 name={pageData.caller_name}
																							 text={transcription.content}
																							 timestamp={transcription.timestamp}/>
									}
								}) : <p className="text-center">No Call Transcription Available</p>}
							</div>
						</div>
					</div>
				</div>
			);

		} else {
			return (
				<div className="flex flex-col justify-center items-center w-full mt-32">
					<h1 className="text-4xl font-extrabold">Still chatting away...</h1>
					<p className="mt-6 text-xl text-center">
						Your call details will be available once the call has ended.
					</p>
					<Link to={urls["adminUserCallLogs"].replace(":userID", userID || "")}
								className={buttonVariants({
									variant: "default",
									className: "mt-12 !font-extrabold"
								})}>
						<ArrowLeft/>&nbsp;Let's Go Back
					</Link>
				</div>
			)
		}

	} else {
		// Ideally it should not reach here.
		return <></>
	}
}

function TranscriptionUserRow(props: { name: string, text: string, timestamp?: number }) {
	return (
		<div className="flex flex-col rounded bg-muted/35 p-4">
			<p className="text-lg font-bold text-muted-foreground">
				{props.name}: <Timestamp timestamp={props.timestamp}/>
			</p>
			<p>{props.text}</p>
		</div>
	)
}

function TranscriptionAiRow(props: { name: string, text: string, timestamp?: number }) {
	return <div className="flex flex-col rounded p-4">
		<p className="text-lg font-bold text-muted-foreground">
			{props.name}: <Timestamp timestamp={props.timestamp}/>
		</p>
		<p>{props.text}</p>
	</div>;
}

function Timestamp(props: { timestamp: number | undefined }) {
	if (props.timestamp) {
		return (
			<span className="mt-2 text-sm text-muted-foreground font-normal">
                {formattedDateTime(new Date(props.timestamp * 1000))}
            </span>
		)
	} else {
		return <></>
	}
}
