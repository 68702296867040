import React from 'react';
import ReactDOM from 'react-dom/client';
import './globals.css';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import routes from "./router";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
// @ts-ignore
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import {getCurrentTheme} from "@lib/utils";

// Set up ReactRouter and TanStack Query
const router = createBrowserRouter(routes);
const queryClient = new QueryClient()

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <TawkMessengerReact propertyId="6666b14f981b6c56477b78c2" widgetId="1i00i97v6"/>
            <RouterProvider router={router}/>
        </QueryClientProvider>
    </React.StrictMode>
);

console.log(getCurrentTheme());

// Set UI Theme.
let preferredTheme = getCurrentTheme();
if (preferredTheme === "dark") {
    document.getElementsByTagName("html")[0].classList.remove("light");
    document.getElementsByTagName("html")[0].classList.add("dark");
} else {
    document.getElementsByTagName("html")[0].classList.remove("dark");
    document.getElementsByTagName("html")[0].classList.add("light");
}

// Check for dark mode preference.
// If no value was set, we'll set it to system theme. Otherwise we'll use whatever was set.
// let darkMode = localStorage.getItem("aicaller_theme");
// if (darkMode === null) {
//     localStorage.setItem("aicaller_theme", "system");
//     if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
//         // set dark mode
//     } else {
//         // set light mode
//     }
//     document.getElementsByTagName("html")[0].classList.add("dark");
// } else {
//     if (darkMode === "true") {
//         document.getElementsByTagName("html")[0].classList.add("dark");
//     } else {
//         document.getElementsByTagName("html")[0].classList.remove("dark");
//     }
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
