import logo_white from "@images/branding/ai_call_center_logo_white.png";
import logo_black from "@images/branding/ai_call_center_logo_black.png";
import {ArrowLeft, AudioLines, CircleDollarSign, LogOut, SunMoon, Tv2, Users} from "lucide-react";
import {Link, NavLink, Outlet} from "react-router-dom";
import {urls} from "@routes";
import {useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {ApiRequestFailed, authenticateAndFetchData, retryFn} from "@lib/apis";
import PageLoading from "@components/ui/pageloading";
import {Card, CardContent} from "@components/ui/card";
import {buttonVariants} from "@components/ui/button";
import {DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger} from "@components/ui/dropdown-menu";
import {changeTheme, cn, getCurrentTheme} from "@lib/utils";
import {Toaster} from "@components/ui/toaster";
import {toast} from "@components/ui/use-toast";

interface PageData {
	status_code: number
	status_text: string
	message: string
}

export interface AdminBaseOutletContext {
	showToast: (title: string, text: string, variant: "default" | "destructive") => void
}

export default function AdminBase() {
	const [logoDarkMode, setLogoDarkMode] = useState<boolean>(
		getCurrentTheme() === "dark"
	);
	const [pageData, setPageData] = useState<PageData>();

	// Authenticate user as Admin.
	const adminAuthQuery = useQuery({
		queryKey: ["adminDashboard"],
		queryFn: () => authenticateAndFetchData("/api/admin/dashboard/"),
		gcTime: 0,
		retry: retryFn
	});
	useEffect(() => {
		if (adminAuthQuery.data) {
			setPageData(adminAuthQuery.data.data as PageData);
		}
	}, [adminAuthQuery.data]);

	function showToast(title: string, text: string, variant: "default" | "destructive") {
		toast({
			title: title,
			description: text,
			variant: variant,
			className: cn(
				'top-0 right-0 flex fixed md:max-w-[420px] md:top-4 md:right-4'
			),
		});
	}

	// ========================================================================
	// --------------------------- MAIN RENDER CODE ---------------------------
	// ========================================================================

	if (adminAuthQuery.isLoading) {
		return (
			<PageLoading/>
		)

	} else if (adminAuthQuery.error as unknown as ApiRequestFailed) {
		let error = adminAuthQuery.error as ApiRequestFailed

		if (error.statusCode === 403) {
			// User is not admin.
			return (
				<div className="w-screen h-screen flex flex-col justify-center items-center">
					<Card className="w-4/12 pt-6">
						<CardContent>
							<div className="w-full flex flex-col justify-center items-center">
								<h1 className="font-helvetica-neue-bold text-2xl text-center">
									Access Denied - Administrator Only
								</h1>
								<p className="text-center mt-4">
									Sorry, this page is restricted to <b>administrators</b> only.
									You don't have the necessary permissions to access this page.
								</p>
								<Link to={urls["dashboard"]}
									  className={buttonVariants({variant: "default", className: "mt-8"})}>
									<ArrowLeft className="w-5 h-5"/>&nbsp;&nbsp;Go To Dashboard
								</Link>
							</div>
						</CardContent>
					</Card>
				</div>
			)

		} else {
			return <p>Error: {error.statusText}</p>
		}

	} else if (pageData) {
		return (
			<div className="grid min-h-screen w-full md:grid-cols-[220px_1fr] lg:grid-cols-[280px_1fr]">
				{/* ------------------ SIDEBAR ------------------ */}
				<div className="hidden bg-muted md:block">
					<div className="flex h-full max-h-screen flex-col gap-2 px-4 py-5">
						<div className="flex justify-center mt-4">
							<NavLink to={urls["dashboard"]}>
								<img src={logoDarkMode ? logo_white : logo_black} alt="logo" className="sidebar-logo"/>
								<h1 className="text-center font-helvetica-neue-bold text-2xl">Admin</h1>
							</NavLink>
						</div>
						<hr className="mt-5 mb-5 border-muted-foreground/30"/>
						<div className="flex-1 mt-5">
							<nav className="grid items-start px-2 text-lg font-medium lg:px-4">
								<NavLink to={urls["adminDashboard"]}
										 className={({isActive}) =>
											 isActive ?
												 "flex items-center gap-3 rounded-lg px-3 py-3 text-primary transition-all hover:text-primary" :
												 "flex items-center gap-3 rounded-lg px-3 py-3 text-muted-foreground transition-all hover:text-primary"}
								>
									<Tv2 className="h-4 w-4"/>
									Dashboard
								</NavLink>
								<NavLink to={urls["adminAllUsers"]}
										 className={({isActive}) =>
											 isActive ?
												 "flex items-center gap-3 rounded-lg px-3 py-3 text-primary transition-all hover:text-primary" :
												 "flex items-center gap-3 rounded-lg px-3 py-3 text-muted-foreground transition-all hover:text-primary"}
								>
									<Users className="h-4 w-4"/>
									All Users
								</NavLink>
								<NavLink to={urls["adminVoiceBuilder"]}
										 className={({isActive}) =>
											 isActive ?
												 "flex items-center gap-3 rounded-lg px-3 py-3 text-primary transition-all hover:text-primary" :
												 "flex items-center gap-3 rounded-lg px-3 py-3 text-muted-foreground transition-all hover:text-primary"}
								>
									<AudioLines className="h-4 w-4"/>
									Voice Builder
								</NavLink>
								<NavLink to={urls["adminSubscriptionPlans"]}
										 className={({isActive}) =>
											 isActive ?
												 "flex items-center gap-3 rounded-lg px-3 py-3 text-primary transition-all hover:text-primary" :
												 "flex items-center gap-3 rounded-lg px-3 py-3 text-muted-foreground transition-all hover:text-primary"}
								>
									<CircleDollarSign className="h-4 w-4"/>
									Subscription Plans
								</NavLink>
							</nav>
						</div>
					</div>
				</div>

				{/* ------------------ MAIN WORKSPACE ------------------  */}
				<div className="flex flex-col py-5 px-10 max-h-screen overflow-auto">
					{/* Menu at top of workspace */}
					<header className="flex flex-row-reverse items-center h-14 lg:h-[60px]">
						<Link to={urls["logout"]}
							  className="p-3 border ml-4 rounded-2xl hover:text-primary">
							<LogOut/>
						</Link>
						<DropdownMenu>
							<DropdownMenuTrigger asChild>
								<div className="p-3 border rounded-2xl hover:text-primary cursor-pointer">
									<SunMoon/>
								</div>
							</DropdownMenuTrigger>
							<DropdownMenuContent>
								<DropdownMenuItem onClick={() => {
									changeTheme("dark");
									setLogoDarkMode(true);
								}}>
									Dark
								</DropdownMenuItem>
								<DropdownMenuItem onClick={() => {
									changeTheme("light");
									setLogoDarkMode(false);
								}}>
									Light
								</DropdownMenuItem>
								<DropdownMenuItem onClick={() => {
									changeTheme("system");
									setLogoDarkMode(getCurrentTheme() === "dark");
								}}>
									System
								</DropdownMenuItem>
							</DropdownMenuContent>
						</DropdownMenu>
					</header>
					{/* Workspace */}
					<div className="flex mt-5">
						<Outlet context={{showToast} satisfies AdminBaseOutletContext}/>
						<Toaster/>
					</div>
				</div>
			</div>
		)

	} else {
		// Ideally it should not reach here.
		return <></>
	}
}
