import {Label} from "@components/ui/label";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "@components/ui/select";
import {useEffect, useState} from "react";
import {useMutation, useQuery} from "@tanstack/react-query";
import {ApiRequestFailed, authenticateAndFetchData, authenticateAndPostData, retryFn} from "@lib/apis";
import PageLoading from "@components/ui/pageloading";
import PageDataErrorHandler from "@components/data/pageDataErrorHandler";
import {Button} from "@components/ui/button";
import {Alert, AlertDescription, AlertTitle} from "@components/ui/alert";
import {Info, LoaderCircle, PhoneIncoming} from "lucide-react";
import {Input} from "@components/ui/input";
import {useNavigate, useOutletContext} from "react-router-dom";
import {urls} from "@routes";
import {Tabs, TabsContent, TabsList, TabsTrigger} from "@components/ui/tabs";
import {BaseOutletContext} from "@pages/Base";
import {Badge} from "@components/ui/badge";

interface PageData {
	status_code: number
	status_text: string

	user_email: string
	verified_numbers: Array<string>
	voice_details: Array<VoiceDetails>
}

interface VoiceDetails {
	source: string
	voice_id: string
	voice_name: string
	gender: "M" | "F"
	personalities: Array<string>
	nationality: string
	sample_audio_url: string | null
}

interface MeetingResponse {
	status_code: number
	status_text: string

	meeting_join_url: string
}

export default function Conversation() {
	const {showToast} = useOutletContext<BaseOutletContext>();

	const [pageData, setPageData] = useState<PageData>();
	const [callPlatform, setCallPlatform] = useState<string>("meeting_call");
	const [
		allNationalities,
		setAllNationalities
	] = useState<Array<string>>([]);
	const [
		filteredVoices,
		setFilteredVoices
	] = useState<Array<{ voiceId: string, voiceName: string }>>([]);
	const [mobileNumber, setMobileNumber] = useState<string>("");
	const [gender, setGender] = useState<string>("");
	const [nationality, setNationality] = useState<string>("");
	const [voice, setVoice] = useState<string>("");  // This is voice id
	const [voiceSampleURL, setVoiceSampleURL] = useState<string | null>(null);
	const [aiCallName, setAiCallName] = useState<string>("");
	const [userCallName, setUserCallName] = useState<string>("");
	const [language, setLanguage] = useState<string>("");

	const [callUnderway, setCallUnderway] = useState<boolean>(false);
	const [meetingLink, setMeetingLink] = useState<string | undefined>(undefined);

	const navigate = useNavigate();

	// Fetch page data
	const pageDataQuery = useQuery({
		queryKey: ["conversation"],
		queryFn: () => authenticateAndFetchData("/api/conversation/"),
		gcTime: 0,
		refetchOnWindowFocus: false,
		retry: retryFn,
	});
	useEffect(() => {
		if (pageDataQuery.data) {
			let data = pageDataQuery.data.data as PageData
			if (data.status_code === 310) {
				// Quota Exceeded. Redirect to correct page.
				navigate(urls["quotaExhausted"], {replace: true});
			} else {
				setPageData(data);
			}
		}
	}, [pageDataQuery.data, navigate]);

	// This will fetch all distinct nationalities from the voice data.
	useEffect(() => {
		if (pageData) {
			let nationalities: Array<string> = []
			pageData.voice_details.forEach(voice => {
				if (!nationalities.includes(voice.nationality)) {
					nationalities.push(voice.nationality);
				}
			});
			setAllNationalities(nationalities);
		}
	}, [pageData]);

	// This will unlock and populate additional details section.
	useEffect(() => {
		if (pageData) {
			setVoice("");
			setFilteredVoices(pageData.voice_details.filter(voice => {
				if (callPlatform === "meeting_call") {
					return (voice.gender === gender) && (voice.nationality === nationality) && (voice.source === "cartesia");
				} else {
					return (voice.gender === gender) && (voice.nationality === nationality) && (voice.source !== "cartesia");
				}
			}).map(voice => {
				return {voiceId: voice.voice_id, voiceName: voice.voice_name}
			}));
		}
	}, [mobileNumber, gender, nationality, callPlatform, pageData]);

	// API call to start conversation.
	const makeCallMutation = useMutation({
		mutationKey: ["makeCall", mobileNumber],
		mutationFn: () => authenticateAndPostData("/api/conversation/", {
			"mobile_number": mobileNumber,
			"voice_id": voice,
			"user_call_name": userCallName,
			"ai_call_name": aiCallName,
			"call_language": language,
		}),
		gcTime: 0,
		retry: retryFn,
		onSuccess: (response) => {
			// Switch to "ongoing call" stage.
			console.log(response);
			setCallUnderway(true);
		},
		onError: (error: ApiRequestFailed) => {
			console.error(error);
			showToast(
				"Error",
				error.data.message,
				"destructive",
			);
		}
	});

	// API Call to start Meeting conversation call.
	const makeMeetingCallMutation = useMutation({
		mutationKey: ["makeMeetingConversationCall"],
		mutationFn: () => authenticateAndPostData("/api/meeting-conversation/", {
			"voice_id": voice,
			"ai_call_name": aiCallName,
			"call_language": language,
		}),
		gcTime: 0,
		retry: retryFn,
		onSuccess: (response) => {
			let responseData = response.data as MeetingResponse;
			console.log(response);

			// Redirect user to meeting url in a new tab.
			setCallUnderway(true);
			setMeetingLink(responseData.meeting_join_url);
			window.open(responseData.meeting_join_url, "_blank");
		},
		onError: (error: ApiRequestFailed) => {
			console.error(error);
			showToast(
				"Error",
				error.data.message,
				"destructive",
			);
		},
	});

	/**
	 * Makes API request with entered details to start the AI conversation.
	 */
	function makeCall() {
		if (callPlatform === "phone_call") {
			// ---------- Twilio Phone Calls ----------
			if (mobileNumber && voice && userCallName && aiCallName && language) {
				makeCallMutation.mutate();

			} else {
				showToast(
					"Missing Values",
					"Please check and fill in all the fields.",
					"destructive"
				);
			}

		} else {
			// ---------- Meeting Calls ----------
			if (voice && aiCallName && language) {
				makeMeetingCallMutation.mutate();

			} else {
				showToast(
					"Missing Values",
					"Please check and fill in all the fields.",
					"destructive"
				);
			}
		}
	}

	function changeVoice(voiceId: string) {
		if (pageData) {
			let voice: VoiceDetails = pageData.voice_details.filter(value => {
				return value.voice_id === voiceId
			})[0];

			setVoice(voiceId);
			setVoiceSampleURL(voice.sample_audio_url);
		}
	}

	// ========================================================================
	// --------------------------- MAIN RENDER CODE ---------------------------
	// ========================================================================

	if (pageDataQuery.isLoading) {
		return (
			<PageLoading/>
		)

	} else if (pageDataQuery.error as unknown as ApiRequestFailed) {
		return <PageDataErrorHandler error={pageDataQuery.error as unknown as ApiRequestFailed}/>

	} else if (pageData) {
		return (
			<div className="flex flex-col w-full items-center mt-6">
				<h1 className="text-4xl text-center font-helvetica-neue-bold">Have The AI Call You</h1>
				<p className="text-xl mt-4 text-center">
					Discuss life, work, movies, games - speak your mind & relax!
				</p>
				{/* Call platform selection */}
				<div
					className="w-full md:w-10/12 xl:w-6/12 flex flex-col items-center mt-10 px-6 py-10 border rounded-2xl transition-all">
					<h1 className="font-helvetica-neue-bold text-2xl text-center">
						How do you want to proceed?
					</h1>
					<Tabs value={callPlatform} onValueChange={setCallPlatform} className="w-full mt-6">
						<TabsList className="grid w-full grid-cols-2">
							<TabsTrigger value="meeting_call">Meeting Call</TabsTrigger>
							<TabsTrigger value="phone_call">Phone Call</TabsTrigger>
						</TabsList>
						<TabsContent value="phone_call" className="mt-6">
							<p>Have the AI call you on your verified phone number.</p>
							{/* ------------------ NUMBER SELECTION ------------------ */}
							<div className="w-full flex flex-col mt-2">
								<Label htmlFor="mobile-number-select">
									Choose your verified number:
								</Label>
								<Select onValueChange={setMobileNumber}>
									<SelectTrigger id="mobile-number-select"
																 className="mt-4 border">
										<SelectValue placeholder="Your Number"/>
									</SelectTrigger>
									<SelectContent>
										{pageData.verified_numbers.map(num => (
											<SelectItem value={num} key={num}>{num}</SelectItem>
										))}
									</SelectContent>
								</Select>
								{pageData.verified_numbers.length === 0 && <Badge variant={"destructive"} className={"mt-4"}>
                    <Info className={"w-4 h-4 mr-2"}/>
                    Your Number is not Verified.
                    To proceed, please visit&nbsp;
                    <a className={"underline"} href={urls["phoneNumber"] + "?tab=verify_number"}>
                        Phone Number
                    </a>&nbsp;
                    Page to verify your number.
                </Badge>}
							</div>
						</TabsContent>
						<TabsContent value="meeting_call" className="mt-6">
							<p>
								Talk with the AI using on a Zoom / Google Meet like video call interface. You will be
								provided with a URL to join the call.
							</p>
						</TabsContent>
					</Tabs>
				</div>
				{/* Call Data Form */}
				<div
					className="w-full md:w-10/12 xl:w-6/12 flex flex-col items-center mt-6 px-6 py-10 border rounded-2xl transition-all">
					{/* ------------------ GENDER & NATIONALITY SELECTION ------------------ */}
					<div className={"w-full grid grid-cols-2 gap-6"}>
						<div className="w-full flex flex-col">
							<Label htmlFor="gender-select">
								Talk with:
							</Label>
							<Select onValueChange={setGender}>
								<SelectTrigger id="gender-select" className="mt-4 border">
									<SelectValue placeholder={"Select Gender"}/>
								</SelectTrigger>
								<SelectContent>
									<SelectItem value="M">Him</SelectItem>
									<SelectItem value="F">Her</SelectItem>
								</SelectContent>
							</Select>
						</div>
						<div className="w-full flex flex-col">
							<Label htmlFor="nationality-select">
								AI Nationality:
							</Label>
							<Select onValueChange={setNationality}>
								<SelectTrigger id="nationality-select" className="mt-4 border">
									<SelectValue placeholder={"Select Nationality"}/>
								</SelectTrigger>
								<SelectContent>
									{allNationalities.map(nationality => (
										<SelectItem value={nationality} key={nationality}>{nationality}</SelectItem>
									))}
								</SelectContent>
							</Select>
						</div>
					</div>

					<section className="w-full">
						{/* ------------------ VOICE LIST ------------------ */}
						<div className="w-full flex flex-col mt-6">
							<Label htmlFor="voice-model-select">
								Select Voice Type:
							</Label>
							<Select value={voice} onValueChange={changeVoice}>
								<SelectTrigger id="voice-model-select" className="mt-4 border">
									<SelectValue placeholder={"Select Voice"}/>
								</SelectTrigger>
								<SelectContent>
									{filteredVoices.length === 0 && <p className="text-center text-sm py-4">No Voice Available.</p>}
									{filteredVoices.map(voice => (
										<SelectItem value={voice.voiceId} key={voice.voiceId}>
											{voice.voiceName}
										</SelectItem>
									))}
								</SelectContent>
							</Select>
							{voiceSampleURL && <audio className="mt-4"
                                        controls
                                        src={voiceSampleURL}></audio>}

						</div>
						{/* ------------------ USER & AI NAME ------------------ */}
						<div className={"w-full grid grid-cols-2 mt-6 gap-6"}>
							{/* AI */}
							<div
								className={callPlatform === "phone_call" ? "w-full flex flex-col" : "w-full flex flex-col col-span-2"}>
								<Label htmlFor="ai-name">
									AI Name:
								</Label>
								<Input type="text"
											 id="ai-name"
											 className="mt-4 border"
											 value={aiCallName}
											 onChange={e => setAiCallName(e.target.value)}
											 placeholder="ex. John"/>
							</div>
							{/* USER */}
							{callPlatform === "phone_call" && <div className="w-full flex flex-col">
                  <Label htmlFor="ai-name">
                      Your Name:
                  </Label>
                  <Input type="text"
                         id="ai-name"
                         className="mt-4 border"
                         value={userCallName}
                         onChange={e => setUserCallName(e.target.value)}
                         placeholder="ex. Jane"/>
              </div>}
						</div>

						{/* ------------------ CALL LANGUAGE ------------------ */}
						<div className="w-full flex flex-col mt-6">
							<Label htmlFor="language-select">
								Language to use during call:
							</Label>
							<Select onValueChange={setLanguage}>
								<SelectTrigger id="language-select" className="mt-4 border">
									<SelectValue placeholder={"Select Language"}/>
								</SelectTrigger>
								<SelectContent>
									<SelectItem value="English">English</SelectItem>
								</SelectContent>
							</Select>
						</div>
					</section>
				</div>
				<Button className="mt-10 px-8"
								onClick={makeCall}
								disabled={makeCallMutation.isPending || makeMeetingCallMutation.isPending}>
					{(makeMeetingCallMutation.isPending || makeMeetingCallMutation.isPending) ?
						<><LoaderCircle className="w-4 h-4 mr-2 animate-spin"/>Starting up...</> :
						<><PhoneIncoming className="w-4 h-4 mr-2"/>Start the Call</>}
				</Button>
				{callUnderway && <CallUnderwayAlert aiCallName={aiCallName}
                                            userCallName={userCallName}
                                            meetingLink={meetingLink}/>}

			</div>
		)

	} else {
		// Ideally it should not reach here.
		return <></>
	}
}

function CallUnderwayAlert(props: { aiCallName: string, userCallName: string, meetingLink: string | undefined }) {
	return (
		<Alert className={"w-1/2 fixed bottom-10"}>
			{/*<Terminal className="h-4 w-4"/>*/}
			<PhoneIncoming className={"h-4 w-4"}/>
			<AlertTitle>Heads up {props.userCallName}!</AlertTitle>
			<AlertDescription>
				Your call with <b>{props.aiCallName}</b> is underway.
				If you are having any trouble with receiving the call please let us know.
				{props.meetingLink && <><br/><br/>
            If you don't get redirected to the call automatically, please use this
            link <a className="text-primary underline" href={props.meetingLink}>{props.meetingLink}</a>
        </>}
			</AlertDescription>
		</Alert>
	)
}

// function NoVerifiedNumber() {
// 	return (
// 		<div className={"w-full flex flex-col justify-center items-center mt-24"}>
// 			<h1 className={"text-2xl text-center"}>
// 				No verified numbers available for your account.
// 			</h1>
// 			<p className="mt-2">Please verify your phone number to proceed.</p>
// 			<Link to={urls["phoneNumber"] + "?tab=verify_number"}
// 						className={buttonVariants({variant: "default", className: "mt-4"})}>
// 				Verify Number<ArrowRight className={"w-4 h-4 ml-2"}/>
// 			</Link>
// 		</div>
// 	)
// }
