import {useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {ApiRequestFailed, authenticateAndFetchData, retryFn} from "@lib/apis";
import PageLoading from "@components/ui/pageloading";
import PageDataErrorHandler from "@components/data/pageDataErrorHandler";
import {ColumnDef} from "@tanstack/react-table";
import {formattedDateTime} from "@lib/utils";
import {Badge} from "@components/ui/badge";
import {buttonVariants} from "@components/ui/button";
import {Link} from "react-router-dom";
import DataTable from "@components/ui/datatable";
import {urls} from "@routes";

interface PageData {
	status_code: number
	status_text: string
	projects: Array<Project>
}

interface Project {
	project_uid: string
	project_name: string
	created_at_ts: string
	project_status: "processing" | "completed"
}

export default function BulkCallsHistory() {
	const [pageData, setPageData] = useState<PageData>();

	// Query to fetch page data.
	const pageDataQuery = useQuery({
		queryKey: ["bulkCallsHistoryPageData"],
		queryFn: () => authenticateAndFetchData("/api/bulk-calls/get-projects/"),
		gcTime: 0,
		retry: retryFn,
		refetchOnWindowFocus: false,
	});
	useEffect(() => {
		if (pageDataQuery.data) {
			setPageData(pageDataQuery.data.data as PageData);
		}
	}, [pageDataQuery.data]);

	// Table columns
	const columns: ColumnDef<Project>[] = [
		{
			accessorKey: "project_name",
			header: "Project Name"
		},
		{
			accessorKey: "created_on",
			header: "Created On",
			accessorFn: originalRow => {
				return formattedDateTime(new Date(originalRow.created_at_ts));
			}
		},
		{
			cell: props => {
				if (props.row.original.project_status === "processing") {
					return (
						<Badge variant="secondary">Processing</Badge>
					)
				} else {
					return (
						<Badge variant="default">Completed</Badge>
					)
				}
			},
			header: "Status",
			enableColumnFilter: false,
		},
		{
			cell: props => {
				return (
					<Link to={urls["bulkCallsProjectDetails"].replace(
						":projectUID", props.row.original.project_uid
					)}
						  className={buttonVariants({variant: "default", size: "sm"})}>
						View Calls
					</Link>
				)
			},
			header: "View Calls"
		}
	]

	// ========================================================================
	// --------------------------- MAIN RENDER CODE ---------------------------
	// ========================================================================

	if (pageDataQuery.isLoading) {
		return (
			<PageLoading/>
		)

	} else if (pageDataQuery.error as unknown as ApiRequestFailed) {
		return <PageDataErrorHandler error={pageDataQuery.error as unknown as ApiRequestFailed}/>

	} else if (pageData) {
		return (
			<div className="w-full border rounded-2xl px-6 py-12 flex flex-col justify-center items-center">
				<div className="mb-10">
					<h1 className="text-4xl font-extrabold text-center mb-2">Your Bulk Call Projects</h1>
					<p className="text-xl text-center">
						Track and manage all your bulk call projects here. Check out each call in a project by using
						the "View" button.
					</p>
				</div>
				<DataTable columns={columns} data={pageData.projects}/>
			</div>
		)

	} else {
		// Ideally it should not reach here.
		return <></>
	}
}
