import {Tabs, TabsContent, TabsList, TabsTrigger} from "@components/ui/tabs";
import {ReactNode, useEffect, useState} from "react";
import {Label} from "@components/ui/label";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "@components/ui/select";
import DataTable from "@components/ui/datatable";
import {ColumnDef} from "@tanstack/react-table";
import {Button} from "@components/ui/button";
import {LoaderCircle, Plus, Trash} from "lucide-react";
import {ApiRequestFailed, authenticateAndFetchData, authenticateAndPostData, retryFn} from "@lib/apis";
import {useMutation, useQuery} from "@tanstack/react-query";
import {useSearchParams} from "react-router-dom";
import {Toaster} from "@components/ui/toaster";
import {cn, formattedDateTime} from "@lib/utils";
import {useToast} from "@components/ui/use-toast";
import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
	AlertDialogTrigger
} from "@components/ui/alert-dialog";
import PageLoading from "@components/ui/pageloading";
import PageDataErrorHandler from "@components/data/pageDataErrorHandler";
import {VerifyNumber} from "@components/ui/verifynumber";

interface PageData {
	status_code: number
	status_text: string

	verified_numbers_count: number
	local_numbers_count: number
	local_numbers_max: number
	tollfree_numbers_count: number
	tollfree_numbers_max: number
}

interface VerifiedNumber {
	id: number
	number: string
}

interface GetVerifiedNumbersResponse {
	status_code: number
	status_text: string
	verified_numbers: Array<VerifiedNumber>
}

interface PurchasedNumber {
	sid: string
	number: string
	type: string
	purchase_timestamp: number
}

interface GetPurchasedNumbersResponse {
	status_code: number
	status_text: string
	purchased_numbers: Array<PurchasedNumber>
}

interface AvailableNumbers {
	phone_number: string
	iso_country: string
	voice_available: boolean
	sms_available: boolean
	mms_available: boolean
}

interface GetNumbersResponse {
	status_code: number
	status_text: string
	available_numbers: Array<AvailableNumbers>
}

export default function PhoneNumber() {
	const [searchParams] = useSearchParams();
	const defaultTab = searchParams.get("tab") || "your_numbers";
	const {toast} = useToast();

	const [pageData, setPageData] = useState<PageData>();
	const [currentTab, setCurrentTab] = useState<string>(defaultTab);

	// Fetch page data.
	const pageDataQuery = useQuery({
		queryKey: ["phoneNumberPageData"],
		queryFn: () => authenticateAndFetchData("/api/phone-number/"),
		gcTime: 0,
		retry: retryFn,
	});
	useEffect(() => {
		if (pageDataQuery.data) {
			setPageData(pageDataQuery.data.data as PageData);
		}
	}, [pageDataQuery.data]);

	function changeTab(tab: string) {
		setCurrentTab(tab);
	}

	function refetchPageData() {
		pageDataQuery.refetch().then();
	}

	function callToast(title: string, text: string, variant: "default" | "destructive") {
		toast({
			title: title,
			description: text,
			variant: variant,
			className: cn(
				'top-0 right-0 flex fixed md:max-w-[420px] md:top-4 md:right-4'
			),
		});
	}

	// ========================================================================
	// --------------------------- MAIN RENDER CODE ---------------------------
	// ========================================================================

	if (pageDataQuery.isLoading) {
		return (
			<PageLoading/>
		)

	} else if (pageDataQuery.error as unknown as ApiRequestFailed) {
		return <PageDataErrorHandler error={pageDataQuery.error as unknown as ApiRequestFailed}/>

	} else if (pageData) {
		return (
			<div className="w-full flex flex-col items-center mt-8">
				<div className="w-full grid grid-cols-1 md:grid-cols-3 gap-6">
					<div className="flex flex-col items-start p-4 border rounded-2xl">
						<h1 className="text-center text-lg">Numbers Verified:</h1>
						<p className="text-center text-2xl font-helvetica-neue-bold mt-2">{pageData.verified_numbers_count}</p>
					</div>
					<div className="flex flex-col items-start p-4 border rounded-2xl">
						<h1 className="text-center text-lg">Local Numbers Purchased:</h1>
						<p className="text-center text-2xl font-helvetica-neue-bold mt-2">
							{pageData.local_numbers_count} / {pageData.local_numbers_max}
						</p>
					</div>
					<div className="flex flex-col items-start p-4 border rounded-2xl">
						<h1 className="text-center text-lg">Toll Free Numbers Purchased:</h1>
						<p className="text-center text-2xl font-helvetica-neue-bold mt-2">
							{pageData.tollfree_numbers_count} / {pageData.tollfree_numbers_max}
						</p>
					</div>
				</div>
				<Tabs defaultValue={defaultTab} value={currentTab} onValueChange={changeTab} className="w-full mt-6">
					<TabsList className="flex items-center justify-between flex-wrap h-auto">
						<TabsTrigger value="your_numbers">Your Phone Numbers</TabsTrigger>
						<TabsTrigger value="verify_number">Verify New Number</TabsTrigger>
						<TabsTrigger value="purchase_number">Purchase New Number</TabsTrigger>
					</TabsList>
					<TabsContent value="your_numbers">
						<YourNumbers callToast={callToast}
									 refetchPageData={refetchPageData}/>
					</TabsContent>
					<TabsContent value="verify_number">
						<VerifyNewNumber refetchPageData={refetchPageData}
										 callToast={callToast}
										 changeTab={changeTab}/>
					</TabsContent>
					<TabsContent value="purchase_number">
						<PurchaseNewNumber callToast={callToast}
										   changeTab={changeTab}
										   refetchPageData={refetchPageData}/>
					</TabsContent>
				</Tabs>
				<Toaster/>
			</div>
		)

	} else {
		// Ideally it should not reach here.
		return <></>
	}
}

// ---------------------- YOUR NUMBERS ----------------------

function YourNumbers(props: {
	callToast: (title: string, text: string, variant: "default" | "destructive") => void,
	refetchPageData: () => void,
}) {
	const [
		verifiedNumbers,
		setVerifiedNumbers
	] = useState<Array<VerifiedNumber>>([]);

	const [
		purchasedNumbers,
		setPurchasedNumbers
	] = useState<Array<PurchasedNumber>>([]);

	// Query for fetching verified numbers.
	const verifiedNumbersQuery = useQuery({
		queryKey: ["fetchVerifiedNumbers"],
		queryFn: () => authenticateAndFetchData("/api/get-user-verified-numbers/"),
		gcTime: 0,
		retry: retryFn,
		refetchOnWindowFocus: true,
	});
	useEffect(() => {
		if (verifiedNumbersQuery.data) {
			let data: GetVerifiedNumbersResponse = verifiedNumbersQuery.data.data;
			setVerifiedNumbers(data.verified_numbers);
		}
	}, [verifiedNumbersQuery.data]);

	// Query for fetching purchased numbers.
	const purchasedNumbersQuery = useQuery({
		queryKey: ["fetchPurchasedNumbers"],
		queryFn: () => authenticateAndFetchData("/api/get-user-purchased-numbers/"),
		gcTime: 0,
		retry: retryFn,
		refetchOnWindowFocus: true,
	});
	useEffect(() => {
		if (purchasedNumbersQuery.data) {
			let data: GetPurchasedNumbersResponse = purchasedNumbersQuery.data.data;
			setPurchasedNumbers(data.purchased_numbers);
		}
	}, [purchasedNumbersQuery.data]);

	// Mutation for deleting purchased number
	const deletePurchasedNumberMutation = useMutation({
		mutationKey: ["deletePurchasedNumber"],
		mutationFn: (numberSID: string) => authenticateAndPostData("/api/release-purchased-number/", {
			"sid": numberSID
		}),
		gcTime: 0,
		retry: retryFn,
		onSuccess: () => {
			// show toast and refetch the data.
			props.callToast(
				"Success",
				"Purchased number removed from your account successfully.",
				"default"
			);
			purchasedNumbersQuery.refetch().then();
			props.refetchPageData();
		},
		onError: (error: ApiRequestFailed) => {
			console.error(error);
			props.callToast(
				"Oops! Something went wrong",
				error.data.message,
				"destructive"
			);
		}
	});

	// Mutation for deleting verified number
	const deleteVerifiedNumberMutation = useMutation({
		mutationKey: ["deleteVerifiedNumber"],
		mutationFn: (id: number) => authenticateAndPostData("/api/delete-verified-number/", {
			"id": id
		}),
		gcTime: 0,
		retry: retryFn,
		onSuccess: (response) => {
			// show toast and refetch the data.
			let number: string = response.data.phone_number;
			props.callToast(
				"Success!",
				`Verified number ${number} has been removed from your account.`,
				"default"
			);
			verifiedNumbersQuery.refetch().then();
			props.refetchPageData();
		},
		onError: (error: ApiRequestFailed) => {
			console.error(error);
			props.callToast(
				"Oops! Something went wrong",
				error.data.message,
				"destructive"
			);
		}
	})

	// Set up the table columns for verified numbers.
	const verifiedColumns: ColumnDef<VerifiedNumber>[] = [
		{
			accessorKey: "number",
			header: "Number"
		},
		{
			id: "remove_number",
			header: () => (<div className="text-right">Remove Number</div>),
			cell: props => {
				return (
					<DeleteVerifiedNumberConfirmation number={props.row.original.number}
													  id={props.row.original.id}
													  deleteVerifiedNumber={deleteVerifiedNumber}>
						<Button variant={"destructive"}>
							<Trash className="w-4 h-4 mr-2"/>Remove
						</Button>
					</DeleteVerifiedNumberConfirmation>
				)
			},
			meta: {
				align: "right"
			}
		},
	]

	// Set up the table columns for purchased numbers.
	const purchasedColumns: ColumnDef<PurchasedNumber>[] = [
		{
			accessorKey: "number",
			header: "Number",
		},
		{
			accessorKey: "type",
			header: "Number Type",
		},
		{
			header: "Purchased On",
			accessorFn: originalRow => formattedDateTime(new Date(originalRow.purchase_timestamp)),
		},
		{
			id: "remove_number",
			header: () => <div className="text-right">Remove Number</div>,
			cell: props => {
				return (
					<DeletePurchasedNumberConfirmation number={props.row.original.number}
													   numberSID={props.row.original.sid}
													   deletePurchasedNumber={deletePurchasedNumber}>
						<Button variant={"destructive"} disabled={deletePurchasedNumberMutation.isPending}>
							<Trash className="w-4 h-4 mr-2"/>Remove
						</Button>
					</DeletePurchasedNumberConfirmation>
				)
			},
			meta: {
				align: "right"
			}
		}
	]

	function deleteVerifiedNumber(id: number) {
		deleteVerifiedNumberMutation.mutate(id);
	}

	function deletePurchasedNumber(numberSID: string) {
		deletePurchasedNumberMutation.mutate(numberSID);
	}

	return (
		<div className="w-full flex flex-col items-center p-6 border rounded-2xl">
			<h1 className="text-center text-4xl font-helvetica-neue-bold">
				Your Phone Numbers
			</h1>
			<p className="text-center text-xl mt-2">
				Manage your existing verified and purchased numbers.
			</p>
			<div className="w-full mt-12 flex flex-col">
				{/* Verified Numbers table */}
				<div className="w-full flex flex-col">
					<h1 className="mb-6 text-2xl font-helvetica-neue-bold">Verified Numbers:</h1>
					<DataTable columns={verifiedColumns} data={verifiedNumbers}/>
				</div>
				{/* Purchased Numbers table */}
				<div className="w-full flex flex-col mt-12">
					<h1 className="mb-6 text-2xl font-helvetica-neue-bold">Purchased Numbers:</h1>
					<DataTable columns={purchasedColumns} data={purchasedNumbers}/>
				</div>
			</div>
		</div>
	)
}

// ---------------------- VERIFY NEW NUMBER ----------------------

function VerifyNewNumber(props: {
	refetchPageData: () => void,
	callToast: (title: string, text: string, variant: "default" | "destructive") => void,
	changeTab: (tab: "your_numbers" | "verify_number" | "purchase_number") => void,
}) {

	// This will be run once number is verified.
	function numberVerified() {
		props.refetchPageData();
		props.callToast(
			"Success!",
			"Your number has been verified.",
			"default",
		)
		props.changeTab("your_numbers");
	}

	// ========================================================================
	// --------------------------- MAIN RENDER CODE ---------------------------
	// ========================================================================

	return (
		<div className="w-full flex flex-col items-center p-6 border rounded-2xl">
			<VerifyNumber number_verified={numberVerified}/>
		</div>
	)
}

// ---------------------- PURCHASE NEW NUMBER ----------------------

function PurchaseNewNumber(props: {
	callToast: (title: string, text: string, variant: "default" | "destructive") => void,
	changeTab: (tab: "your_numbers" | "verify_number" | "purchase_number") => void,
	refetchPageData: () => void,
}) {
	const [numberType, setNumberType] = useState("");

	const [
		availableNumbers,
		setAvailableNumbers
	] = useState<Array<AvailableNumbers>>([]);

	// Set up the table columns.
	const columns: ColumnDef<AvailableNumbers>[] = [
		{
			accessorKey: "phone_number",
			header: "Number"
		},
		{
			accessorKey: "iso_country",
			header: "Country",
		},
		{
			header: "Voice Available",
			cell: props => {
				if (props.row.original.voice_available) {
					return <span className="text-primary">Yes</span>
				} else {
					return <span className="text-destructive">No</span>
				}
			},
			enableGlobalFilter: false,
		},
		{
			header: "SMS Available",
			cell: props => {
				if (props.row.original.sms_available) {
					return <span className="text-primary">Yes</span>
				} else {
					return <span className="text-destructive">No</span>
				}
			},
			enableGlobalFilter: false,
		},
		{
			header: "MMS Available",
			cell: props => {
				if (props.row.original.mms_available) {
					return <span className="text-primary">Yes</span>
				} else {
					return <span className="text-destructive">No</span>
				}
			},
			enableGlobalFilter: false,
		},
		{
			header: "Purchase",
			cell: props => {
				return (
					<Button size="sm"
							disabled={purchaseNumberMutation.isPending}
							onClick={() => purchaseNumberMutation.mutate(
								{number: props.row.original.phone_number, number_type: numberType}
							)}>
						<Plus className="w-4 h-4 mr-2"/>Purcahse
					</Button>
				)
			},
			enableGlobalFilter: false,
		},
	]

	// Mutation for fetching available numbers.
	const getNumbersMutation = useMutation({
		mutationKey: ["getAvailableNumbers"],
		mutationFn: (numberType: string) => authenticateAndFetchData(
			"/api/get-available-numbers?number_type=" + numberType
		),
		gcTime: 0,
		retry: retryFn,
		onSuccess: (response) => {
			let data: GetNumbersResponse = response.data;
			setAvailableNumbers(data.available_numbers);
		},
		onError: (error: ApiRequestFailed) => {
			console.error(error);
			props.callToast("Oops! Something went wrong :(", error.data.message, "destructive");
		}
	});

	// This will update the table on select box change.
	function onNumberTypeChange(value: string) {
		setNumberType(value);
		getNumbersMutation.mutate(value);
	}

	// Mutation for purchasing number.
	const purchaseNumberMutation = useMutation({
		mutationKey: ["purchaseNumber"],
		mutationFn: (data: { number: string, number_type: string }) => authenticateAndPostData(
			"/api/purchase-number/",
			{
				"number": data.number,
				"number_type": data.number_type,
			}),
		gcTime: 0,
		retry: retryFn,
		onSuccess: (response) => {
			// send to purchased numbers page.
			props.callToast(
				"Purchase Successful",
				`Number ${response.data.number} has been added to your account successfully!`,
				"default"
			);
			props.refetchPageData();
			props.changeTab("your_numbers");
		},
		onError: (error: ApiRequestFailed) => {
			console.error(error);
			props.callToast(
				"Purchase Failed",
				error.data.message,
				"destructive"
			);
		}
	})

	// ========================================================================
	// --------------------------- MAIN RENDER CODE ---------------------------
	// ========================================================================

	return (
		<div className="w-full flex flex-col items-center p-6 border rounded-2xl">
			<h1 className="text-center text-4xl font-helvetica-neue-bold">
				Purchase New Number
			</h1>
			<p className="text-center text-xl mt-2">
				Get a number to have our AI answer all the calls on your behalf.
			</p>
			<div className="w-full sm:w-6/12 lg:w-4/12 xl:w-2/12 flex flex-col mt-12 mb-12">
				<Label htmlFor="number-type-select">
					Number Type:
				</Label>
				<Select onValueChange={(value) => onNumberTypeChange(value)} required>
					<SelectTrigger id="number-type-select" className="mt-2 border">
						<SelectValue placeholder="Select type of Number"/>
					</SelectTrigger>
					<SelectContent>
						<SelectItem value="toll_free" key="toll_free">Toll Free</SelectItem>
						<SelectItem value="local" key="local">Local</SelectItem>
					</SelectContent>
				</Select>
			</div>
			{getNumbersMutation.isPending ?
				<LoaderCircle className="w-10 h-10 animate-spin"/> :
				<DataTable columns={columns} data={availableNumbers}/>}
		</div>
	)
}

function DeletePurchasedNumberConfirmation(props: {
	children: ReactNode,
	number: string,
	numberSID: string,
	deletePurchasedNumber: (numberSID: string) => void
}) {
	return (
		<AlertDialog>
			<AlertDialogTrigger asChild>
				{props.children}
			</AlertDialogTrigger>
			<AlertDialogContent>
				<AlertDialogHeader>
					<AlertDialogTitle>Confirm deleting {props.number}?</AlertDialogTitle>
					<AlertDialogDescription>
						This action cannot be undone. Once deleted this number will become unavailable for use
						immediately. All existing call logs for this number will still be accessible.
					</AlertDialogDescription>
				</AlertDialogHeader>
				<AlertDialogFooter>
					<AlertDialogCancel>
						Cancel
					</AlertDialogCancel>
					<AlertDialogAction onClick={() => props.deletePurchasedNumber(props.numberSID)}>
						Continue
					</AlertDialogAction>
				</AlertDialogFooter>
			</AlertDialogContent>
		</AlertDialog>
	)
}

function DeleteVerifiedNumberConfirmation(props: {
	children: ReactNode,
	id: number,
	number: string,
	deleteVerifiedNumber: (id: number) => void
}) {
	return (
		<AlertDialog>
			<AlertDialogTrigger asChild>
				{props.children}
			</AlertDialogTrigger>
			<AlertDialogContent>
				<AlertDialogHeader>
					<AlertDialogTitle>Confirm deleting {props.number}?</AlertDialogTitle>
					<AlertDialogDescription>
						This action cannot be undone. Once deleted this number will become unavailable for use
						immediately. All existing call logs for this number will still be accessible.
					</AlertDialogDescription>
				</AlertDialogHeader>
				<AlertDialogFooter>
					<AlertDialogCancel>
						Cancel
					</AlertDialogCancel>
					<AlertDialogAction onClick={() => props.deleteVerifiedNumber(props.id)}>
						Continue
					</AlertDialogAction>
				</AlertDialogFooter>
			</AlertDialogContent>
		</AlertDialog>
	)
}
