import {useEffect, useState} from "react";
import {ColumnDef} from "@tanstack/react-table";
import {useQuery} from "@tanstack/react-query";
import {ApiRequestFailed, authenticateAndFetchData, retryFn} from "@lib/apis";
import PageLoading from "@components/ui/pageloading";
import PageDataErrorHandler from "@components/data/pageDataErrorHandler";
import DataTable from "@components/ui/datatable";
import {formattedDateTime} from "@lib/utils";
import {Button, buttonVariants} from "@components/ui/button";
import {Link} from "react-router-dom";
import { urls } from "@routes";

interface PageData {
    status_code: number
    status_text: string
    users: Array<User>
}

interface User {
    id: number
    username: string
    email: string
    date_joined_ts: number
    verified_numbers: Array<VerifiedNumber>
    current_usage_seconds: number
}

interface VerifiedNumber {
    country_code: string
    number: string
}

export default function AdminAllUsers() {
    const [pageData, setPageData] = useState<PageData>();

    // Table columns
    const columns: ColumnDef<User>[] = [
        {
            accessorKey: "username",
            header: "Name"
        },
        {
            accessorKey: "email",
            id: "email",
            header: "Email ID",
            filterFn: "includesString"
        },
        {
            accessorKey: "date_joined",
            header: "Date Joined",
            accessorFn: originalRow => {
                console.log(originalRow.date_joined_ts)
                return formattedDateTime(new Date(originalRow.date_joined_ts));
            }
        },
        {
            accessorKey: "verified_numbers",
            header: "Verified Numbers",
            accessorFn: originalRow => {
                let s = ""
                originalRow.verified_numbers.forEach(num => {
                    s += `+${num.country_code}${num.number}\n`
                });
                return s || "---"
            }
        },
        {
            accessorKey: "current_usage_seconds",
            header: "Usage Time",
            accessorFn: originalRow => {
                return usageTimeFormatter(originalRow.current_usage_seconds);
            }
        },
        {
            header: "View User",
            cell: props => {
                return (
                    <Link to={urls["adminUserDetails"].replace(":userID", props.row.original.id.toString())}
                          className={buttonVariants({variant: "default", size: "sm"})}>
                        View User
                    </Link>
                )
            }
        }
    ]

    // Fetch page data
    const pageDataQuery = useQuery({
        queryKey: ["adminAllUsers"],
        queryFn: () => authenticateAndFetchData("/api/admin/all-users/"),
        gcTime: 0,
        retry: retryFn,
    });
    useEffect(() => {
        if (pageDataQuery.data) {
            setPageData(pageDataQuery.data.data as PageData);
        }
    }, [pageDataQuery.data]);

    function usageTimeFormatter(seconds: number) {
        let minutes = Math.floor(seconds / 60);
        seconds = seconds % 60;

        let minute_string = minutes < 10 ? `0${minutes} min` : `${minutes} min`;
        let seconds_string = seconds < 10 ? `0${seconds} sec` : `${seconds} sec`;
        return `${minute_string} ${seconds_string}`
    }


    // ========================================================================
    // --------------------------- MAIN RENDER CODE ---------------------------
    // ========================================================================

    if (pageDataQuery.isLoading) {
        return (
            <PageLoading/>
        )

    } else if (pageDataQuery.error as unknown as ApiRequestFailed) {
        return <PageDataErrorHandler error={pageDataQuery.error as unknown as ApiRequestFailed}/>

    } else if (pageData) {
        return (
            <div className="w-full flex flex-col items-center">
                <div className="mb-10">
                    <h1 className="text-4xl font-extrabold text-center mb-2">All Registered Users</h1>
                </div>
                <DataTable columns={columns} data={pageData.users}/>
            </div>
        )

    } else {
        // Ideally it should not reach here.
        return <></>
    }
}
