import {Card, CardContent, CardDescription, CardHeader, CardTitle} from "@components/ui/card";
import {Label} from "@components/ui/label";
import {Input} from "@components/ui/input";
import {Button} from "@components/ui/button";
import {useState} from "react";
import {useToast} from "@components/ui/use-toast";
import {Toaster} from "@components/ui/toaster";
import {cn, getCurrentTheme} from "@lib/utils";
import {useMutation} from "@tanstack/react-query";
import {ApiRequestFailed, authenticateAndPostData, retryFn} from "@lib/apis";
import logo_white from "@images/branding/ai_call_center_logo_white.png";
import logo_black from "@images/branding/ai_call_center_logo_black.png";

export default function ForgotPassword() {
	const {toast} = useToast()

	const [email, setEmail] = useState("");
	const [errorText, setErrorText] = useState("");
	const [buttonText, setButtonText] = useState("Send Link");
	const [disableButton, setDisableButton] = useState(false);

	// Mutation for sending password reset link.
	const sendLinkMutation = useMutation({
		mutationKey: ['sendLink', email],
		mutationFn: () => authenticateAndPostData("/api/send-reset-password-email/", {
			"email_id": email,
		}),
		gcTime: 0,
		retry: retryFn,
		onSuccess: (response) => {
			setButtonText("Sent!");
			setDisableButton(true);
			toast({
				title: "Password Reset Link Sent",
				description: "Please follow the instructions in the email to reset your password.",
				variant: "default",
				className: cn(
					'top-0 right-0 flex fixed md:max-w-[420px] md:top-4 md:right-4'
				),
			});
		},
		onError: (error: ApiRequestFailed) => {
			console.error(error);
			setErrorText(error.data.message);
		}
	})

	function sendPasswordResetLink() {
		setErrorText("");
		if (email) {
			sendLinkMutation.mutate();
		} else {
			setErrorText("Please enter a valid email address.");
		}
	}

	return (
		<div className={"w-full flex flex-col justify-center items-center"}>
			<img src={getCurrentTheme() === "dark" ? logo_white : logo_black} alt="logo" className={"w-[300px] md:w-[400px]"}/>
			<Card className="mx-auto max-w-sm w-96 mt-24">
				<CardHeader>
					<CardTitle className="text-2xl">Reset Password</CardTitle>
					<CardDescription>
						Enter your email address and we'll send a password reset link to get back into your account.
					</CardDescription>
				</CardHeader>
				<CardContent>
					<div className="grid gap-4">
						<div className="grid gap-2">
							<Label htmlFor="email"><span className="text-red-700">*</span>&nbsp;Email</Label>
							<Input
								id="email"
								type="email"
								placeholder="me@example.com"
								value={email}
								onChange={e => setEmail(e.target.value)}
								required
							/>
						</div>
						<Button className="w-full mt-7"
										onClick={sendPasswordResetLink}
										disabled={disableButton || sendLinkMutation.isPending}>
							{sendLinkMutation.isPending ? "Sending..." : buttonText}
						</Button>
					</div>
					<div className="mt-4 text-center text-sm">
						Don't have an account?{" "}
						<a href="/auth/signup" className="underline">
							Sign up
						</a>
					</div>
					{errorText && <p className="text-red-600 text-center mt-4">{errorText}</p>}
				</CardContent>
			</Card>
			<Toaster/>
		</div>
	)
}
