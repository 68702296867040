import {Tabs, TabsContent, TabsList, TabsTrigger} from "@components/ui/tabs";
import {useState} from "react";
import BulkCallsCreate from "@pages/BulkDelegateCall/BulkCallsCreate";
import BulkCallsHistory from "@pages/BulkDelegateCall/BulkCallsHistory";
import {useSearchParams} from "react-router-dom";

export default function BulkCalls() {
	const [searchParams] = useSearchParams();
	const tab = searchParams.get("tab") || "create";

	const [
		currentTab,
		setCurrentTab
	] = useState<string>(tab);

	function changeTab(value: string) {
		setCurrentTab(value);
	}

	// ========================================================================
	// --------------------------- MAIN RENDER CODE ---------------------------
	// ========================================================================

	return (
		<div className="w-full flex flex-col items-center justify-center">
			<Tabs value={currentTab} onValueChange={changeTab} className="w-full">
				<TabsList className="grid w-full grid-cols-2">
					<TabsTrigger value="create">Create Bulk Delegate Tasks</TabsTrigger>
					<TabsTrigger value="history">Bulk Call History</TabsTrigger>
				</TabsList>
				<TabsContent value="create" className="mt-6">
					<BulkCallsCreate/>
				</TabsContent>
				<TabsContent value="history" className="mt-6">
					<BulkCallsHistory/>
				</TabsContent>
			</Tabs>
		</div>
	)
}
