import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {useMutation, useQuery} from "@tanstack/react-query";
import {
	ApiRequestFailed,
	authenticateAndFetchData,
	authenticateAndPostData,
	authenticateAndPostFormData,
	retryFn
} from "@lib/apis";
import {ColumnDef} from "@tanstack/react-table";
import {Button} from "@components/ui/button";
import {CloudUpload, Trash2} from "lucide-react";
import PageLoading from "@components/ui/pageloading";
import PageDataErrorHandler from "@components/data/pageDataErrorHandler";
import DataTable from "@components/ui/datatable";
import {Input} from "@components/ui/input";
import {Label} from "@components/ui/label";

interface PageData {
	status_code: number
	status_text: string

	audio_files: Array<SampleAudio>
}

interface SampleAudio {
	file_name: string
	file_link: string
}

export default function AdminVoiceSample() {
	const {voiceID} = useParams();

	const [pageData, setPageData] = useState<PageData>();
	const [voiceSampleFile, setVoiceSampleFile] = useState<FileList>();
	const [errorText, setErrorText] = useState<string>("");

	// Fetch page data
	const pageDataQuery = useQuery({
		queryKey: ["getSampleAudio", voiceID],
		queryFn: () => authenticateAndFetchData("/api/admin/get-voice-sample-file?voice_id=" + voiceID),
		gcTime: 0,
		retry: retryFn
	});
	useEffect(() => {
		if (pageDataQuery.data) {
			let data = pageDataQuery.data.data as PageData;
			setPageData(data);
		}
	}, [pageDataQuery.data]);

	// Mutation to add audio files
	const addSampleAudioMutation = useMutation({
		mutationKey: ["addSampleAudio", voiceID],
		mutationFn: () => {
			let fd = new FormData();
			fd.append("voice_id", voiceID!);
			if (voiceSampleFile) {
				[...voiceSampleFile].forEach(audioFile => {
					fd.append(audioFile.name, audioFile);
				});
			}
			return authenticateAndPostFormData("/api/admin/add-sample-audio/", fd);
		},
		gcTime: 0,
		retry: retryFn,
		onSuccess: () => {
			// Refetch the page data.
			pageDataQuery.refetch().then();
		},
		onError: (error: ApiRequestFailed) => {
			console.error(error);
			setErrorText(error.data.message);
		}
	});

	// Mutation to delete Filler audio files.
	const deleteSampleAudioMutation = useMutation({
		mutationKey: ["deleteSampleAudio", voiceID],
		mutationFn: () => authenticateAndPostData("/api/admin/delete-sample-audio/", {
			"voice_id": voiceID,
		}),
		gcTime: 0,
		retry: retryFn,
		onSuccess: () => {
			// Refetch page data.
			pageDataQuery.refetch().then();
		},
		onError: (error: ApiRequestFailed) => {
			console.error(error);
			setErrorText(error.data.message);
		}
	});

	// Build the table columns.
	const columns: ColumnDef<SampleAudio>[] = [
		{
			accessorKey: "file_name",
			header: "File Name",
		},
		{
			header: "Play Audio",
			cell: props => {
				return <audio controls src={props.row.original.file_link}></audio>
			}
		},
		{
			accessorKey: "file_link",
			header: "Link",
			cell: props => (
				<a href={props.row.original.file_link}
				   className="underline text-primary"
				   target="_blank"
				   rel="noreferrer">
					{props.row.original.file_link}
				</a>
			),
			enableGlobalFilter: false
		},
		{
			header: "Delete Audio",
			cell: () => {
				return (
					<Button variant="destructive"
							size="sm"
							onClick={() => deleteSampleAudioMutation.mutate()}>
						<Trash2 className="mr-2 w-4 h-4"/>Delete
					</Button>
				)
			},
			enableGlobalFilter: false
		}
	]

	function addSampleAudio() {
		setErrorText("");
		if (pageData!.audio_files.length > 0) {
			setErrorText("Please delete the existing audio file first.");
		} else {
			if (voiceSampleFile) {
				addSampleAudioMutation.mutate();
			} else {
				setErrorText("Please select a file.");
			}
		}
	}

	// ========================================================================
	// --------------------------- MAIN RENDER CODE ---------------------------
	// ========================================================================

	if (pageDataQuery.isLoading) {
		return (
			<PageLoading/>
		)

	} else if (pageDataQuery.error as unknown as ApiRequestFailed) {
		return <PageDataErrorHandler error={pageDataQuery.error as unknown as ApiRequestFailed}/>

	} else if (pageData) {
		// @ts-ignore
		return (
			<div className="w-full">
				<div className="flex flex-col items-center px-6 py-6 border rounded-2xl">
					<h1 className="text-4xl text-center font-helvetica-neue-bold">Add New Sample Audio</h1>
					<p className="text-center text-lg mt-4 w-1/2">
						<b className="underline">IMPORTANT:</b> Before uploading, please make sure the audio files
						have a <b>sample rate of 8000Hz</b> and are in&nbsp;
						<a href="https://en.wikipedia.org/wiki/%CE%9C-law_algorithm"
						   className="underline text-primary"
						   target="_blank"
						   rel="noreferrer">
							mu-law
						</a> format. Otherwise the audio won't work or might play loud static noises.
					</p>
					<p className="text-center text-lg mt-4 w-1/2">
						Only 1 sample audio per voice is possible.<br/>
						Delete existing one first if you want to upload another.
					</p>
					<div className="grid w-full max-w-sm items-center gap-1.5 mt-10">
						<Label htmlFor="filler-audio-input">Select a File</Label>
						<Input id="filler-audio-input"
							   type="file"
							   accept="wav"
							   multiple={false}
							   onChange={e => {
								   e.target.files && setVoiceSampleFile(e.target.files)
							   }}/>
					</div>
					<Button className="mt-6" onClick={addSampleAudio}>
						<CloudUpload className="mr-2 w-4 h-4"/>Upload Audio File
					</Button>
					{errorText && <p className="mt-6 text-destructive text-center">{errorText}</p>}
				</div>
				{/* ---------- Fillers Audio Table ---------- */}
				<div className="w-full flex flex-col items-center mt-12">
					<DataTable columns={columns} data={pageData.audio_files}/>
				</div>
			</div>
		)

	} else {
		// Ideally it should not reach here.
		return <></>

	}
}
