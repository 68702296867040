import {useEffect, useState} from "react";
import {Label} from "@components/ui/label";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "@components/ui/select";
import {Input} from "@components/ui/input";
import {Textarea} from "@components/ui/textarea";
import {Checkbox} from "@components/ui/checkbox";
import {Button} from "@components/ui/button";
import {ApiRequestFailed, authenticateAndFetchData, authenticateAndPostData, retryFn} from "@lib/apis";
import {useMutation, useQuery} from "@tanstack/react-query";
import {useNavigate} from "react-router-dom";
import {urls} from "@routes";

interface PageData {
    status_code: number
    status_text: string
}

export default function AdminAddVoice() {
    const navigate = useNavigate();

    const [pageData, setPageData] = useState<PageData>();
    const [source, setSource] = useState<string>("");
    const [voiceID, setVoiceID] = useState<string>("");
    const [voiceName, setVoiceName] = useState<string>("");
    const [voiceGender, setVoiceGender] = useState<string>("");
    const [voiceNationality, setVoiceNationality] = useState<string>("");
    const [voicePersonality, setVoicePersonality] = useState<string>("");
    const [elevenlabsVoiceModel, setElevenlabsVoiceModel] = useState<string>("");
    const [activateVoice, setActivateVoice] = useState<boolean>(false);
    const [errorText, setErrorText] = useState<string>("");

    // Fetch page data.
    const pageDataQuery = useQuery({
        queryKey: ["adminAddVoice"],
        queryFn: () => authenticateAndFetchData("/api/admin/add-voice/"),
        gcTime: 0,
        retry: retryFn
    });
    useEffect(() => {
        if (pageDataQuery.data) {
            setPageData(pageDataQuery.data.data as PageData);
        }
    }, [pageDataQuery.data]);

    // Mutation for adding voice.
    const addVoiceMutation = useMutation({
        mutationKey: ["addVoice"],
        mutationFn: () => authenticateAndPostData("/api/admin/add-voice/", {
            "voice_id": voiceID,
            "voice_name": voiceName,
            "source": source,
            "is_active": activateVoice,
            "gender": voiceGender,
            "personalities": voicePersonality.split("\n"),
            "nationality": voiceNationality,
            "elevenlabs_model": elevenlabsVoiceModel,
        }),
        gcTime: 0,
        retry: retryFn,
        onSuccess: () => {
            navigate(urls["adminVoiceBuilder"]);
        },
        onError: (error: ApiRequestFailed) => {
            setErrorText(error.data.message);
            console.error(error);
        }
    });

    /**
     * Checks values and makes API request to create new voice.
     */
    function addVoice() {
        setErrorText("");
        if (source === "elevenlabs") {
            if (voiceID && voiceName && voiceGender && voiceNationality && voicePersonality && elevenlabsVoiceModel) {
                addVoiceMutation.mutate();

            } else {
                setErrorText("Please fill in all the required fields for elevenlabs.");
            }

        } else if (["azure", "cartesia"].includes(source)) {
            if (voiceID && voiceName && voiceGender && voiceNationality && voicePersonality) {
                addVoiceMutation.mutate();
            } else {
                setErrorText("Please fill in all the required fields for azure.");
            }

        } else {
            setErrorText("Please set the voice source.");
        }
    }

    // ========================================================================
    // --------------------------- MAIN RENDER CODE ---------------------------
    // ========================================================================

    return (
        <div className="w-full flex flex-col items-center justify-center">
            <h1 className="text-4xl font-helvetica-neue-bold">Create A New Voice</h1>
            <div
                className="w-4/12 flex flex-col my-10 px-6 py-10 border-2 border-muted-foreground/30 rounded-2xl transition-all">
                {/* Source Selection */}
                <div className="w-full flex flex-col">
                    <Label htmlFor="source-select">
                        Select Voice Source:
                    </Label>
                    <Select onValueChange={setSource} required>
                        <SelectTrigger id="source-select" className="mt-2 border-muted-foreground/50">
                            <SelectValue placeholder="Source"/>
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value="elevenlabs" key="elevenlabs">ElevenLabs</SelectItem>
                            <SelectItem value="azure" key="azure">Azure</SelectItem>
                            <SelectItem value="cartesia" key="cartesia">Cartesia</SelectItem>
                        </SelectContent>
                    </Select>
                </div>
                {/* Voice ID */}
                <div className="w-full flex flex-col mt-6">
                    <Label htmlFor="voice-id">
                        Voice ID:
                    </Label>
                    <Input type="text"
                           id="voice-id"
                           className="mt-2 border-muted-foreground/50"
                           value={voiceID}
                           onChange={e => setVoiceID(e.target.value)}
                           placeholder=""/>
                </div>
                {/* AI Voice Name & Gender Selection */}
                <div className="w-full grid grid-cols-2 mt-6 gap-6">
                    <div className="w-full flex flex-col">
                        <Label htmlFor="voice-model-name">
                            Voice Model Name:
                        </Label>
                        <Input type="text"
                               id="voice-model-name"
                               className="mt-2 border-muted-foreground/50"
                               value={voiceName}
                               onChange={e => setVoiceName(e.target.value)}
                               placeholder="ex. Chris"/>
                    </div>
                    <div className="w-full flex flex-col">
                        <Label htmlFor="gender-select">
                            Select Gender:
                        </Label>
                        <Select onValueChange={setVoiceGender} required>
                            <SelectTrigger id="gender-select" className="mt-2 border-muted-foreground/50">
                                <SelectValue placeholder="Gender"/>
                            </SelectTrigger>
                            <SelectContent>
                                <SelectItem value="M" key="Male">Male</SelectItem>
                                <SelectItem value="F" key="Female">Female</SelectItem>
                            </SelectContent>
                        </Select>
                    </div>
                </div>
                {/* Voice Nationality */}
                <div className="w-full flex flex-col mt-6">
                    <Label htmlFor="voice-nationality">
                        Voice Nationality / Accent:
                    </Label>
                    <Input type="text"
                           id="voice-nationality"
                           className="mt-2 border-muted-foreground/50"
                           value={voiceNationality}
                           onChange={e => setVoiceNationality(e.target.value)}
                           placeholder="ex. Indian"/>
                </div>
                {/* Voice Personality */}
                <div className="w-full flex flex-col mt-6">
                    <Label htmlFor="voice-personality-list">
                        Personality (<a href="https://ideonomy.mit.edu/essays/traits.html"
                                        rel="noreferrer"
                                        target="_blank"
                                        className="underline">refer to this site</a>):
                    </Label>
                    <Textarea id="voice-personality-list"
                              className="mt-2 border-muted-foreground/50"
                              rows={4}
                              value={voicePersonality}
                              placeholder="Add one personality on each line (1 Positive, 2 Neutral & 1 Negative)"
                              onChange={e => setVoicePersonality(e.target.value)}>
                    </Textarea>
                </div>

                {/* ------------------- ElevenLabs only ------------------- */}
                <hr className="my-8"/>
                <div className="w-full flex flex-col">
                    <Label htmlFor="elevenlabs-model-select">
                        ElevenLabs Model <b>(Only for "elevenlabs" source)</b>:
                    </Label>
                    <Select onValueChange={setElevenlabsVoiceModel} required>
                        <SelectTrigger id="elevenlabs-model-select" className="mt-2 border-muted-foreground/50">
                            <SelectValue placeholder="Select Model"/>
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value="eleven_turbo_v2" key="eleven_turbo_v2">
                                Turbo V2
                            </SelectItem>
                            <SelectItem value="eleven_multilingual_v2" key="eleven_multilingual_v2">
                                Multilingual V2
                            </SelectItem>
                        </SelectContent>
                    </Select>
                </div>

                {/* Activate Voice */}
                <hr className="my-8"/>
                <div className="w-full flex flex-col">
                    <p className="text-sm">
                        Selecting will make this voice available to all users <b>immediately</b>.
                        Ideally you should leave this unchecked and only activate it after filler and greeting
                        voices have been set up.
                    </p>
                    <div className="flex items-center space-x-2 mt-4">
                        <Checkbox id="terms"
                                  className="rounded"
                                  defaultChecked={activateVoice}
                                  onChange={() => {
                                      setActivateVoice(!activateVoice);
                                  }}/>
                        <label
                            htmlFor="terms"
                            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                        >
                            Activate Voice
                        </label>
                    </div>
                </div>
                <Button className="mt-8" onClick={addVoice}>Create AI Voice Model</Button>
                {errorText && <p className="text-destructive mt-4 text-center">{errorText}</p>}
            </div>
        </div>
    )
}
