import {ReactNode, useEffect, useState} from "react";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import {ColumnDef} from "@tanstack/react-table";
import {Button} from "@components/ui/button";
import {ChevronLeft, Plus, Trash} from "lucide-react";
import DataTable from "@components/ui/datatable";
import {urls} from "@routes";
import {useMutation, useQuery} from "@tanstack/react-query";
import {ApiRequestFailed, authenticateAndFetchData, authenticateAndPostData, retryFn} from "@lib/apis";
import PageLoading from "@components/ui/pageloading";
import PageDataErrorHandler from "@components/data/pageDataErrorHandler";
import {BaseOutletContext} from "@pages/Base";
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger
} from "@components/ui/dialog";
import {Label} from "@components/ui/label";
import {Input} from "@components/ui/input";
import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
	AlertDialogTrigger
} from "@components/ui/alert-dialog";

interface PageData {
	status_code: number
	status_text: string

	user_email: string
	username: string
	verified_numbers: Array<VerifiedNumber>
}

interface VerifiedNumber {
	id: number
	number_with_country_code: string
	total_calls_made: number
}

interface AddNumberResponse {
	status_code: number
	status_text: string

	verified_number: string
	user_email: string
}

export default function AdminUserVerifiedNumbers() {
	const {userID} = useParams();
	const navigate = useNavigate();
	const {showToast} = useOutletContext<BaseOutletContext>();

	const [
		pageData,
		setPageData
	] = useState<PageData>();
	const [
		verifiedNumbers,
		setVerifiedNumbers
	] = useState<VerifiedNumber[]>([]);
	const [
		showAddNumberDialog,
		setShowAddNumberDialog
	] = useState<boolean>(false);

	// Fetch page data.
	const pageDataQuery = useQuery({
		queryKey: ["adminUserVerifiedNumbersPageData"],
		queryFn: () => authenticateAndFetchData("/api/admin/user-verified-numbers?user_id=" + userID),
		gcTime: 0,
		retry: retryFn,
		refetchOnWindowFocus: false,
	});
	useEffect(() => {
		if (pageDataQuery.data) {
			let data = pageDataQuery.data.data as PageData
			setPageData(data);
			setVerifiedNumbers(data.verified_numbers);
		}
	}, [pageDataQuery.data]);

	// Mutation to add new verified number.
	const addNumberMutation = useMutation({
		mutationKey: ["adminAddVerifiedNumberMutation"],
		mutationFn: (data: {
			countryCode: string,
			phoneNumber: string,
		}) => authenticateAndPostData("/api/admin/user-verified-numbers/", {
			"action": "add",
			"user_id": userID || "",
			"country_code": data.countryCode,
			"phone_number": data.phoneNumber,
		}),
		gcTime: 0,
		retry: retryFn,
		onSuccess: (response) => {
			let data = response.data as AddNumberResponse;
			// close dialog, refetch page data & show alert.
			setShowAddNumberDialog(false);
			pageDataQuery.refetch().then();
			showToast(
				"Success!",
				`Number ${data.verified_number} has been added to ${data.user_email} account.`,
				"default",
			);
		},
		onError(error: ApiRequestFailed) {
			// Show alert.
			console.error(error);
			showToast(
				"Error",
				error.data.message,
				"destructive",
			);
		}
	});

	// Mutation to delete number.
	const deleteNumberMutation = useMutation({
		mutationKey: ["adminRemoveNumberMutation"],
		mutationFn: (data: { numberID: number }) => authenticateAndPostData("/api/admin/user-verified-numbers/", {
			"action": "delete",
			"user_id": userID || "",
			"number_id": data.numberID,
		}),
		gcTime: 0,
		retry: retryFn,
		onSuccess: (response) => {
			let data = response.data as AddNumberResponse;
			// Refetch page data & show alert.
			pageDataQuery.refetch().then();
			showToast(
				"Success!",
				`Number ${data.verified_number} has been removed from ${data.user_email} account.`,
				"default",
			);
		},
		onError: (error: ApiRequestFailed) => {
			console.error(error);
			showToast(
				"Error",
				error.data.message,
				"destructive",
			);
		}
	})

	const columns: ColumnDef<VerifiedNumber>[] = [
		{
			accessorKey: "number_with_country_code",
			header: "Number",
		},
		{
			accessorKey: "total_calls_made",
			header: "Total Calls Made",
		},
		{
			header: "Remove Number",
			cell: props => {
				return (
					<RemoveNumberAlert number={props.row.original.number_with_country_code}
									   numberID={props.row.original.id}
									   deleteEventHandler={removeVerifiedNumber}>
						<Button size="sm" variant={"destructive"}>
							<Trash className="w-4 h-4 mr-2"/>Remove
						</Button>
					</RemoveNumberAlert>
				)
			},
			enableGlobalFilter: false,
		},
	];

	function goBack() {
		navigate(urls["adminUserDetails"].replace(":userID", userID || ""));
	}

	/**
	 * Function to handle adding new verified number. Country code should not have "+" symbol.
	 */
	function addVerifiedNumber(countryCode: string, phoneNumber: string) {
		if (countryCode === "" || phoneNumber === "" || isNaN(Number(countryCode)) || isNaN(Number(phoneNumber))) {
			showToast(
				"Invalid Values",
				"Please enter a valid country code & phone number. Make sure not to add + symbol in country code.",
				"destructive",
			);

		} else {
			addNumberMutation.mutate({countryCode: countryCode, phoneNumber: phoneNumber});
		}
	}

	/**
	 * Function to handle removing selected verified number from user account.
	 * @param numberID - Database "id" value of number.
	 */
	function removeVerifiedNumber(numberID: number) {
		deleteNumberMutation.mutate({numberID: numberID});
	}

	// ========================================================================
	// --------------------------- MAIN RENDER CODE ---------------------------
	// ========================================================================

	if (pageDataQuery.isLoading) {
		return (
			<PageLoading/>
		)

	} else if (pageDataQuery.error as unknown as ApiRequestFailed) {
		return <PageDataErrorHandler error={pageDataQuery.error as unknown as ApiRequestFailed}/>

	} else if (pageData) {
		return (
			<div className="w-full flex flex-col">
				{/* Header */}
				<div className="flex flex-row justify-start items-center">
					<Button variant="ghost" className="text-xl" onClick={goBack}>
						<ChevronLeft className="w-6 h-6 mr-2"/>Go Back
					</Button>
					<h1 className="font-helvetica-neue-bold text-xl ml-6">
						Verified Numbers
						for <span className="text-primary">{pageData.user_email}</span> ({pageData.username})
					</h1>
				</div>
				{/* Main Content */}
				<div className="w-full flex flex-col mt-12 items-center justify-center">
					<div className="w-full">
						<DataTable columns={columns} data={verifiedNumbers}/>
					</div>
					<AddNumberDialog addNumberEventHandler={addVerifiedNumber}
									 open={showAddNumberDialog}
									 setOpen={setShowAddNumberDialog}>
						<Button className="mt-6">
							<Plus className="w-4 h-4 mr-2"/>Add New Number
						</Button>
					</AddNumberDialog>
				</div>
			</div>
		)

	} else {
		// Ideally it should not reach here.
		return <></>
	}
}

export function AddNumberDialog(props: {
	children: ReactNode,
	addNumberEventHandler: (countryCode: string, phoneNumber: string) => void,
	open: boolean,
	setOpen: (value: boolean) => void,
}) {
	const [countryCode, setCountryCode] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");

	return (
		<Dialog open={props.open} onOpenChange={props.setOpen}>
			<DialogTrigger asChild>
				{props.children}
			</DialogTrigger>
			<DialogContent className="sm:max-w-[425px]">
				<DialogHeader>
					<DialogTitle>Add New Verified Number</DialogTitle>
					<DialogDescription>
						NOTE: The number will be added directly without any prior verification. So please make sure the
						number is
						valid before adding.
					</DialogDescription>
				</DialogHeader>
				<div className="w-full flex flex-col">
					<div className="w-full flex flex-col">
						<Label htmlFor="country-code" className="font-helvetica-neue-bold">
							Country Code (do not add +):
						</Label>
						<Input id="country-code"
							   className="mt-4"
							   value={countryCode}
							   onChange={e => setCountryCode(e.target.value)}
							   placeholder={"ex: 91"}
							   required/>
					</div>
					<div className="w-full flex flex-col mt-6">
						<Label htmlFor="phone-number" className="font-helvetica-neue-bold">
							Phone Number:
						</Label>
						<Input id="phone-number"
							   className="mt-4"
							   value={phoneNumber}
							   onChange={e => setPhoneNumber(e.target.value)}
							   placeholder={"ex: 9833123456"}
							   required/>
					</div>
				</div>
				<DialogFooter>
					<Button onClick={() => props.addNumberEventHandler(countryCode, phoneNumber)}>
						Save changes
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	)
}

export function RemoveNumberAlert(props: {
	children: ReactNode,
	number: string,
	numberID: number,
	deleteEventHandler: (numberID: number) => void
}) {
	return (
		<AlertDialog>
			<AlertDialogTrigger asChild>
				{props.children}
			</AlertDialogTrigger>
			<AlertDialogContent>
				<AlertDialogHeader>
					<AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
					<AlertDialogDescription>
						This action cannot be undone. This will permanently remove number <b>{props.number}</b> from
						this account. You can add this number back later but any associated stats will be reset.
					</AlertDialogDescription>
				</AlertDialogHeader>
				<AlertDialogFooter>
					<AlertDialogCancel>Cancel</AlertDialogCancel>
					<AlertDialogAction onClick={() => props.deleteEventHandler(props.numberID)}>
						Confirm
					</AlertDialogAction>
				</AlertDialogFooter>
			</AlertDialogContent>
		</AlertDialog>
	)
}
