import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {ApiRequestFailed, authenticateAndFetchData, retryFn} from "@lib/apis";
import {urls} from "@routes";
import PageLoading from "@components/ui/pageloading";
import PageDataErrorHandler from "@components/data/pageDataErrorHandler";
import {formattedDateTime} from "@lib/utils";
import {Label} from "@components/ui/label";
import {Button} from "@components/ui/button";
import {ChevronLeft} from "lucide-react";


interface PageData {
	status_code: number
	status_text: string

	project_name: string
	participant_email_id: string
	ai_name: string
	ai_nationality: string
	language: string
	call_instructions: string
	post_call_instructions: string
	post_call_result: string | null
	call_log: Array<CallTranscription>
	call_duration_minutes: number
	call_duration_seconds: number
	call_status: "created" | "ongoing" | "ended"
}

interface CallTranscription {
	role: string
	content: string
	timestamp?: number
}

export default function BulkDelegateMeetingCallDetails() {
	const navigate = useNavigate();
	const {projectUID, callUID} = useParams();

	const [pageData, setPageData] = useState<PageData>();


	// Fetch page data.
	const pageDataQuery = useQuery({
		queryKey: ["bulkCallsProjectCallDetailsPageData"],
		queryFn: () => authenticateAndFetchData(
			`/api/bulk-delegate-meeting-calls/get-project-call-details?project_uid=${projectUID}&call_uid=${callUID}`,
		),
		gcTime: 0,
		retry: retryFn,
		refetchOnWindowFocus: false,
	});
	useEffect(() => {
		if (pageDataQuery.data) {
			setPageData(pageDataQuery.data.data as PageData);
		}
	}, [pageDataQuery.data]);

	/**
	 * Returns call duration as a string in the following format:
	 * "MM min SS sec"
	 */
	function callDurationFormatter(minutes: number, seconds: number) {
		let minute_string = minutes < 10 ? `0${minutes} min` : `${minutes} min`;
		let seconds_string = seconds < 10 ? `0${seconds} sec` : `${seconds} sec`;
		return `${minute_string} ${seconds_string}`
	}

	function goBack() {
		navigate(urls["bulkDelegateMeetingProject"].replace(":projectUID", projectUID || ""));
	}

	// ========================================================================
	// --------------------------- MAIN RENDER CODE ---------------------------
	// ========================================================================

	if (pageDataQuery.isLoading) {
		return (
			<PageLoading/>
		)

	} else if (pageDataQuery.error as unknown as ApiRequestFailed) {
		return <PageDataErrorHandler error={pageDataQuery.error as unknown as ApiRequestFailed}/>

	} else if (pageData) {
		return (
			<div className="grid-rows-3 w-full">
				<div className="flex flex-row justify-start items-center mb-12">
					<Button variant="ghost" className="text-xl" onClick={goBack}>
						<ChevronLeft className="w-6 h-6 mr-2"/>Go Back
					</Button>
					<p className="text-xl font-bold text-center ml-4">
						Project: {pageData.project_name}
					</p>
				</div>
				<div className="flex flex-row justify-start">
					<div className="border rounded-2xl flex-grow p-4 w-64 mr-2">
						<p className="font-bold text-muted-foreground">AI Name:</p>
						<p className="mt-2">
							{pageData.ai_name} | {pageData.ai_nationality || "N/A"}
						</p>
					</div>
					<div className="border rounded-2xl flex-grow p-4 w-64 mr-2">
						<p className="font-bold text-muted-foreground">Language:</p>
						<p className="mt-2">
							{pageData.language}
						</p>
					</div>
					<div className="border rounded-2xl flex-grow p-4 w-64 mr-2">
						<p className="font-bold text-muted-foreground">Participant Email ID:</p>
						<p className="mt-2">
							{pageData.participant_email_id}
						</p>
					</div>
					<div className="border rounded-2xl flex-grow p-4 w-64 ml-2">
						<p className="font-bold text-muted-foreground">Call Duration:</p>
						<p className="mt-2">
							{callDurationFormatter(
								pageData.call_duration_minutes,
								pageData.call_duration_seconds
							)}
						</p>
					</div>
				</div>
				<hr className="my-12"/>
				{/* ------------------ CALL INSTRUCTIONS ------------------*/}
				{/* Call Instructions */}
				<div className="w-full flex flex-col mt-6">
					<Label htmlFor={"call-instruction-text"} className="font-helvetica-neue-bold">
						Call Instructions:
					</Label>
					<div className="w-full border rounded-2xl p-6 mt-2" id={"call-instruction-text"}>
						<pre className="whitespace-pre-wrap">
							{pageData.call_instructions}
						</pre>
					</div>
				</div>
				{/* Post Call Instructions */}
				<div className="w-full flex flex-col mt-6">
					<Label htmlFor={"post-call-instruction-text"} className="font-helvetica-neue-bold">
						Post Call Instructions:
					</Label>
					<div className="w-full border rounded-2xl p-6 mt-2" id={"post-call-instruction-text"}>
						<pre className="whitespace-pre-wrap">
							{pageData.post_call_instructions}
						</pre>
					</div>
				</div>
				{/* Post Call Results */}
				<div className="w-full flex flex-col mt-6">
					<Label htmlFor={"post-call-result-text"} className="font-helvetica-neue-bold">
						Post Call Result:
					</Label>
					<div className="w-full border rounded-2xl p-6 mt-2" id={"post-call-result-text"}>
						<pre className="whitespace-pre-wrap">
							{pageData.post_call_result}
						</pre>
					</div>
				</div>
				{/* ------------------ CALL TRANSCRIPTION ------------------*/}
				<div className="w-full mt-6">
					<h1 className="text-2xl font-bold">Call Transcription:</h1>
					<div className="w-full border rounded-2xl p-6 mt-4">
						{pageData.call_log ? pageData.call_log.map((transcription, index) => {
							if (transcription.role === "user") {
								return <TranscriptionUserRow key={`Participant_${index}`}
																						 name={"Participant"}
																						 text={transcription.content}
																						 timestamp={transcription.timestamp}/>
							} else {
								return <TranscriptionAiRow key={`${pageData.ai_name}_${index}`}
																					 name={pageData.ai_name}
																					 text={transcription.content}
																					 timestamp={transcription.timestamp}/>
							}
						}) : <p className="text-center">No Call Transcription Available</p>}
					</div>
				</div>
			</div>
		)

	} else {
		// Ideally it should not reach here.
		return <></>
	}
}

function TranscriptionUserRow(props: { name: string, text: string, timestamp?: number }) {
	return (
		<div className="flex flex-col rounded bg-muted/35 p-4">
			<p className="text-lg font-bold text-muted-foreground">
				{props.name}: <Timestamp timestamp={props.timestamp}/>
			</p>
			<p>{props.text}</p>
		</div>
	)
}

function TranscriptionAiRow(props: { name: string, text: string, timestamp?: number }) {
	return <div className="flex flex-col rounded p-4">
		<p className="text-lg font-bold text-muted-foreground">
			{props.name}: <Timestamp timestamp={props.timestamp}/>
		</p>
		<p>{props.text}</p>
	</div>;
}

function Timestamp(props: { timestamp: number | undefined }) {
	if (props.timestamp) {
		return (
			<span className="mt-2 text-sm text-muted-foreground font-normal">
                {formattedDateTime(new Date(props.timestamp * 1000))}
            </span>
		)
	} else {
		return <></>
	}
}
