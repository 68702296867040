import {ErrorResponse, Link, useRouteError} from "react-router-dom";
import {buttonVariants} from "@components/ui/button";
import {ArrowLeft} from "lucide-react";
import {urls} from "@routes";

export default function ErrorPage() {
    const error = (useRouteError() as ErrorResponse);
    console.log(error)

    if (error.status === 404) {
        return (
            <div className="flex flex-col justify-center items-center w-screen h-screen">
                <h1 className="text-4xl font-extrabold uppercase">The page you were looking for</h1>
                <h1 className="text-4xl uppercase mt-2">Does not exist</h1>
                <p className="text-2xl mt-7 text-muted-foreground">Status Code: {error.status}</p>
                <Link to={urls["dashboard"]} className={buttonVariants({variant: "default", className: "mt-10"})} >
                    <ArrowLeft/>&nbsp;Let's Go Back
                </Link>
            </div>
        )

    } else {
        // Consider it as 500 error
        return (
            <div className="flex flex-col justify-center items-center w-screen h-screen">
                <h1 className="text-4xl">Hmm... Something went wrong</h1>
                <p className="text-2xl mt-7 text-muted-foreground">Status Code: {error.status}</p>
                <p className="text-2xl mt-4">{error.data}</p>
            </div>
        )
    }
}