import {ApiRequestFailed} from "@lib/apis";
import {Navigate} from "react-router-dom";
import {urls} from "@routes";

interface Props {
    error: ApiRequestFailed
}

export default function PageDataErrorHandler(props: Props) {
    let errorStatusCode = props.error.statusCode

    if (errorStatusCode === 499) {
        // Send user to login page if we get the custom status code 499.
        return (
            <Navigate to={urls["login"]} replace={true}/>
        )

    } else if (errorStatusCode === 403) {
        return <p>Error: 403 Access Forbidden</p>

    } else {
        // Show ErrorPage for all other status codes.
        return (
            <p>Error</p>
        )
    }
}