import {useQuery} from "@tanstack/react-query";
import {ApiRequestFailed, authenticateAndFetchData, retryFn} from "@lib/apis";
import PageLoading from "@components/ui/pageloading";
import PageDataErrorHandler from "@components/data/pageDataErrorHandler";
import {useEffect, useState} from "react";

interface PageData {
    status_code: number
    status_text: string

    twilio_acc_bal: number
}

export default function AdminDashboard() {
    const [pageData, setPageData] = useState<PageData>();

    // Fetch the page data.
    const pageDataQuery = useQuery({
        queryKey: ["adminDashboard"],
        queryFn: () => authenticateAndFetchData("/api/admin/dashboard/"),
        gcTime: 0,
        retry: retryFn
    });
    useEffect(() => {
        if (pageDataQuery.data) {
            setPageData(pageDataQuery.data.data as PageData);
        }
    }, [pageDataQuery.data]);


    // ========================================================================
    // --------------------------- MAIN RENDER CODE ---------------------------
    // ========================================================================

    if (pageDataQuery.isLoading) {
        return (
            <PageLoading/>
        )

    } else if (pageDataQuery.error as unknown as ApiRequestFailed) {
        return <PageDataErrorHandler error={pageDataQuery.error as unknown as ApiRequestFailed}/>

    } else if (pageData) {
        return (
            <div className={"w-full grid grid-rows-1"}>
                <div className={"w-full"}>
                    <h1 className={"text-2xl underline"}>
                        Twilio Account balance: <b className={"text-green-500"}>${pageData.twilio_acc_bal}</b>
                    </h1>
                </div>
            </div>
        )
    } else {
        // Ideally it should not reach here.
        return <></>
    }
}
