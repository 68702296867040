import {Button} from "@components/ui/button"
import {Card, CardContent, CardDescription, CardHeader, CardTitle,} from "@components/ui/card"
import {Input} from "@components/ui/input"
import {Label} from "@components/ui/label"
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useMutation} from "@tanstack/react-query";
import {ApiRequestFailed, authenticateAndPostData} from "@lib/apis";
import {saveToken} from "@lib/jwt";
import {urls} from "@routes";
import {getCurrentTheme} from "@lib/utils";
import logo_white from "@images/branding/ai_call_center_logo_white.png";
import logo_black from "@images/branding/ai_call_center_logo_black.png";

interface LoginSuccessData {
	status_code: number
	status_text: string
	access_token: string
	refresh_token: string
}

export default function Login() {
	const [email, setEmail] = useState<string>("");
	const [password, setPassword] = useState<string>("");
	const [errorText, setErrorText] = useState<string>("");

	const navigate = useNavigate();

	const loginMutation = useMutation({
		mutationKey: ['login', email, password],
		mutationFn: () => authenticateAndPostData(
			"/api/auth/login/",
			{"email": email, "password": password}
		),
		gcTime: 0,
		onSuccess: (response) => {
			// Set tokens & send user to dashboard page.
			let data: LoginSuccessData = response.data;
			if (data.access_token && data.refresh_token) {
				saveToken("access", data.access_token);
				saveToken("refresh", data.refresh_token);
				navigate(urls["dashboard"]);
			} else {
				// Ideally should not happen. But just in case.
				setErrorText("Oops, Something went wrong on our end! Please try again.");
			}
		},
		onError: (error: ApiRequestFailed) => {
			// Show error message.
			setErrorText(error.data.message);
		}
	});

	/**
	 * Handles signup form submission.
	 */
	function submitLoginData() {
		if (email && password) {
			setErrorText("");
			loginMutation.mutate();
		} else {
			setErrorText("Please fill in all the required fields.");
		}
	}

	// ========================================================================
	// --------------------------- MAIN RENDER CODE ---------------------------
	// ========================================================================

	return (
		<div className={"w-full flex flex-col justify-center items-center"}>
			<img src={getCurrentTheme() === "dark" ? logo_white : logo_black} alt="logo" className={"w-[300px] md:w-[400px]"}/>
			<Card className="mx-auto max-w-sm w-96 mt-24">
				<CardHeader>
					<CardTitle className="text-2xl">Login</CardTitle>
					<CardDescription>
						Enter your email below to login to your account
					</CardDescription>
				</CardHeader>
				<CardContent>
					<div className="grid gap-4">
						<div className="grid gap-2">
							<Label htmlFor="email"><span className="text-red-700">*</span>&nbsp;Email</Label>
							<Input
								id="email"
								type="email"
								placeholder="me@example.com"
								value={email}
								onChange={e => setEmail(e.target.value)}
								required
							/>
						</div>
						<div className="grid gap-2">
							<div className="flex items-center">
								<Label htmlFor="password"><span className="text-red-700">*</span>&nbsp;Password</Label>
								<a href={urls["forgotPassword"]} className="ml-auto inline-block text-sm underline">
									Forgot your password?
								</a>
							</div>
							<Input id="password"
										 type="password"
										 value={password}
										 onChange={e => setPassword(e.target.value)}
										 required/>
						</div>
						<Button type="submit" className="w-full mt-7" onClick={submitLoginData}>
							Login
						</Button>
					</div>
					<div className="mt-4 text-center text-sm">
						Don&apos;t have an account?{" "}
						<a href="/auth/signup" className="underline">
							Sign up
						</a>
					</div>
					<p className="text-red-600 text-center mt-4">{errorText}</p>
				</CardContent>
			</Card>
		</div>
	)
}
