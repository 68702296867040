import {useCSVReader} from "react-papaparse";
import {UploadCloud} from "lucide-react";

interface CsvUploadProps {
	classname?: string
	fileSelectedCallback: (columnNames: Array<string>, rows: Array<Array<string>>) => void
}

export default function CsvUpload(props: CsvUploadProps) {
	const {CSVReader} = useCSVReader();

	let classnames = "border-2 border-dashed rounded-2xl px-6 py-12 text-center flex flex-col " +
		"justify-center items-center cursor-pointer";
	if (props.classname) {
		classnames = classnames + " " + props.classname;
	}

	return (
		<CSVReader onUploadAccepted={(results: any) => {
			let columns: Array<string> = [];
			let rows: Array<Array<string>> = [];
			// Each row is an Array of string in results.data
			results.data.forEach((row: Array<string>, index: number) => {
				if (index === 0) {
					row.forEach((columnName) =>{
						if (columnName) {
							columns.push(columnName);
						}
					});
				} else {
					let nonEmptyValues = row.filter((value => value !== ""))
					if (nonEmptyValues.length > 0) rows.push(nonEmptyValues);
				}
			});
			props.fileSelectedCallback(columns, rows);
		}}>
			{({
				  getRootProps,
				  acceptedFile,
			  }: any) => (
				<div className={classnames} {...getRootProps()}>
					<UploadCloud className="mr-2"/>Upload CSV File
					{acceptedFile && (
						<p className="mt-2">File Selected: {acceptedFile.name}</p>
					)}
				</div>
			)}

		</CSVReader>
	)
}
