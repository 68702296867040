import logo_white from "@images/branding/ai_call_center_logo_white.png";
import logo_black from "@images/branding/ai_call_center_logo_black.png";
import {
	Blocks,
	BookUser,
	CalendarCheck,
	CircleUserRound,
	CircuitBoard,
	CreditCard,
	LoaderCircle,
	LogOut,
	Menu,
	ScrollText,
	Speech,
	SunMoon,
	Terminal,
	Tv2,
	Undo,
	User,
	Users
} from "lucide-react";
import {Link, NavLink, Outlet} from "react-router-dom";
import {urls} from "@routes";
import {Dispatch, ReactNode, SetStateAction, useEffect, useState} from "react";
import {Badge} from "@components/ui/badge"
import {changeTheme, cn, getCurrentTheme} from "@lib/utils";
import {DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger} from "@components/ui/dropdown-menu";
import {Toaster} from "@components/ui/toaster";
import {useToast} from "@components/ui/use-toast";
import {Alert, AlertDescription, AlertTitle} from "@components/ui/alert";
import {useMutation, useQuery} from "@tanstack/react-query";
import {ApiRequestFailed, authenticateAndFetchData, authenticateAndPostData, retryFn} from "@lib/apis";
import {Button} from "@components/ui/button";
import {Sheet, SheetContent, SheetTrigger} from "@components/ui/sheet";

interface LoggedInData {
	user_email: string
	sub_id: string | null
	sub_status: "active" | "past_due" | "unpaid" | "cancelled" | "free"
}

export interface BaseOutletContext {
	setUserName: Dispatch<SetStateAction<string>>
	setSubStatus: Dispatch<SetStateAction<"active" | "past_due" | "unpaid" | "cancelled" | "free">>
	showToast: (title: string, text: string, variant: "default" | "destructive") => void
}

export default function Base() {
	const [userName, setUserName] = useState<string>("");
	const [logoDarkMode, setLogoDarkMode] = useState<boolean>(
		getCurrentTheme() === "dark"
	);
	const [
		subStatus,
		setSubStatus
	] = useState<"active" | "past_due" | "unpaid" | "cancelled" | "free">("free");
	const [subID, setSubID] = useState<string | null>(null);
	const [resumingSub, setResumingSub] = useState<boolean>(false);

	const {toast} = useToast();

	// Query to fetch common data.
	const baseQuery = useQuery({
		queryKey: ["baseQuery"],
		queryFn: () => authenticateAndFetchData("/api/logged-in-data/"),
		gcTime: 0,
		retry: retryFn,
		refetchOnWindowFocus: true
	});
	useEffect(() => {
		if (baseQuery.data) {
			let data = baseQuery.data.data as LoggedInData
			setUserName(data.user_email)
			setSubStatus(data.sub_status);
			setSubID(data.sub_id);
		}
	}, [baseQuery.data]);

	// Mutation to undo cancellation.
	const undoCancellationMutation = useMutation({
		mutationKey: ["undoCancellationMutation"],
		mutationFn: (subscriptionID: string) => authenticateAndPostData("/api/resume-subscription/", {
			"subscription_id": subscriptionID,
		}),
		gcTime: 0,
		retry: retryFn,
		onSuccess: () => {
			// Waiting for webhook. Ideally should be done using websockets but we don't have that rn.
			setTimeout(() => {
				baseQuery.refetch().then(() => {
					showToast(
						"Subscription Resumed",
						"Your subscription plan has been resumed successfully!",
						"default",
					);
					setResumingSub(false);
				});
			}, 5000);
		},
		onError: (error: ApiRequestFailed) => {
			setResumingSub(false);
			showToast(
				"Oops! Something went wrong",
				error.data.message,
				"destructive",
			);
		}
	});

	function showToast(title: string, text: string, variant: "default" | "destructive") {
		toast({
			title: title,
			description: text,
			variant: variant,
			className: cn(
				'top-0 right-0 flex fixed md:max-w-[420px] md:top-4 md:right-4'
			),
		});
	}

	function undoCancellation() {
		setResumingSub(true);
		if (subID) {
			undoCancellationMutation.mutate(subID);
		} else {
			setResumingSub(false);
			console.error(`Invalid subscription ID: ${subID}`);
		}
	}

	// ========================================================================
	// --------------------------- MAIN RENDER CODE ---------------------------
	// ========================================================================

	return (
		<div className="grid min-h-screen w-full md:grid-cols-[280px_1fr] lg:grid-cols-[320px_1fr]">
			{/* ------------------ SIDEBAR ------------------ */}
			<div className="hidden bg-muted md:block">
				<div className="flex h-full max-h-screen flex-col gap-2 px-4 py-5">
					<div className="flex justify-center mt-4">
						<NavLink to={urls["dashboard"]}>
							<img src={logoDarkMode ? logo_white : logo_black} alt="logo" className="sidebar-logo"/>
						</NavLink>
					</div>
					<hr className="mt-5 mb-5 border-muted-foreground/30"/>
					<div className="flex-1 mt-5">
						<nav className="grid items-start px-2 text-lg font-medium lg:px-4">
							<NavLink to={urls["dashboard"]}
											 className={({isActive}) =>
												 isActive ?
													 "flex items-center gap-3 rounded-lg px-3 py-3 text-primary transition-all hover:text-primary" :
													 "flex items-center gap-3 rounded-lg px-3 py-3 text-muted-foreground transition-all hover:text-primary"}
							>
								<Tv2 className="h-4 w-4"/>
								Dashboard
							</NavLink>
							<NavLink to={urls["conversation"]}
											 className={({isActive}) =>
												 isActive ?
													 "flex items-center gap-3 rounded-lg px-3 py-3 text-primary transition-all hover:text-primary" :
													 "flex items-center gap-3 rounded-lg px-3 py-3 text-muted-foreground transition-all hover:text-primary"}
							>
								<Speech className="h-4 w-4"/>
								Conversation
							</NavLink>
							<NavLink to={urls["delegate"]}
											 className={({isActive}) =>
												 isActive ?
													 "flex items-center gap-3 rounded-lg px-3 py-3 text-primary transition-all hover:text-primary" :
													 "flex items-center gap-3 rounded-lg px-3 py-3 text-muted-foreground transition-all hover:text-primary"}
							>
								<CalendarCheck className="h-4 w-4"/>
								Delegate Calls
							</NavLink>
							{/*<NavLink to={urls["inboundCalls"]}*/}
							{/*		 className={({isActive}) =>*/}
							{/*			 isActive ?*/}
							{/*				 "flex items-center gap-3 rounded-lg px-3 py-3 text-primary transition-all hover:text-primary" :*/}
							{/*				 "flex items-center gap-3 rounded-lg px-3 py-3 text-muted-foreground transition-all hover:text-primary"}*/}
							{/*>*/}
							{/*	<PhoneIncoming className="h-4 w-4"/>*/}
							{/*	Inbound Calls*/}
							{/*</NavLink>*/}
							<NavLink to={urls["bulkCalls"]}
											 className={({isActive}) =>
												 isActive ?
													 "flex items-center gap-3 rounded-lg px-3 py-3 text-primary transition-all hover:text-primary" :
													 "flex items-center gap-3 rounded-lg px-3 py-3 text-muted-foreground transition-all hover:text-primary"}
							>
								<Users className="h-4 w-4"/>
								Bulk Delegate Calls
							</NavLink>
							<NavLink to={urls["bulkDelegateMeeting"]}
											 className={({isActive}) =>
												 isActive ?
													 "flex items-center gap-3 rounded-lg px-3 py-3 text-primary transition-all hover:text-primary" :
													 "flex items-center gap-3 rounded-lg px-3 py-3 text-muted-foreground transition-all hover:text-primary"}
							>
								<Users className="h-4 w-4"/>
								Bulk Delegate Meeting
							</NavLink>
							<NavLink to={urls["callLogs"]}
											 className={({isActive}) =>
												 isActive ?
													 "flex items-center gap-3 rounded-lg px-3 py-3 text-primary transition-all hover:text-primary" :
													 "flex items-center gap-3 rounded-lg px-3 py-3 text-muted-foreground transition-all hover:text-primary"}
							>
								<ScrollText className="h-4 w-4"/>
								Call Logs
							</NavLink>
							<NavLink to={urls["integrations"]}
											 className={({isActive}) =>
												 isActive ?
													 "flex items-center gap-3 rounded-lg px-3 py-3 text-primary transition-all hover:text-primary" :
													 "flex items-center gap-3 rounded-lg px-3 py-3 text-muted-foreground transition-all hover:text-primary"}
							>
								<Blocks className="h-4 w-4"/>
								Integrations
							</NavLink>
							<NavLink to={urls["phoneNumber"]}
											 className={({isActive}) =>
												 isActive ?
													 "flex items-center gap-3 rounded-lg px-3 py-3 text-primary transition-all hover:text-primary" :
													 "flex items-center gap-3 rounded-lg px-3 py-3 text-muted-foreground transition-all hover:text-primary"}
							>
								<BookUser className="h-4 w-4"/>
								Phone Numbers
							</NavLink>
							<NavLink to={urls["userRestApis"]}
											 className={({isActive}) =>
												 isActive ?
													 "flex items-center gap-3 rounded-lg px-3 py-3 text-primary transition-all hover:text-primary" :
													 "flex items-center gap-3 rounded-lg px-3 py-3 text-muted-foreground transition-all hover:text-primary"}
							>
								<CircuitBoard className="h-4 w-4"/>
								API<Badge variant="outline">coming soon</Badge>
							</NavLink>
							<NavLink to={urls["subscription"]}
											 className={({isActive}) =>
												 isActive ?
													 "flex items-center gap-3 rounded-lg px-3 py-3 text-primary transition-all hover:text-primary" :
													 "flex items-center gap-3 rounded-lg px-3 py-3 text-muted-foreground transition-all hover:text-primary"}
							>
								<CreditCard className="h-4 w-4"/>
								Subscription
							</NavLink>
						</nav>
					</div>
					<hr className="mt-5 mb-5 border-muted-foreground/30"/>
					<div className="flex">
						<nav className="grid items-start px-2 text-lg font-medium lg:px-4">
							<NavLink to={urls["dashboard"]}
											 className="flex items-center gap-3 rounded-lg px-3 py-3 text-muted-foreground transition-all hover:text-primary">
								<User className="h-4 w-4"/>
								User Profile
							</NavLink>
						</nav>
					</div>
					<div className="flex">
						<nav className="grid items-start px-2 text-lg font-medium lg:px-4">
							<Link to={urls["logout"]}
										className="flex items-center gap-3 rounded-lg px-3 py-3 text-muted-foreground transition-all hover:text-primary">
								<LogOut className={"w-4 h-4"}/>
								Logout
							</Link>
						</nav>
					</div>
				</div>
			</div>

			{/* ------------------ MAIN WORKSPACE ------------------  */}
			{/* also includes navbar hamburger for mobile */}
			<div className="flex flex-col py-5 px-5 md:px-10 max-h-screen overflow-auto">
				{/* Menu at top of workspace */}
				<header className="flex flex-row justify-between items-center h-14 lg:h-[60px]">
					{/* username badge will be hidden on mobile */}
					<div className="hidden flex-row items-center md:flex">
						<Badge className={"px-3 py-1"}><CircleUserRound className="w-4 h-4 mr-2"/>{userName}</Badge>
					</div>
					{/* This logo will only appear for mobile */}
					<NavLink to={urls["dashboard"]}>
						<img src={logoDarkMode ? logo_white : logo_black} alt="logo" className={"md:hidden w-[150px]"}/>
					</NavLink>
					<div className="flex flex-row-reverse items-center">
						<MobileMenuSheet logoDarkMode={logoDarkMode} userName={userName}>
							<Button variant={"ghost"} className={"ml-4 md:hidden"}>
								<Menu/>
							</Button>
						</MobileMenuSheet>
						{/*<Link to={urls["logout"]}*/}
						{/*			className="p-3 border ml-4 rounded-2xl hover:text-primary">*/}
						{/*	<LogOut/>*/}
						{/*</Link>*/}
						<DropdownMenu>
							<DropdownMenuTrigger asChild>
								<div className="p-3 border rounded-2xl hover:text-primary cursor-pointer">
									<SunMoon/>
								</div>
							</DropdownMenuTrigger>
							<DropdownMenuContent>
								<DropdownMenuItem onClick={() => {
									changeTheme("dark");
									setLogoDarkMode(true);
								}}>
									Dark
								</DropdownMenuItem>
								<DropdownMenuItem onClick={() => {
									changeTheme("light");
									setLogoDarkMode(false);
								}}>
									Light
								</DropdownMenuItem>
								<DropdownMenuItem onClick={() => {
									changeTheme("system");
									setLogoDarkMode(getCurrentTheme() === "dark");
								}}>
									System
								</DropdownMenuItem>
							</DropdownMenuContent>
						</DropdownMenu>
					</div>
				</header>
				{/* Workspace */}
				<SubAlert status={subStatus} undoCancellation={undoCancellation} resumingSub={resumingSub}/>
				<div className="flex mt-5">
					<Outlet context={{setUserName, setSubStatus, showToast} satisfies BaseOutletContext}/>
					<Toaster/>
				</div>
			</div>
		</div>
	)
}

function SubAlert(props: {
	status: "active" | "past_due" | "unpaid" | "cancelled" | "free",
	resumingSub: boolean,
	undoCancellation: () => void,
}) {
	switch (props.status) {
		case "active" || "free":
			return null

		case "past_due":
			return (
				<Alert className="mt-4 mb-4" variant={"destructive"}>
					<Terminal className="h-4 w-4"/>
					<AlertTitle>Payment Failed</AlertTitle>
					<AlertDescription>
						Your last subscription renewal payment failed.
						Please update your card details to avoid cancellation.
					</AlertDescription>
				</Alert>
			)

		case "unpaid":
			return (
				<Alert className="mt-4 mb-4" variant={"destructive"}>
					<Terminal className="h-4 w-4"/>
					<AlertTitle>Payment Failed</AlertTitle>
					<AlertDescription>
						Your last subscription renewal payment failed.
						Please update your card details to avoid cancellation.
					</AlertDescription>
				</Alert>
			)

		case "cancelled":
			return (
				<Alert className="mt-4 mb-4" variant={"destructive"}>
					<Terminal className="h-4 w-4"/>
					<AlertTitle>Subscription marked for Cancellation</AlertTitle>
					<AlertDescription>
						<div className="w-full flex flex-row justify-between items-center">
							<p className="mr-6">
								Your subscription is currently marked for cancellation. You can continue using your paid
								plan features till end of current billing cycle, after which your account will be
								switched to Free plan. Use the Undo button to restart your subscription.
							</p>
							<Button size={"sm"}
											variant={"secondary"}
											onClick={props.undoCancellation}
											disabled={props.resumingSub}>
								{props.resumingSub ?
									<><LoaderCircle className="w-4 h-4 mr-2 animate-spin"/>
										Processing. Please wait...
									</> :
									<><Undo className="w-4 h-4 mr-2"/>Undo Cancellation</>}
							</Button>
						</div>
					</AlertDescription>
				</Alert>
			)

		default:
			return null
	}
}

function MobileMenuSheet(props: {
	children: ReactNode,
	logoDarkMode: boolean,
	userName: string
}) {
	return (
		<Sheet>
			<SheetTrigger asChild>
				{props.children}
			</SheetTrigger>
			<SheetContent>
				<div className="flex h-full max-h-screen flex-col gap-2 px-4 py-5">
					{/*<div className="flex justify-center mt-4">*/}
					{/*	<NavLink to={urls["dashboard"]}>*/}
					{/*		<img src={props.logoDarkMode ? logo_white : logo_black} alt="logo" className="sidebar-logo"/>*/}
					{/*	</NavLink>*/}
					{/*</div>*/}
					{/*<hr className="mt-5 mb-5 border-muted-foreground/30"/>*/}
					<div className="flex flex-row items-center">
						<Badge className={"px-3 py-1"}><CircleUserRound className="w-4 h-4 mr-2"/>{props.userName}</Badge>
					</div>
					<div className="flex-1 mt-5">
						<nav className="grid items-start px-2 text-lg font-medium lg:px-4">
							<NavLink to={urls["dashboard"]}
											 className={({isActive}) =>
												 isActive ?
													 "flex items-center gap-3 rounded-lg px-3 py-3 text-primary transition-all hover:text-primary" :
													 "flex items-center gap-3 rounded-lg px-3 py-3 text-muted-foreground transition-all hover:text-primary"}
							>
								<Tv2 className="h-4 w-4"/>
								Dashboard
							</NavLink>
							<NavLink to={urls["conversation"]}
											 className={({isActive}) =>
												 isActive ?
													 "flex items-center gap-3 rounded-lg px-3 py-3 text-primary transition-all hover:text-primary" :
													 "flex items-center gap-3 rounded-lg px-3 py-3 text-muted-foreground transition-all hover:text-primary"}
							>
								<Speech className="h-4 w-4"/>
								Conversation
							</NavLink>
							<NavLink to={urls["delegate"]}
											 className={({isActive}) =>
												 isActive ?
													 "flex items-center gap-3 rounded-lg px-3 py-3 text-primary transition-all hover:text-primary" :
													 "flex items-center gap-3 rounded-lg px-3 py-3 text-muted-foreground transition-all hover:text-primary"}
							>
								<CalendarCheck className="h-4 w-4"/>
								Delegate Calls
							</NavLink>
							{/*<NavLink to={urls["inboundCalls"]}*/}
							{/*		 className={({isActive}) =>*/}
							{/*			 isActive ?*/}
							{/*				 "flex items-center gap-3 rounded-lg px-3 py-3 text-primary transition-all hover:text-primary" :*/}
							{/*				 "flex items-center gap-3 rounded-lg px-3 py-3 text-muted-foreground transition-all hover:text-primary"}*/}
							{/*>*/}
							{/*	<PhoneIncoming className="h-4 w-4"/>*/}
							{/*	Inbound Calls*/}
							{/*</NavLink>*/}
							<NavLink to={urls["bulkCalls"]}
											 className={({isActive}) =>
												 isActive ?
													 "flex items-center gap-3 rounded-lg px-3 py-3 text-primary transition-all hover:text-primary" :
													 "flex items-center gap-3 rounded-lg px-3 py-3 text-muted-foreground transition-all hover:text-primary"}
							>
								<Users className="h-4 w-4"/>
								Bulk Delegate Calls
							</NavLink>
							<NavLink to={urls["bulkDelegateMeeting"]}
											 className={({isActive}) =>
												 isActive ?
													 "flex items-center gap-3 rounded-lg px-3 py-3 text-primary transition-all hover:text-primary" :
													 "flex items-center gap-3 rounded-lg px-3 py-3 text-muted-foreground transition-all hover:text-primary"}
							>
								<Users className="h-4 w-4"/>
								Bulk Delegate Meeting
							</NavLink>
							<NavLink to={urls["callLogs"]}
											 className={({isActive}) =>
												 isActive ?
													 "flex items-center gap-3 rounded-lg px-3 py-3 text-primary transition-all hover:text-primary" :
													 "flex items-center gap-3 rounded-lg px-3 py-3 text-muted-foreground transition-all hover:text-primary"}
							>
								<ScrollText className="h-4 w-4"/>
								Call Logs
							</NavLink>
							<NavLink to={urls["integrations"]}
											 className={({isActive}) =>
												 isActive ?
													 "flex items-center gap-3 rounded-lg px-3 py-3 text-primary transition-all hover:text-primary" :
													 "flex items-center gap-3 rounded-lg px-3 py-3 text-muted-foreground transition-all hover:text-primary"}
							>
								<Blocks className="h-4 w-4"/>
								Integrations
							</NavLink>
							<NavLink to={urls["phoneNumber"]}
											 className={({isActive}) =>
												 isActive ?
													 "flex items-center gap-3 rounded-lg px-3 py-3 text-primary transition-all hover:text-primary" :
													 "flex items-center gap-3 rounded-lg px-3 py-3 text-muted-foreground transition-all hover:text-primary"}
							>
								<BookUser className="h-4 w-4"/>
								Phone Numbers
							</NavLink>
							<NavLink to={urls["userRestApis"]}
											 className={({isActive}) =>
												 isActive ?
													 "flex items-center gap-3 rounded-lg px-3 py-3 text-primary transition-all hover:text-primary" :
													 "flex items-center gap-3 rounded-lg px-3 py-3 text-muted-foreground transition-all hover:text-primary"}
							>
								<CircuitBoard className="h-4 w-4"/>
								API<Badge variant="outline">coming soon</Badge>
							</NavLink>
							<NavLink to={urls["subscription"]}
											 className={({isActive}) =>
												 isActive ?
													 "flex items-center gap-3 rounded-lg px-3 py-3 text-primary transition-all hover:text-primary" :
													 "flex items-center gap-3 rounded-lg px-3 py-3 text-muted-foreground transition-all hover:text-primary"}
							>
								<CreditCard className="h-4 w-4"/>
								Subscription
							</NavLink>
						</nav>
					</div>
					<hr className="mt-5 mb-5 border-muted-foreground/30"/>
					<div className="flex">
						<nav className="grid items-start px-2 text-lg font-medium lg:px-4">
							<NavLink to={urls["dashboard"]}
											 className="flex items-center gap-3 rounded-lg px-3 py-3 text-muted-foreground transition-all hover:text-primary">
								<User className="h-4 w-4"/>
								User Profile
							</NavLink>
						</nav>
					</div>
					<div className="flex">
						<nav className="grid items-start px-2 text-lg font-medium lg:px-4">
							<Link to={urls["logout"]}
										className="flex items-center gap-3 rounded-lg px-3 py-3 text-muted-foreground transition-all hover:text-primary">
								<LogOut className={"w-4 h-4"}/>
								Logout
							</Link>
						</nav>
					</div>
				</div>
			</SheetContent>
		</Sheet>
	)
}
