import {Label} from "@components/ui/label";
import {Textarea} from "@components/ui/textarea";
import {useEffect, useState} from "react";
import {Input} from "@components/ui/input";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "@components/ui/select";
import {Button} from "@components/ui/button";
import {Save} from "lucide-react";
import {useMutation, useQuery} from "@tanstack/react-query";
import {ApiRequestFailed, authenticateAndFetchData, authenticateAndPostData, retryFn} from "@lib/apis";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import {BaseOutletContext} from "@pages/Base";
import PageLoading from "@components/ui/pageloading";
import PageDataErrorHandler from "@components/data/pageDataErrorHandler";
import {urls} from "@routes";

interface PageData {
	status_code: number
	status_text: string
	user_email: string
	phone_numbers: Array<string>
	voice_details: Array<VoiceDetails>
	business_description: string
	agent_details: AgentDetails
}

interface VoiceDetails {
	voice_id: string
	voice_name: string
	gender: "M" | "F"
	personalities: Array<string>
	nationality: string
}

interface AgentDetails {
	selected_number: string
	selected_ai_gender: string
	selected_ai_nationality: string
	selected_ai_voice_id: string
	selected_ai_name: string
}

export default function EditInboundCallAgent() {
	const [pageData, setPageData] = useState<PageData>();
	const [businessDescription, setBusinessDescription] = useState<string>("");
	const [aiCallName, setAiCallName] = useState<string>("");
	const [phoneNumber, setPhoneNumber] = useState<string>("");
	const [gender, setGender] = useState<string>("");
	const [nationality, setNationality] = useState<string>("");
	const [voiceId, setVoiceId] = useState<string>("");

	const {showToast} = useOutletContext<BaseOutletContext>();
	const navigate = useNavigate();
	const {agentUID} = useParams();

	const [
		allNationalities,
		setAllNationalities
	] = useState<Array<string>>([]);
	const [
		filteredVoices,
		setFilteredVoices
	] = useState<Array<{ voiceId: string, voiceName: string }>>([]);

	// Fetch page data.
	const pageDataQuery = useQuery({
		queryKey: ["editInboundCallAgentPageData", agentUID],
		queryFn: () => authenticateAndFetchData("/api/edit-inbound-call-ai?uid=" + agentUID),
		gcTime: 0,
		retry: retryFn,
		refetchOnWindowFocus: false,
	});
	useEffect(() => {
		if (pageDataQuery.data) {
			let data = pageDataQuery.data.data as PageData;
			setPageData(data);
			setBusinessDescription(data.business_description);
			setAiCallName(data.agent_details.selected_ai_name);
			setGender(data.agent_details.selected_ai_gender);
			setNationality(data.agent_details.selected_ai_nationality);
			setVoiceId(data.agent_details.selected_ai_voice_id);
			setPhoneNumber(data.agent_details.selected_number);
			console.log(data.agent_details.selected_ai_voice_id);
		}
	}, [pageDataQuery.data]);

	// Mutation for creating AI agent.
	const editAiMutation = useMutation({
		mutationKey: ["editInboundCallAI"],
		mutationFn: (data: {
			phoneNumber: string,
			voiceID: string,
			name: string,
			businessDescription: string
		}) => authenticateAndPostData("/api/edit-inbound-call-ai/", {
			"uid": agentUID,
			"phone_number": data.phoneNumber,
			"ai_voice_id": data.voiceID,
			"ai_name": data.name,
			"business_info_text": data.businessDescription,
		}),
		gcTime: 0,
		retry: retryFn,
		onSuccess: () => {
			// Show toast & Send back to inbound calls page.
			showToast(
				"Success!",
				"All changes have been saved successfully.",
				"default",
			)
			navigate(urls["inboundCalls"]);
		},
		onError: (error: ApiRequestFailed) => {
			console.error(error);
			showToast(
				"Error",
				error.data.message,
				"destructive",
			);
		}
	});

	// // Set username on sidebar
	// const {setUserName} = useOutletContext() as any;
	// useEffect(() => {
	// 	if (pageData) {
	// 		setUserName(pageData.user_email)
	// 	}
	// }, [pageData, setUserName]);

	// This will fetch all distinct nationalities from the voice data.
	useEffect(() => {
		if (pageData) {
			let nationalities: Array<string> = []
			pageData.voice_details.forEach(voice => {
				if (!nationalities.includes(voice.nationality)) {
					nationalities.push(voice.nationality);
				}
			});
			setAllNationalities(nationalities);
		}
	}, [pageData]);

	// This will unlock and populate additional details section.
	useEffect(() => {
		if (pageData) {
			// Array of filtered voice data.
			let voices = pageData.voice_details.filter(voiceItem => {
				return (voiceItem.gender === gender) && (voiceItem.nationality === nationality)
			}).map(voiceItem => {
				return {voiceId: voiceItem.voice_id, voiceName: voiceItem.voice_name}
			});

			// Array of only voice ids
			let ids = pageData.voice_details.filter(voiceItem => {
				return (voiceItem.gender === gender) && (voiceItem.nationality === nationality)
			}).map(voiceItem => {
				return voiceItem.voice_id
			});

			// Check if current voiceID is present in the new filtered list. If it's not present, reset the voiceID
			// value.
			if (!ids.includes(voiceId)) {
				setVoiceId("");
			}

			setFilteredVoices(voices);
		}
	}, [gender, nationality, pageData, voiceId]);

	function saveChanges() {
		console.log(phoneNumber)
		console.log(gender)
		console.log(nationality)
		console.log(voiceId)
		console.log(businessDescription)
		console.log(aiCallName)
		if (phoneNumber && gender && nationality && voiceId && businessDescription && aiCallName) {
			editAiMutation.mutate({
				"phoneNumber": phoneNumber,
				"voiceID": voiceId,
				"name": aiCallName,
				"businessDescription": businessDescription,
			});

		} else {
			showToast(
				"Missing Details",
				"Please fill in all the fields",
				"destructive",
			)
		}
	}

	// ========================================================================
	// --------------------------- MAIN RENDER CODE ---------------------------
	// ========================================================================

	if (pageDataQuery.isLoading) {
		return (
			<PageLoading/>
		)

	} else if (pageDataQuery.error as unknown as ApiRequestFailed) {
		return <PageDataErrorHandler error={pageDataQuery.error as unknown as ApiRequestFailed}/>

	} else if (pageData) {
		return (
			<div className="w-full flex flex-col items-center mt-8">
				<h1 className="text-4xl font-helvetica-neue-bold text-center">
					Edit Inbound Call Agent
				</h1>
				<hr className="mt-6 mb-12 w-full"/>
				<div className="w-full grid grid-cols-3 gap-6">
					{/* info text for RAG */}
					<div className="w-full h-full flex flex-col col-span-2">
						<Label htmlFor="business-description" className="font-helvetica-neue-bold">
							Business Description:
						</Label>
						<Textarea id="business-description"
								  className="mt-4 border h-full"
								  value={businessDescription}
								  placeholder="Provide details like your business name, services offered, working hours,
							  contact info etc. which the AI can use to answer customer queries."
								  rows={5}
								  onChange={e => setBusinessDescription(e.target.value)}
								  required/>
					</div>

					{/* Agent Settings */}
					<div className="w-full flex flex-col h-full">
						<Label htmlFor="business-description" className="font-helvetica-neue-bold">
							AI Agent Settings:
						</Label>
						<div className="w-full flex flex-col border rounded-2xl p-6 mt-4">
							{/* ------------------ NUMBER SELECTION ------------------ */}
							<div className="w-full flex flex-col">
								<Label htmlFor="mobile-number-select">
									Phone Number
								</Label>
								<Select onValueChange={setPhoneNumber} defaultValue={phoneNumber}>
									<SelectTrigger id="mobile-number-select" className="mt-2 border">
										<SelectValue placeholder="Select One"/>
									</SelectTrigger>
									<SelectContent>
										{pageData.phone_numbers.map(number => (
											<SelectItem value={number} key={number}>{number}</SelectItem>
										))}
									</SelectContent>
								</Select>
							</div>
							{/* ------------------ AI GENDER ------------------ */}
							<div className="w-full flex flex-col mt-6">
								<Label htmlFor="gender-select">
									Gender:
								</Label>
								<Select onValueChange={setGender} defaultValue={gender}>
									<SelectTrigger id="gender-select" className="mt-2 border">
										<SelectValue placeholder={"Select Gender"}/>
									</SelectTrigger>
									<SelectContent>
										<SelectItem value="M">Him</SelectItem>
										<SelectItem value="F">Her</SelectItem>
									</SelectContent>
								</Select>
							</div>
							{/* ------------------ AI NATIONALITY ------------------ */}
							<div className="w-full flex flex-col mt-6">
								<Label htmlFor="nationality-select">
									AI Nationality:
								</Label>
								<Select onValueChange={setNationality} defaultValue={nationality}>
									<SelectTrigger id="nationality-select" className="mt-2 border">
										<SelectValue placeholder={"Select Nationality"}/>
									</SelectTrigger>
									<SelectContent>
										{allNationalities.map(nationality => (
											<SelectItem value={nationality} key={nationality}>{nationality}</SelectItem>
										))}
									</SelectContent>
								</Select>
							</div>
							{/* ------------------ VOICE LIST ------------------ */}
							<div className="w-full flex flex-col mt-6">
								<Label htmlFor="voice-model-select">
									Voice Type:
								</Label>
								<Select onValueChange={setVoiceId} defaultValue={voiceId} required>
									<SelectTrigger id="voice-model-select" className="mt-2 border">
										<SelectValue placeholder={"Select Voice"}/>
									</SelectTrigger>
									<SelectContent>
										{filteredVoices.map(voice => (
											<SelectItem value={voice.voiceId} key={voice.voiceId}>
												{voice.voiceName}
											</SelectItem>
										))}
									</SelectContent>
								</Select>
							</div>
							{/* ------------------ AI NAME ------------------ */}
							<div className="w-full flex flex-col mt-6">
								<Label htmlFor="ai-name">
									Name:
								</Label>
								<Input type="text"
									   id="ai-name"
									   className="mt-2 border"
									   value={aiCallName}
									   onChange={e => setAiCallName(e.target.value)}
									   placeholder="ex. Jane"
									   required/>
							</div>
							<Button className="mt-6" onClick={saveChanges}>
								<Save className="w-4 h-4 mr-2"/>Save Changes
							</Button>
						</div>
					</div>
				</div>
			</div>
		)

	} else {
		// Ideally it should not reach here.
		return <></>
	}
}
