import {useEffect, useState} from "react";
import {ApiRequestFailed, authenticateAndFetchData, retryFn} from "@lib/apis";
import {useQuery} from "@tanstack/react-query";
import {ColumnDef} from "@tanstack/react-table";
import {formattedDateTime} from "@lib/utils";
import {Badge} from "@components/ui/badge";
import {Link, useParams} from "react-router-dom";
import {buttonVariants} from "@components/ui/button";
import PageLoading from "@components/ui/pageloading";
import PageDataErrorHandler from "@components/data/pageDataErrorHandler";
import DataTable from "@components/ui/datatable";
import {urls} from "@routes";
import {Label} from "@components/ui/label";

interface PageData {
	status_code: number
	status_text: string

	project_name: string
	call_instructions: string
	post_call_instructions: string
	calls: Array<Call>
}

interface Call {
	call_uid: string
	ai_call_name: string
	callee_name: string
	call_started_at_ts: number
	call_ended_at_ts: number | null
	call_status: "connecting" | "ongoing" | "ended"
}

export default function BulkCallsProjectDetails() {
	const {projectUID} = useParams();

	const [pageData, setPageData] = useState<PageData>();

	// Query to fetch page data.
	const pageDataQuery = useQuery({
		queryKey: ["bulkCallsProjectDetailsPageData"],
		queryFn: () => authenticateAndFetchData("/api/bulk-calls/get-project-details?project_uid=" + projectUID),
		gcTime: 0,
		retry: retryFn,
		refetchOnWindowFocus: false,
	});
	useEffect(() => {
		if (pageDataQuery.data) {
			setPageData(pageDataQuery.data.data as PageData);
		}
	}, [pageDataQuery.data]);

	// Table columns
	const columns: ColumnDef<Call>[] = [
		{
			accessorKey: "callee_name",
			header: "Callee Name",
		},
		{
			accessorKey: "ai_call_name",
			header: "AI (Caller) Name",
		},
		{
			header: "Call Started At",
			accessorFn: originalRow => {
				if (originalRow.call_started_at_ts) {
					return formattedDateTime(new Date(originalRow.call_started_at_ts));
				} else {
					return "---"
				}
			},
		},
		{
			header: "Call Ended At",
			accessorFn: originalRow => {
				if (originalRow.call_ended_at_ts) {
					return formattedDateTime(new Date(originalRow.call_ended_at_ts));
				} else {
					return "---"
				}
			},
		},
		{
			header: "Status",
			cell: props => {
				if (props.row.original.call_status === "connecting") {
					return (
						<Badge variant="secondary">Queued</Badge>
					)
				} else if (props.row.original.call_status === "ongoing") {
					return (
						<Badge variant="destructive">In Call</Badge>
					)

				} else {
					return (
						<Badge variant="default">Completed</Badge>
					)
				}
			},
			enableColumnFilter: false,
		},
		{
			header: "Call Details",
			cell: props => {
				return (
					<Link to={urls["bulkCallsProjectCallDetails"].replace(
						":projectUID", projectUID || ""
					).replace(
						":callUID", props.row.original.call_uid
					)}
						  className={buttonVariants({variant: "default", size: "sm"})}>
						View Call
					</Link>
				)
			},
		}
	]

	// ========================================================================
	// --------------------------- MAIN RENDER CODE ---------------------------
	// ========================================================================

	if (pageDataQuery.isLoading) {
		return (
			<PageLoading/>
		)

	} else if (pageDataQuery.error as unknown as ApiRequestFailed) {
		return <PageDataErrorHandler error={pageDataQuery.error as unknown as ApiRequestFailed}/>

	} else if (pageData) {
		return (
			<div className="w-full flex flex-col justify-center items-center">
				<div className="flex flex-col justify-center items-center">
					<h1 className="text-4xl font-extrabold text-center mb-2">
						Bulk Call Project: {pageData.project_name}
					</h1>
					<p className="text-xl text-center">
						All calls for this project. Check details for each call by clicking on "View Call" button.
					</p>
				</div>
				{/* Call Instructions */}
				<div className="w-full flex flex-col mt-6">
					<Label htmlFor={"call-instruction-text"} className="font-helvetica-neue-bold">
						Call Instructions:
					</Label>
					<div className="w-full border rounded-2xl p-6 mt-2" id={"call-instruction-text"}>
						<pre className="whitespace-pre-wrap">
							{pageData.call_instructions}
						</pre>
					</div>
				</div>
				{/* Post Call Instructions */}
				<div className="w-full flex flex-col mt-6">
					<Label htmlFor={"post-call-instruction-text"} className="font-helvetica-neue-bold">
						Post Call Instructions:
					</Label>
					<div className="w-full border rounded-2xl p-6 mt-2" id={"post-call-instruction-text"}>
						<pre className="whitespace-pre-wrap">
							{pageData.post_call_instructions}
						</pre>
					</div>
				</div>
				{/* Calls Table */}
				<div className="w-full mt-6">
					<DataTable columns={columns} data={pageData.calls}/>
				</div>
			</div>
		)

	} else {
		// Ideally it should not reach here.
		return <></>
	}
}
