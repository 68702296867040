import {Card, CardContent, CardDescription, CardHeader, CardTitle} from "@components/ui/card";
import {Label} from "@components/ui/label";
import {Input} from "@components/ui/input";
import {Button, buttonVariants} from "@components/ui/button";
import {Link, useSearchParams} from "react-router-dom";
import {ChangeEvent, useState} from "react";
import {useMutation} from "@tanstack/react-query";
import {ApiRequestFailed, authenticateAndPostData, retryFn} from "@lib/apis";
import {urls} from "@routes";
import {ArrowLeft} from "lucide-react";


export default function ChangePassword() {
	const [searchParams] = useSearchParams();
	const code = searchParams.get("code") || "";
	const email = searchParams.get("email") || "";

	const [newPassword, setNewPassword] = useState("");
	const [errorText, setErrorText] = useState("");
	const [
		passwordChangeSuccess,
		setPasswordChangeSuccess
	] = useState<boolean>(false)

	// Mutation for changing password.
	const changePasswordMutation = useMutation({
		mutationKey: ["changePassword"],
		mutationFn: () => authenticateAndPostData("/api/change-password/", {
			"code": code,
			"email": email,
			"new_password": newPassword,
		}),
		gcTime: 0,
		retry: retryFn,
		onSuccess: () => {
			setPasswordChangeSuccess(true);
		},
		onError: (error: ApiRequestFailed) => {
			console.error(error);
			setErrorText(error.data.message);
		}
	})

	return (
		<>
			{passwordChangeSuccess ?
				<SuccessCard/> :
				<ChangePasswordInput value={newPassword}
									 onChange={e => setNewPassword(e.target.value)}
									 submitFunction={() => changePasswordMutation.mutate()}
									 errorText={errorText}/>}
		</>
	)
}

function ChangePasswordInput(props: {
	value: string,
	onChange: (e: ChangeEvent<HTMLInputElement>) => void,
	submitFunction: () => void,
	errorText: string
}) {
	return (
		<Card className="mx-auto max-w-sm w-96">
			<CardHeader>
				<CardTitle className="text-2xl">Change Password</CardTitle>
				<CardDescription>
					Enter your new account password.
				</CardDescription>
			</CardHeader>
			<CardContent>
				<div className="grid gap-4">
					<div className="grid gap-2">
						<Label htmlFor="password"><span className="text-red-700">*</span>&nbsp;New Password</Label>
						<Input
							id="password"
							type="password"
							placeholder="Minimum 6 characters."
							value={props.value}
							onChange={props.onChange}
							required
						/>
					</div>
					<Button className="w-full mt-7" onClick={props.submitFunction}>
						Change Password
					</Button>
				</div>
				{props.errorText && <p className="text-red-600 text-center mt-4">{props.errorText}</p>}
			</CardContent>
		</Card>
	)
}

function SuccessCard() {
	return (
		<div className="flex flex-col w-full items-center justify-center">
			<h1 className="text-4xl font-helvetica-neue-bold text-center">
				Password Change Successful.
			</h1>
			<p className="text-xl text-center mt-4">You can now log in to you account with the new password.</p>
			<Link to={urls["login"]} className={buttonVariants({variant: "default", className: "mt-6"})}>
				<ArrowLeft className="w-4 h-4 mr-2"/>Go To Login
			</Link>
		</div>
	)
}
